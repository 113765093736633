import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import DispatcherOrderForm from "./OrderForm";
import OrdersPage from "./OrdersPage";

const DispatcherPage = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path} component={OrdersPage} exact />
      <Route
        path={`${match.path}/order-form/:id`}
        component={DispatcherOrderForm}
      />
    </Switch>
  );
};

export default React.memo(DispatcherPage);
