import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Checkbox,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { PromoCodeValuesItem } from "../../../_shared/types";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/styles";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import API from "../../../_shared/axios";
import SaveIcon from "@material-ui/icons/Save";
import { AlertContext } from "../../_shared/ToastList";
import { alertError, alertWarning, getErrorMsg } from "../../../_shared/utils";
import ExtraModal from "../../_shared/ExtraModal";
import { Moment } from "moment/moment";
import { useUsersRoles } from "../../../_shared/hooks";

const useStyles = makeStyles({
  valueCell: {
    width: "25%",
    fontSize: "16px",
  },
  row: {
    height: "54px",
    fontSize: "16px",
  },
  fs: {
    fontSize: "16px",
  },
  icon: {
    cursor: "pointer",
  },
  alertBox: {
    width: "600px",
    height: "300px",
  },
  alertMsg: {},
  alerting: { height: "54px", fontSize: "16px", borderBottom: "none" },
  smoothAlert: {
    marginTop: "5px",
    paddingTop: "10px",
    borderTop: "1px grey solid",
    width: "100%",
    maxHeight: "500px",
    transition: "max-height 0.7s ease-in",
    float: "right",
  },
  hidden: {
    display: "none",
  },
  chosen: {
    boxShadow: "-1px 0px 12px 0px rgba(34, 60, 80, 0.2) inset",
    backgroundColor: "#eee",
  },
});

export type PersonalValueRowData = {
  promoCodeValueId: number;
  value: string;
  customerPhoneNumber: string;
  type: number;
  startDate: string;
  expirationDate: string;
};

const PersonalValueRow: FC<{
  data: PromoCodeValuesItem;
  index: number;
  save: (index: number, newDate: Moment | null) => void;
  startDate: Moment | null;
  dateChange: (date: Moment | null) => void;
  onDelete: (index: number) => void;
  disableWarning: () => void;
  warningDisabled: boolean;
  edit: boolean;
  setEdit: (a: boolean) => void;
  groupEdit: boolean;
  onClick?: any;
  type: number;
  highlight: boolean;
}> = ({
  disableWarning,
  warningDisabled,
  index,
  data,
  save,
  onDelete,
  startDate,
  dateChange,
  edit,
  setEdit,
  groupEdit,
  onClick,
  type,
  highlight,
}) => {
  const classes = useStyles();
  const currentDate = useMemo(() => moment(), []);
  const alertContext = useContext(AlertContext);
  const alertRef = useRef(null);
  const roles = useUsersRoles();

  const [alertDelete, setAlertDelete] = useState(false);

  const deleteValue = () => {
    if (roles[0] === "SuperAdmin") {
      API.delete(`promoCodes/value/${data.promoCodeValueId}`)
        .then(() => {
          onDelete(index);
        })
        .catch((e) => {
          console.error(e);
          alertError(alertContext, getErrorMsg(e.response, "Ошибка удаления"));
        })
        .finally(() => setAlertDelete(false));
    } else {
      alertWarning(alertContext, "Это действие доступно только супер админу");
    }
  };

  return (
    <>
      <TableRow
        key={data.promoCodeValueId}
        className={
          alertDelete
            ? classes.alerting
            : edit
            ? [classes.chosen, classes.row].join(" ")
            : classes.row
        }
        style={{
          cursor: groupEdit ? "pointer" : "unset",
          backgroundColor: highlight ? "rgba(235, 230, 96, 0.1)" : "unset",
        }}
        onClick={onClick}
      >
        <TableCell className={classes.valueCell}>
          <Grid container spacing={1}>
            <Grid item>
              <span>{data.value}</span>
            </Grid>
            {data.commentary ? (
              <Grid item title={data.commentary}>
                <MessageIcon fontSize={"small"} />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </TableCell>
        <TableCell className={classes.fs}>{data.customerPhoneNumber}</TableCell>
        <TableCell className={classes.fs} style={{ width: "364px" }}>
          <Grid
            container
            justify={"space-between"}
            direction={"row"}
            alignItems={"center"}
            wrap={"nowrap"}
          >
            {type === 1 ? (
              <>
                <span style={{ width: "150px" }}>
                  {data.startDate
                    ? moment(data.startDate).format("DD.MM.YYYY")
                    : ""}
                </span>
                {!alertDelete ? (
                  <DeleteIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      if (edit) return;
                      warningDisabled ? deleteValue() : setAlertDelete(true);
                    }}
                    className={classes.icon}
                  />
                ) : (
                  <div />
                )}
              </>
            ) : edit ? (
              <>
                <div onClick={(e) => e.stopPropagation()}>
                  <DatePicker
                    className={classes.fs}
                    style={{ width: "150px" }}
                    value={startDate}
                    format="DD.MM.YYYY"
                    initialFocusedDate={currentDate}
                    onChange={(date) => {
                      // @ts-ignore
                      if (edit) dateChange(date);
                    }}
                    cancelLabel="Отмена"
                  />
                </div>
                <Grid container justify={"space-around"}>
                  <SaveIcon
                    className={classes.icon}
                    onClick={() => save(index, startDate)}
                    style={{ color: "green" }}
                  />
                  <ClearIcon
                    className={classes.icon}
                    color={"secondary"}
                    onClick={(e) => {
                      setEdit(false);
                    }}
                  />
                </Grid>
              </>
            ) : (
              <>
                <span style={{ width: "150px" }}>
                  {data.startDate
                    ? moment(data.startDate).format("DD.MM.YYYY")
                    : ""}
                </span>
                <Grid container justify={"space-around"}>
                  {!alertDelete ? (
                    <DeleteIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        if (edit) return;
                        warningDisabled ? deleteValue() : setAlertDelete(true);
                      }}
                      className={classes.icon}
                    />
                  ) : (
                    <div />
                  )}
                  <EditIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      if (alertDelete) return;
                      setEdit(!edit);
                    }}
                    color={"primary"}
                    className={classes.icon}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {alertDelete && (
            <Grid
              ref={alertRef}
              container
              alignItems={"flex-end"}
              direction={"row"}
              className={alertDelete ? classes.smoothAlert : classes.hidden}
            >
              <Grid container>
                <Typography variant={"subtitle1"}>
                  Вы действительно хотите удалить код?
                </Typography>
                <Box>
                  <Typography variant={"caption"}>
                    Больше не показывать это сообщение
                  </Typography>
                  <Checkbox
                    checked={warningDisabled}
                    onChange={() => disableWarning()}
                  />
                </Box>
                <Grid container justify={"flex-end"}>
                  <Button
                    size={"small"}
                    variant={"contained"}
                    onClick={deleteValue}
                    color={"secondary"}
                    style={{ marginRight: "5px" }}
                  >
                    Удалить
                  </Button>
                  <Button
                    size={"small"}
                    variant={"outlined"}
                    onClick={() => setAlertDelete(false)}
                    color={"primary"}
                  >
                    Отмена
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </TableCell>
        <TableCell className={classes.fs}>
          {data.expirationDate
            ? moment(data.expirationDate).format("DD.MM.YYYY")
            : ""}
        </TableCell>
      </TableRow>
    </>
  );
};

export default React.memo(PersonalValueRow);
