import React, { FC, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import API from "../../../../_shared/axios";
import {
  AlertContextType,
  DispatcherProduct,
  GiftsResponseEntry,
  OrderCommonPromoCode,
  OrderCommonPromoCodeItem,
  ProductRow,
} from "../../../../_shared/types";
import { AlertContext } from "../../../_shared/ToastList";
import {
  alertError,
  alertWarning,
  getErrorMsg,
  reactMemo,
} from "../../../../_shared/utils";
import GiftsModal, { StateFullCats } from "./GiftsModal";
import { usePrevious } from "../ProductForm";
import lodash from "lodash";

export type GiftsData = {
  [k: string]: {
    count: number;
    variants: Array<number>;
    productId?: number;
    name: string;
    promoCatId: number;
    presentId: number;
  };
};

const useStyles = makeStyles({});

const DispatcherPromocodeForm: FC<{
  disabled?: boolean;
  customerId?: number;
  appliedPromoCode?: OrderCommonPromoCode;
  sourceId: number;
  items: Array<DispatcherProduct>;
  onApply: (data: OrderCommonPromoCode) => void;
  onDelete: () => void;
  commonProducts: Array<ProductRow>;
}> = ({
  disabled,
  appliedPromoCode,
  commonProducts,
  customerId,
  sourceId,
  items,
  onApply,
  onDelete,
}) => {
  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);
  const prevItems = usePrevious({ ...items });

  //7xr4ld9
  const [stage, setStage] = useState<1 | 2 | 3>(1);
  const [codeValue, setCodeValue] = useState<string>("");
  const [gift, setGift] = useState<"prods" | "discount">();
  const [discountValue, setDiscountValue] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<GiftsData>({});
  const [codeInfo, setCodeInfo] = useState<any>({});
  const [promocodeValueId, setPromocodeValueId] = useState<number>();

  const enterCode = () => {
    API.post(`promoCode/${customerId}`, { value: codeValue, sourceId, items })
      .then((resp) => {
        if (resp.data.discount) {
          setGift("discount");
          setDiscountValue(resp.data.discount);
          if (stage === 1) setStage(2);

          setPromocodeValueId(resp.data.promoCodeValueId);
          API.get(`promoCodes/${resp.data.promoCodeId}`)
            .then((getResponse) => {
              setCodeInfo(getResponse.data);
            })
            .catch((e) =>
              alertError(
                alertContext,
                getErrorMsg(e.response, "Ошибка получения данных о промокоде")
              )
            );
          // API.get(`promocodes/${codeValue}/terms?customerId=${customerId}`)
          //   .then((terms) => {
          //     setPromocodeValueId(terms.data.promoCodeValueId);
          //   })
          //   .catch((e) =>
          //     alertError(
          //       alertContext,
          //       getErrorMsg(e.response, "Ошибка получения данных о промокоде")
          //     )
          //   );
        } else {
          setGift("prods");
          if (stage === 1) setStage(2);

          const giftsData: GiftsData = {};

          resp.data.presents.forEach((g: GiftsResponseEntry) => {
            if (g.product) {
              giftsData[String(g.promotionCategory.promotionCategoryId)] = {
                count: g.count,
                variants: g.variants.map((v) => v.variantId),
                productId: g.product.productId,
                name: g.promotionCategory.name,
                promoCatId: g.promotionCategory.promotionCategoryId,
                presentId: g.id,
              };
            } else {
              giftsData[String(g.promotionCategory.promotionCategoryId)] = {
                count: g.count,
                variants: g.variants.map((v) => v.variantId),
                name: g.promotionCategory.name,
                promoCatId: g.promotionCategory.promotionCategoryId,
                presentId: g.id,
              };
            }
          });
          setPromocodeValueId(resp.data.promoCodeValueId);

          API.get(`promoCodes/${resp.data.promoCodeId}`).then((getResponse) => {
            setData(giftsData);
            setCodeInfo(getResponse.data);
          });
        }
      })
      .catch((e) =>
        alertError(
          alertContext,
          getErrorMsg(e.response, e.response.status === 404 ? "Промокод не найден" : "Ошибка получения данных о промокоде")
        )
      );
  };

  const applyCode = (ddd?: OrderCommonPromoCodeItem[]) => {
    if (gift === "prods" && ddd) {
      const promoCodeObject = {
        id: codeInfo.id,
        name: codeInfo.name,
        value: codeValue,
        presents: ddd,
        promocodeValueId: promocodeValueId,
      };

      setShowModal(false);
      setStage(3);

      return onApply(promoCodeObject);
    } else if (gift === "discount") {
      const promoCodeObject = {
        id: codeInfo.id,
        name: codeInfo.name,
        value: codeValue,
        discount: discountValue,
        presents: [],
        promocodeValueId: promocodeValueId,
      };

      setShowModal(false);
      setStage(3);

      return onApply(promoCodeObject);
    }
  };

  // useEffect(() => {
  //   if (stage === 3 && customerId) enterCode();
  // }, [items, customerId, stage]);

  useEffect(() => {
    if (appliedPromoCode) {
      setStage(3);
    } else {
      setStage(1);
    }
  }, [appliedPromoCode]);

  useEffect(() => {
    if (Object.values(data).length && stage === 2) {
      setShowModal(true);
    }
  }, [data]);

  useEffect(() => {
    if (stage === 2) {
      if (!lodash.isEqual(prevItems, items)) {
        setGift(undefined);
        setDiscountValue(0);
        setStage(1);
        alertWarning(
          alertContext,
          "Состав заказа изменился, промокод аннулирован"
        );
      }
    }
  }, [items]);

  return (
    <Box>
      {gift === "discount" && stage === 2 ? (
        <Grid container direction={"column"} style={{ padding: "5px" }}>
          <Typography variant={"h6"} style={{ margin: "5px 5px 5px 20px" }}>
            Размер скидки: {discountValue}
          </Typography>
          <Divider />
          <Grid
            container
            direction={"row"}
            justify={"space-around"}
            style={{ paddingTop: "10px" }}
          >
            <Button
              disabled={disabled}
              variant={"contained"}
              color={"primary"}
              onClick={() => applyCode()}
            >
              Использовать
            </Button>
            <Button
              disabled={disabled}
              variant={"outlined"}
              color={"secondary"}
              onClick={() => setStage(1)}
            >
              Отменить
            </Button>
          </Grid>
        </Grid>
      ) : stage === 3 ? (
        <Grid container direction={"row"}>
          <TextField
            variant={"outlined"}
            value={"Промокод применен"}
            disabled
          />
          <Button
            disabled={disabled}
            size={"small"}
            variant={"outlined"}
            color={"primary"}
            onClick={() => setStage(1)}
          >
            Изменить
          </Button>
          <Button
            disabled={disabled}
            size={"small"}
            variant={"outlined"}
            color={"secondary"}
            onClick={() => {
              onDelete();
              setStage(1);
            }}
          >
            Удалить
          </Button>
        </Grid>
      ) : (
        <Grid container direction={"row"}>
          <TextField
            variant={"outlined"}
            value={codeValue}
            disabled={disabled}
            placeholder={"Введите промокод"}
            onChange={(e) => setCodeValue(e.target.value)}
          />
          <Button disabled={disabled} onClick={enterCode}>
            Применить
          </Button>
        </Grid>
      )}
      {gift === "prods" && showModal && (
        <GiftsModal
          apply={(data: OrderCommonPromoCodeItem[]) => applyCode(data)}
          commonProducts={commonProducts}
          data={data}
          close={() => setShowModal(false)}
        />
      )}
    </Box>
  );
};

export default reactMemo(DispatcherPromocodeForm);
