import React, { useState, useEffect, useContext, FC } from "react";
import {
  Customer,
  ActivityStatus,
  AlertContextType,
  UserCookie,
} from "../../../_shared/types";
import {
  alertError,
  alertSuccess,
  downloadFile,
  getIntFromString,
  renderDate,
} from "../../../_shared/utils";
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import moment from "moment";
import API from "../../../_shared/axios";
import { AlertContext } from "../../_shared/ToastList";
import NewCustomerForm from "./NewCustomerForm";
import EditCustomerForm from "./EditCustomerForm";
import styles from "../../_shared/styles";
import { makeStyles } from "@material-ui/styles";
import { ProgressBar } from "../../_shared/ProgressBar";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { getClientsQuery } from "../../OrdersPage/shared";
import cookies from "../../../../cookies";

const useStyles = makeStyles({
  page: {
    height: "calc(100% - 112px)",
    margin: 0,
  },
  phoneNumber: {
    margin: "0px 20px 20px",
  },
  header: {
    marginBottom: 20,
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
  },
  paginationPaper: { marginTop: 20 },
  datePicker: {
    marginLeft: "5px !important",
  },
  activeFilterLabel: {
    marginLeft: 10,
    color: "rgba(0, 0, 0, 0.54)",
    "&:nth-child(2)": {
      marginLeft: 20,
    },
  },
  downloadPhones: {
    fontSize: 14,
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    "& > span": {
      marginLeft: "auto",
      borderBottom: "1px solid gray",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
  },
  filter: {
    fontSize: 14,
    fontWeight: 500,
    "& > div": {
      marginLeft: 20,
      padding: 0,
      width: 111,
      "& > label": {
        width: 111,
      },
    },
    display: "flex",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    margin: "0 auto 20px auto",
    padding: "25px 50px",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  clientTable: {
    flexGrow: 1,
    overflow: "scroll",
    "& th": {
      minWidth: 150,
    },
  },
  toggleButton: {
    maxWidth: "9rem",
    minWidth: "9rem",
  },
});

const CustomersPage: FC = () => {
  const [rows, setRows] = useState<Customer[]>([]);
  const [customerId, setCustomerId] = useState<number | undefined>(undefined);
  const [lastActiveFromFilter, setLastActiveFromFilter] = useState<Date>(
    new Date(
      new Date(Date.now()).setTime(
        new Date(Date.now()).getTime() - 24 * 60 * 60 * 1000
      )
    )
  );
  const [lastActiveToFilter, setLastActiveToFilter] = useState<Date>(
    new Date(Date.now())
  );
  const [lastActiveFilterFlag, setLastActiveFilterFlag] = useState(false);
  const [totalClients, setTotalClients] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const alertContext = useContext<AlertContextType>(AlertContext);
  const commonClasses = styles();
  const classes = useStyles();
  const [progress, setProgress] = useState(false);
  const [clientSearch, setClientSearch] = useState("");

  const user: UserCookie | undefined = cookies.get("user");
  const isOperator = user?.roles[0] === "Operator";

  const updateList = () =>
    API.get(
      getClientsQuery(
        clientSearch,
        page + 1,
        rowsPerPage,
        lastActiveFilterFlag
          ? { lastActiveFromFilter, lastActiveToFilter }
          : null
      )
    )
      .then(({ data }) => {
        setTotalClients(data.totalItems);
        setRows(data.items);
      })
      .catch((error) =>
        alertError(alertContext, "Ошибка получения списка клиентов")
      )
      .finally(() => setProgress(false));

  useEffect(() => {
    setProgress(true);
    updateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    rowsPerPage,
    clientSearch,
    lastActiveFilterFlag,
    lastActiveFromFilter,
    lastActiveToFilter,
  ]);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSend = (data: {
    phoneNumber: string;
    lastName: string;
    firstName: string;
    patronymic: string;
    email?: string;
    birthDay?: string;
  }) =>
    new Promise((resolve, reject) =>
      API.post("/customers", data)
        .then(({ data }) => {
          alertSuccess(alertContext, "Клиент добавлен");
          API.get(`/customers`)
            .then(({ data }) => {
              setRows(data.items);
              resolve(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка обновления списка клиентов");
              reject(error);
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка добавления клиента");
          reject(error);
        })
    );

  const onEdit = (
    id: number,
    data: {
      phoneNumber: string;
      lastName: string;
      firstName: string;
      patronymic: string;
      email?: string;
      birthDay?: string;
      tickets: number;
    }
  ) =>
    new Promise((resolve, reject) =>
      API.patch(`/customers/${id}`, data)
        .then(({ data }) => {
          API.get(`/customers`)
            .then(({ data }) => {
              setRows(data.items);
              alertSuccess(alertContext, "Успешное редактирование клиента");
              resolve(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка обновления списка клиентов");
              reject(error);
            });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка редактирования клиента");
          reject(error);
        })
    );

  const downloadPhones = () => {
    let query = "/customersPhones";
    if (lastActiveFilterFlag) {
      const dateFrom = new Date(lastActiveFromFilter.getTime());
      const dateTo = new Date(lastActiveToFilter.getTime());
      dateFrom.setHours(0);
      dateFrom.setMinutes(0);
      dateFrom.setSeconds(0);
      dateTo.setHours(24);
      dateTo.setMinutes(0);
      dateTo.setSeconds(0);
      query += `?$filter=LastActive gt ${dateFrom.toISOString()} and LastActive lt ${dateTo.toISOString()}`;
    }

    API.get(query, { responseType: "text" })
      .then(({ data }) => {
        downloadFile(data, "phone-numbers.cvs", "text/plain;charset=utf-8");
      })
      .catch((error) => alertError(alertContext, "Ошибка запроса телефонов"));
  };

  const getFullName = (row: Customer) => {
    if (!row.firstName && !row.lastName && !row.patronymic) return "-";
    let result: string[] = [];
    if (row.firstName) result = [...result, row.firstName];
    if (row.lastName) result = [...result, row.lastName];
    if (row.patronymic) result = [...result, row.patronymic];
    return result.join(", ");
  };

  useEffect(() => {
    setPage(0);
  }, [
    lastActiveFilterFlag,
    lastActiveToFilter,
    lastActiveFromFilter,
    clientSearch,
  ]);

  return (
    <div className={classes.page}>
      <EditCustomerForm
        onSend={onEdit}
        customerId={customerId}
        onClose={() => setCustomerId(undefined)}
      />
      <div className={classes.content}>
        <h2 className={classes.title}>
          <span>
            Клиенты &nbsp;&nbsp;{" "}
            {!isOperator && <NewCustomerForm onSend={onSend} />}
          </span>
          <div className={classes.downloadPhones}>
            <span onClick={downloadPhones}>
              скачать номера телефонов клиентов
            </span>
          </div>
        </h2>
        <Paper elevation={2} className={classes.header}>
          <div>
            <TextField
              className={classes.phoneNumber}
              id={"servicePhone"}
              label={"Клиент"}
              name={"servicePhone"}
              margin="normal"
              inputProps={{ maxLength: 11 }}
              type="text"
              value={clientSearch}
              onChange={(e) => {
                if (e.target.value === " ") return null;
                const v = String(getIntFromString(e.target.value));
                setClientSearch(v === "0" ? "" : v);
              }}
            />
          </div>
          <span className={classes.filter}>
            <FormControlLabel
              value={lastActiveFilterFlag}
              onChange={() =>
                setLastActiveFilterFlag(
                  (lastActiveFilterFlag) => !lastActiveFilterFlag
                )
              }
              control={<Checkbox color="primary" />}
              label="Фильтр по дате активности"
              labelPlacement="start"
            />
            {lastActiveFilterFlag && (
              <>
                <span className={classes.activeFilterLabel}>С</span>
                <DatePicker
                  className={classes.datePicker}
                  variant="inline"
                  format="DD.MM.YYYY"
                  value={lastActiveFromFilter}
                  onChange={(date: MaterialUiPickersDate) => {
                    if (date) setLastActiveFromFilter(date.toDate());
                  }}
                />
                <span className={classes.activeFilterLabel}>ПО</span>
                <DatePicker
                  className={classes.datePicker}
                  variant="inline"
                  format="DD.MM.YYYY"
                  value={lastActiveToFilter}
                  onChange={(date: MaterialUiPickersDate) => {
                    if (date) setLastActiveToFilter(date.toDate());
                  }}
                />
              </>
            )}
          </span>
        </Paper>
        {progress ? (
          <ProgressBar />
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label="simple table" className={classes.clientTable}>
              <TableHead>
                <TableRow>
                  <TableCell>ФИО</TableCell>
                  <TableCell align="left">Дата рождения</TableCell>
                  <TableCell align="left">Контактный телефон</TableCell>
                  <TableCell align="left">Электронная почта</TableCell>
                  <TableCell align="left">Последняя активность</TableCell>
                  <TableCell align="left">Доступно тикетов</TableCell>
                  <TableCell align="left">Статус клиента</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: Customer, key: number) => (
                  <TableRow
                    key={key}
                    className={
                      !row.isActive ? commonClasses.blocked : undefined
                    }
                    hover
                    onClick={() => (isOperator ? null : setCustomerId(row.id))}
                  >
                    <TableCell component="th" scope="row">
                      {getFullName(row)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.birthDay
                        ? moment(row.birthDay).format("DD.MM.YYYY")
                        : "-"}
                    </TableCell>
                    <TableCell align="left">
                      <a href={`tel:${row.phoneNumber}`}>{row.phoneNumber}</a>
                    </TableCell>
                    <TableCell align="left">
                      {row.email ? (
                        <a href={`mailto:${row.email}`}>{row.email}</a>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.lastActive
                        ? renderDate(row.lastActive, false, true)
                        : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {typeof row.tickets !== "undefined" ? row.tickets : "-"}
                    </TableCell>
                    <TableCell align="left">
                      <ToggleButton
                        className={classes.toggleButton}
                        selected={row.isActive}
                        value={ActivityStatus[row.isActive.toString()]}
                        onChange={(e) => {
                          e.stopPropagation();
                          API.post(
                            `/customers/${row.id}/${
                              row.isActive ? "block" : "unblock"
                            }`
                          )
                            .then(() =>
                              API.get(`/customers`)
                                .then(({ data }) => setRows(data.items))
                                .catch((error) =>
                                  alertError(
                                    alertContext,
                                    "Ошибка обновления списка клиентов"
                                  )
                                )
                            )
                            .catch((error) =>
                              alertError(
                                alertContext,
                                "Ошибка блокировки клиента"
                              )
                            );
                        }}
                      >
                        {ActivityStatus[row.isActive.toString()]}
                      </ToggleButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Paper className={classes.paginationPaper}>
          <TablePagination
            labelRowsPerPage={"Клиентов на странице:"}
            labelDisplayedRows={({ from, to }) =>
              `${from}-${to} из ${totalClients}`
            }
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={totalClients}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
};

export default CustomersPage;
