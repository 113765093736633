import React, { FC, FormEvent, useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import {
  alertError,
  alertSuccess,
  copyObject,
  getErrorMsg,
  getFloatFromString,
  getIntFromString,
  isObjectsEqual,
  isPhoneNotValid,
} from "../_shared/utils";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import API from "../_shared/axios";
import { AlertContextType } from "../_shared/types";
import { AlertContext } from "./_shared/ToastList";

const useStyles = makeStyles({
  addIcon: {
    zIndex: 10,
    position: "absolute",
    right: -50,
    top: 10,
  },
  template: {
    minWidth: 320,
    position: "relative",
    width: "60%",
    display: "flex",
    justifyContent: "flex-start",
    "& .MuiInputBase-root": {
      width: "100% !important",
    },
  },
  colorError: { color: "#f44336" },
  colorBlack: { color: "rgba(0, 0, 0, 0.87)" },
  templatesWrapper: {
    display: "flex",
    flexWrap: "wrap",
    "& > div": {
      minWidth: 370,
      width: "50%",
    },
  },
  contactsAndNumbers: {
    display: "flex",
    flexWrap: "wrap",
    "& > div": {
      minWidth: 370,
      width: "50%",
    },
  },
  labelError: {
    "& > div": {
      "& Label.MuiFormLabel-root": {
        color: "#f44336",
        "&.Mui-focused": {
          color: "#f44336 !important",
        },
      },
    },
  },
  content: {
    boxShadow:
      "0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)",
    backgroundColor: "white",
    padding: "25px 50px 50px 50px",
    maxWidth: 1140,
    width: "100%",
    borderRadius: 10,
    margin: "30px auto",
    "& button[type=submit]": {
      marginTop: 15,
    },
    "& .MuiInputBase-root": {
      minWidth: 320,
      width: "60%",
    },
    "& .MuiOutlinedInput-root": {
      paddingRight: 5,
    },
    "& .MuiFormLabel-root": {
      backgroundColor: "white",
      padding: 5,
    },
    "& .MuiFormControl-root": {
      marginTop: 5,
      marginBottom: 5,
    },
  },
  metaContent: {
    "& .MuiInputBase-root": {
      width: "50%",
    },
  },
  phoneNumber: {
    margin: 0,
  },
  requiredWarning: {
    color: "#f44336",
    margin: "4px 0 0 0",
    fontSize: "0.75rem",
    letterSpacing: "0.03333em",
    lineHeight: "1.66",
  },
  title: {
    marginBottom: 10,
    fontSize: 16,
  },
});

const emailRegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

const SettingsPage: FC = () => {
  const classes = useStyles();

  const [progress, setProgress] = useState(false);
  const [mobileOrderNumber, setMobileOrderNumber] = useState("");
  const [siteOrderNumber, setSiteOrderNumber] = useState("");
  const [tickets, setTickets] = useState(0);
  const [minPrice, setMinPrice] = useState("0");
  const [metaContent, setMetaContent] = useState("");
  const [deliveryTemplate, setDeliveryTemplate] = useState<string[]>([""]);
  const [pickupTemplate, setPickupTemplate] = useState<string[]>([""]);
  const [daysBefore, setDaysBefore] = useState<number>(0);
  const [daysAfter, setDaysAfter] = useState<number>(0);
  const [contacts, setContacts] = useState<{
    email: string;
    orderPhone: string;
    address: string;
    servicePhone: string;
    description: string;
  }>({
    email: "",
    orderPhone: "",
    address: "",
    description: "",
    servicePhone: "",
  });

  const [oldMobileOrderNumber, setOldMobileOrderNumber] = useState("");
  const [oldSiteOrderNumber, setOldSiteOrderNumber] = useState("");
  const [oldTickets, setOldTickets] = useState(0);
  const [oldMinPrice, setOldMinPrice] = useState("0");
  const [oldMetaContent, setOldMetaContent] = useState("");
  const [oldDeliveryTemplate, setOldDeliveryTemplate] = useState<string[]>([
    "",
  ]);
  const [oldPickupTemplate, setOldPickupTemplate] = useState<string[]>([""]);
  const [oldDaysBefore, setOldDaysBefore] = useState<number>(0);
  const [oldDaysAfter, setOldDaysAfter] = useState<number>(0);
  const [oldContacts, setOldContacts] = useState<{
    email: string;
    orderPhone: string;
    address: string;
    servicePhone: string;
    description: string;
  }>({
    email: "",
    orderPhone: "",
    address: "",
    description: "",
    servicePhone: "",
  });

  const isBlurredInit = {
    description: false,
    email: false,
    name: false,
    servicePhone: false,
    orderPhone: false,
    metaContent: false,
    mobileOrderNumber: false,
    siteOrderNumber: false,
    tickets: false,
    minPrice: false,
    deliveryTemplate: false,
    pickupTemplate: false,
    daysBefore: false,
    daysAfter: false,
  };
  const [isBlurred, setIsBlurred] = useState(isBlurredInit);
  const setBlurredField = (field: string) => {
    setIsBlurred({ ...isBlurred, [field]: true });
  };

  const alertContext = useContext<AlertContextType>(AlertContext);
  useEffect(() => {
    API.get("./settings/promoBirthdayRange")
      .then(({ data }) => {
        setOldDaysBefore(data.beforeBirthday);
        setOldDaysAfter(data.afterBirthday);
        setDaysBefore(data.beforeBirthday);
        setDaysAfter(data.afterBirthday);
      })
      .catch((error) => {
        alertError(
          alertContext,
          "Ошибка получения диапазона работы промокода 'День рождения'"
        );
      });
    API.get("/settings/orders/messages")
      .then(({ data }) => {
        setOldDeliveryTemplate(data.deliveryTemplate);
        setOldPickupTemplate(data.pickupTemplate);
        setDeliveryTemplate(data.deliveryTemplate);
        setPickupTemplate(data.pickupTemplate);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения шаблонов сообщений")
        );
      });
    API.get("/settings/orders/minPrice")
      .then(({ data }) => {
        setMinPrice(data.price);
        setOldMinPrice(data.price);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения минимальной цены")
        );
      });
    API.get("/settings/contacts")
      .then(({ data }) => {
        const { email, address, description, orderPhone, servicePhone } = data;
        setContacts({
          email,
          address: address,
          description,
          orderPhone,
          servicePhone,
        });
        setOldContacts({
          email,
          address: address,
          description,
          orderPhone,
          servicePhone,
        });
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения контактов")
        );
      });
    API.get("/settings/metaContent")
      .then(({ data }) => {
        setMetaContent(data.content);
        setOldMetaContent(data.content);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения мета-данных")
        );
      });
    API.get("/settings/tickets")
      .then(({ data }) => {
        setTickets(data.value);
        setOldTickets(data.value);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(
            error.response,
            "Ошибка получения процента зачисления тикетов"
          )
        );
      });
    API.get("/settings/phones")
      .then(({ data }) => {
        const { mobileOrderNumber, siteOrderNumber } = data;
        setMobileOrderNumber(mobileOrderNumber.phone);
        setSiteOrderNumber(siteOrderNumber.phone);
        setOldMobileOrderNumber(copyObject(mobileOrderNumber.phone));
        setOldSiteOrderNumber(copyObject(siteOrderNumber.phone));
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения телефонных номеров")
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAnyChanged = () =>
    getPhoneWithoutRedurant(oldMobileOrderNumber) !==
      getPhoneWithoutRedurant(mobileOrderNumber) ||
    getPhoneWithoutRedurant(oldSiteOrderNumber) !==
      getPhoneWithoutRedurant(siteOrderNumber) ||
    +oldTickets !== +tickets ||
    +oldMinPrice !== +minPrice ||
    oldMetaContent !== metaContent ||
    !isObjectsEqual(oldDeliveryTemplate, deliveryTemplate) ||
    !isObjectsEqual(oldPickupTemplate, pickupTemplate) ||
    !isObjectsEqual(oldContacts, contacts) ||
    !isObjectsEqual(oldDaysAfter, daysAfter) ||
    !isObjectsEqual(oldDaysBefore, daysBefore);

  const getPhoneWithoutRedurant = (tel: string) =>
    ("+" + tel.replace("(", "").replace(")", "").replaceAll("-", "")).replace(
      /.+?(?=\+)/,
      ""
    );

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    setProgress(true);
    e.preventDefault();
    let isSuccess = {
      fields: [
        { field: "messages", value: false },
        { field: "minPrice", value: false },
        { field: "contacts", value: false },
        { field: "metaContent", value: false },
        { field: "tickets", value: false },
        { field: "phones", value: false },
        { field: "promoBirthdayRange", value: false },
      ],
      counter: 0,
    };
    const changeIsSuccessField = (fieldName: string) => {
      let index = 0;
      isSuccess.fields.filter((el, i: number) => {
        if (el.field === fieldName) index = i;
        return true;
      });
      isSuccess.fields[index].value = true;
      isSuccess.counter = isSuccess.counter + 1;
      if (
        isSuccess.counter === 7 &&
        isSuccess.fields.filter((el) => !el.value).length === 0
      ) {
        setOldMobileOrderNumber(mobileOrderNumber);
        setOldSiteOrderNumber(siteOrderNumber);
        setOldTickets(tickets);
        setOldMinPrice(minPrice);
        setOldMetaContent(metaContent);
        setOldDeliveryTemplate(deliveryTemplate);
        setOldPickupTemplate(pickupTemplate);
        setOldDaysAfter(daysAfter);
        setOldDaysBefore(daysBefore);
        setOldContacts(contacts);
        setProgress(false);
        alertSuccess(alertContext, "Настройки успешно сохранены");
      }
    };
    API.post("/settings/orders/messages", {
      deliveryTemplate,
      pickupTemplate,
    })
      .then(() => {
        changeIsSuccessField("messages");
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка сохранения шаблонов сообщений")
        );
      });
    API.post("/settings/orders/minPrice", { price: minPrice })
      .then(() => {
        changeIsSuccessField("minPrice");
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(
            error.response,
            "Ошибка сохранения минимальной суммы заказа"
          )
        );
      });
    API.post("/settings/promoBirthdayRange", {
      beforeBirthday: daysBefore,
      afterBirthday: daysAfter,
    })
      .then(() => {
        changeIsSuccessField("promoBirthdayRange");
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(
            error.response,
            "Ошибка сохранения диапазона работы акции 'День рождения'"
          )
        );
      });
    const { email, orderPhone, address, servicePhone, description } = contacts;
    API.post("/settings/contacts", {
      email,
      orderPhone,
      address: address,
      servicePhone,
      description,
    })
      .then(() => {
        changeIsSuccessField("contacts");
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка сохранения контактов")
        );
      });
    API.post("/settings/metaContent", { content: metaContent })
      .then(() => {
        changeIsSuccessField("metaContent");
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка сохранения контактов")
        );
      });
    API.post("/settings/tickets", { value: tickets })
      .then(() => {
        changeIsSuccessField("tickets");
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(
            error.response,
            "Ошибка сохранения процентов начисления тикетов"
          )
        );
      });
    API.post("/settings/phones", {
      mobileOrderNumber: {
        phone: getPhoneWithoutRedurant(mobileOrderNumber),
        isEnabled: true,
      },
      siteOrderNumber: {
        phone: getPhoneWithoutRedurant(siteOrderNumber),
        isEnabled: true,
      },
    })
      .then(() => {
        changeIsSuccessField("phones");
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка сохранения телефонных номеров")
        );
      });
  };

  const isSubmitDisable = () => {
    return (
      contacts.description === "" ||
      !emailRegExp.test(contacts.email) ||
      contacts.address === "" ||
      isPhoneNotValid(contacts.orderPhone) ||
      isPhoneNotValid(contacts.servicePhone) ||
      isPhoneNotValid(mobileOrderNumber) ||
      isPhoneNotValid(siteOrderNumber) ||
      tickets.toString() === "0" ||
      minPrice.toString() === "0" ||
      metaContent === "" ||
      deliveryTemplate.filter((el) => el === "").length > 0 ||
      pickupTemplate.filter((el) => el === "").length > 0
    );
  };

  return (
    <div className={classes.content}>
      <h2>Настройки</h2>
      <form onSubmit={onSubmit}>
        <div className={classes.contactsAndNumbers}>
          <div>
            <div className={classes.title}>Контакты</div>
            <TextField
              id={"description"}
              label={"Описание"}
              value={contacts?.description}
              onChange={(e) =>
                setContacts({ ...contacts, description: e.target.value })
              }
              variant={"outlined"}
              multiline
              name={"description"}
              margin="normal"
              type="text"
              fullWidth
              onBlur={() => setBlurredField("description")}
              required
              error={!contacts.description && isBlurred.description}
              helperText={
                !contacts.description && isBlurred.description
                  ? "Поле обязательно для заполнения"
                  : ""
              }
            />
            <TextField
              id={"email"}
              label={"Email"}
              variant={"outlined"}
              value={contacts.email}
              onChange={(e) => {
                setContacts({
                  ...contacts,
                  email: e.target.value.toLowerCase(),
                });
              }}
              name={"email"}
              required
              onBlur={() => setBlurredField("email")}
              error={!emailRegExp.test(contacts.email) && isBlurred.email}
              helperText={
                !contacts.email && isBlurred.email
                  ? "Поле обязательно для заполнения"
                  : !emailRegExp.test(contacts.email)
                  ? "Email невалидный"
                  : ""
              }
              margin="normal"
              type="text"
              fullWidth
            />
            <TextField
              id={"address"}
              label={"Адрес"}
              variant={"outlined"}
              value={contacts.address}
              onBlur={() => setBlurredField("address")}
              onChange={(e) =>
                setContacts({ ...contacts, address: e.target.value })
              }
              name={"address"}
              margin="normal"
              type="text"
              required
              error={!contacts.address && isBlurred.name}
              helperText={
                !contacts.address && isBlurred.name
                  ? "Поле обязательно для заполнения"
                  : ""
              }
              fullWidth
            />
            <InputMask
              mask="+7(999)999-99-99"
              value={contacts.servicePhone}
              onBlur={() => setBlurredField("servicePhone")}
              onChange={(e) => {
                setContacts({ ...contacts, servicePhone: e.target.value });
              }}
            >
              {() => (
                <TextField
                  className={classes.phoneNumber}
                  id={"servicePhone"}
                  variant={"outlined"}
                  label={"Телефон отдела сервиса"}
                  name={"servicePhone"}
                  required
                  error={
                    isBlurred.servicePhone &&
                    (!contacts.servicePhone ||
                      isPhoneNotValid(contacts.servicePhone))
                  }
                  helperText={
                    !contacts.servicePhone && isBlurred.servicePhone
                      ? "Поле обязательно для заполнения"
                      : isPhoneNotValid(contacts.servicePhone)
                      ? "Номер невалидный"
                      : ""
                  }
                  margin="normal"
                  type="text"
                  fullWidth
                />
              )}
            </InputMask>
            <InputMask
              mask="+7(999)999-99-99"
              value={contacts.orderPhone}
              onBlur={() => setBlurredField("orderPhone")}
              onChange={(e) => {
                setContacts({ ...contacts, orderPhone: e.target.value });
              }}
            >
              {() => (
                <TextField
                  className={classes.phoneNumber}
                  id={"orderPhone"}
                  label={"Телефон для заказов"}
                  variant={"outlined"}
                  name={"orderPhone"}
                  required
                  error={
                    isBlurred.orderPhone &&
                    (!contacts.orderPhone ||
                      isPhoneNotValid(contacts.orderPhone))
                  }
                  helperText={
                    !contacts.orderPhone && isBlurred.orderPhone
                      ? "Поле обязательно для заполнения"
                      : isPhoneNotValid(contacts.orderPhone)
                      ? "Номер невалидный"
                      : ""
                  }
                  margin="normal"
                  type="text"
                  fullWidth
                />
              )}
            </InputMask>
          </div>
          <div>
            <div className={classes.title}>
              Телефоны для смс-оповещений о заказе
            </div>
            <InputMask
              mask="+7(999)999-99-99"
              value={mobileOrderNumber}
              onBlur={() => setBlurredField("mobileOrderNumber")}
              onChange={(e) => setMobileOrderNumber(e.target.value)}
            >
              {() => (
                <TextField
                  className={classes.phoneNumber}
                  id={"mobileOrderNumber"}
                  label={"Номер для мобильных"}
                  variant={"outlined"}
                  name={"mobileOrderNumber"}
                  margin="normal"
                  required
                  error={
                    isBlurred.mobileOrderNumber &&
                    (!mobileOrderNumber || isPhoneNotValid(mobileOrderNumber))
                  }
                  helperText={
                    !mobileOrderNumber && isBlurred.mobileOrderNumber
                      ? "Поле обязательно для заполнения"
                      : isPhoneNotValid(mobileOrderNumber)
                      ? "Номер невалидный"
                      : ""
                  }
                  type="text"
                  fullWidth
                />
              )}
            </InputMask>
            <InputMask
              mask="+7(999)999-99-99"
              value={siteOrderNumber}
              onChange={(e) => setSiteOrderNumber(e.target.value)}
              onBlur={() => setBlurredField("siteOrderNumber")}
            >
              {() => (
                <TextField
                  id={"phone"}
                  label={"Номер для сайта"}
                  variant={"outlined"}
                  name={"siteOrderNumber"}
                  margin="normal"
                  type="text"
                  required
                  error={
                    isBlurred.siteOrderNumber &&
                    (!siteOrderNumber || isPhoneNotValid(siteOrderNumber))
                  }
                  helperText={
                    !siteOrderNumber && isBlurred.siteOrderNumber
                      ? "Поле обязательно для заполнения"
                      : isPhoneNotValid(siteOrderNumber)
                      ? "Номер невалидный"
                      : ""
                  }
                  fullWidth
                />
              )}
            </InputMask>
            <br />
            <br />
            <div>
              <div className={classes.title}>Настройка заказов</div>
              <TextField
                id="tickets"
                label="Процент начисления тикетов"
                variant={"outlined"}
                inputProps={{ maxLength: 10 }}
                value={tickets}
                onChange={(e) =>
                  e.target.value === " "
                    ? null
                    : setTickets(
                        getIntFromString(e.target.value) < 101
                          ? getIntFromString(e.target.value)
                          : tickets
                      )
                }
                required
                onBlur={() => setBlurredField("tickets")}
                error={!tickets && isBlurred.tickets}
                helperText={
                  !tickets && isBlurred.tickets
                    ? "Поле обязательно для заполнения"
                    : ""
                }
                type="text"
                fullWidth
              />
              <TextField
                id="minPrice"
                label="Минимальная сумма заказа для доставки"
                variant={"outlined"}
                inputProps={{ maxLength: 10 }}
                value={minPrice}
                onBlur={() => setBlurredField("minPrice")}
                onChange={(e) =>
                  e.target.value === " "
                    ? null
                    : setMinPrice(getFloatFromString(e.target.value))
                }
                type="text"
                fullWidth
                required
                error={minPrice === "0" && isBlurred.minPrice}
                helperText={
                  minPrice === "0" && isBlurred.minPrice
                    ? "Поле обязательно для заполнения"
                    : ""
                }
              />
            </div>
          </div>
          <div>
            <div>
              <div className={classes.title}>
                Диапазон промокода День Рождения
              </div>
              <TextField
                id="daysBefore"
                label="Дней до"
                variant={"outlined"}
                inputProps={{ maxLength: 10 }}
                value={daysBefore}
                onChange={(e) =>
                  e.target.value === " "
                    ? null
                    : setDaysBefore(
                        getIntFromString(e.target.value) < 10000
                          ? getIntFromString(e.target.value)
                          : daysBefore
                      )
                }
                required
                onBlur={() => setBlurredField("daysBefore")}
                error={daysBefore < 0 && isBlurred.daysBefore}
                helperText={
                  daysBefore < 0 && isBlurred.daysBefore
                    ? "Поле обязательно для заполнения  и > 0"
                    : ""
                }
                type="text"
                fullWidth
              />
              <TextField
                id="daysAfter"
                label="Дней после"
                variant={"outlined"}
                inputProps={{ maxLength: 10 }}
                value={daysAfter}
                onBlur={() => setBlurredField("daysAfter")}
                onChange={(e) =>
                  e.target.value === " "
                    ? null
                    : setDaysAfter(
                        getIntFromString(e.target.value) < 10000
                          ? getIntFromString(e.target.value)
                          : daysAfter
                      )
                }
                type="text"
                fullWidth
                required
                error={daysAfter < 0 && isBlurred.daysAfter}
                helperText={
                  daysAfter < 0 && isBlurred.daysAfter
                    ? "Поле обязательно для заполнения и > 0"
                    : ""
                }
              />
            </div>
          </div>
        </div>
        <br />
        <TextField
          id={"metaContent"}
          label={"Мета-данные"}
          className={classes.metaContent}
          value={metaContent}
          onChange={(e) => setMetaContent(e.target.value)}
          variant={"outlined"}
          multiline
          onBlur={() => setBlurredField("metaContent")}
          name={"metaContent"}
          margin="normal"
          type="text"
          fullWidth
          required
          error={!metaContent && isBlurred.metaContent}
          helperText={
            !metaContent && isBlurred.metaContent
              ? "Поле обязательно для заполнения"
              : ""
          }
        />
        <br />
        <br />
        <div className={classes.title}>
          Шаблоны сообщений при обработке заказа для доставки и самовывоза
        </div>
        <div className={classes.templatesWrapper}>
          <div>
            {deliveryTemplate.map((template, key) => (
              <div
                className={[
                  classes.template,
                  template === "" && isBlurred.deliveryTemplate
                    ? classes.labelError
                    : "",
                ].join(" ")}
                key={key}
              >
                <FormControl variant={"outlined"}>
                  <InputLabel
                    className={
                      template === "" && isBlurred.deliveryTemplate
                        ? classes.colorError
                        : ""
                    }
                  >
                    Шаблон для доставки *
                  </InputLabel>
                  <OutlinedInput
                    key={key}
                    value={template}
                    multiline
                    className={[
                      template === "" && isBlurred.deliveryTemplate
                        ? classes.colorError
                        : classes.colorBlack,
                    ].join(" ")}
                    onChange={(e) =>
                      setDeliveryTemplate(
                        deliveryTemplate.map((el, index) =>
                          key === index ? e.target.value : el
                        )
                      )
                    }
                    name={"deliveryTemplate"}
                    type="text"
                    fullWidth
                    required
                    onBlur={() => setBlurredField("deliveryTemplate")}
                    error={
                      deliveryTemplate[key] === "" && isBlurred.deliveryTemplate
                    }
                    endAdornment={
                      deliveryTemplate.length > 1 && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setDeliveryTemplate(
                                deliveryTemplate.filter(
                                  (el, index: number) => index !== key
                                )
                              )
                            }
                          >
                            <DeleteOutlineIcon fontSize={"small"} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                  {template === "" && isBlurred.deliveryTemplate && (
                    <p className={classes.requiredWarning}>
                      Поле обязательно для заполнения
                    </p>
                  )}
                </FormControl>
                {key === 0 && (
                  <IconButton
                    className={classes.addIcon}
                    onClick={() =>
                      setDeliveryTemplate([...deliveryTemplate, ""])
                    }
                  >
                    <AddIcon fontSize={"small"} />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
          <div>
            {pickupTemplate.map((template, key) => (
              <div
                className={[
                  classes.template,
                  template === "" && isBlurred.pickupTemplate
                    ? classes.labelError
                    : "",
                ].join(" ")}
                key={key}
              >
                <FormControl variant={"outlined"}>
                  <InputLabel
                    className={
                      template === "" && isBlurred.pickupTemplate
                        ? classes.colorError
                        : ""
                    }
                  >
                    Шаблон для самовывоза *
                  </InputLabel>
                  <OutlinedInput
                    key={key}
                    multiline
                    value={template}
                    className={[
                      template === "" && isBlurred.pickupTemplate
                        ? classes.colorError
                        : classes.colorBlack,
                    ].join(" ")}
                    onChange={(e) =>
                      setPickupTemplate(
                        pickupTemplate.map((el, index) =>
                          key === index ? e.target.value : el
                        )
                      )
                    }
                    name={"pickupTemplate"}
                    type="text"
                    fullWidth
                    required
                    onBlur={() => setBlurredField("pickupTemplate")}
                    error={
                      pickupTemplate[key] === "" && isBlurred.pickupTemplate
                    }
                    endAdornment={
                      pickupTemplate.length > 1 && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setPickupTemplate(
                                pickupTemplate.filter(
                                  (el, index: number) => index !== key
                                )
                              )
                            }
                          >
                            <DeleteOutlineIcon fontSize={"small"} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  />
                  {template === "" && isBlurred.pickupTemplate && (
                    <p className={classes.requiredWarning}>
                      Поле обязательно для заполнения
                    </p>
                  )}
                </FormControl>
                {key === 0 && (
                  <IconButton
                    className={classes.addIcon}
                    onClick={() => setPickupTemplate([...pickupTemplate, ""])}
                  >
                    <AddIcon fontSize={"small"} />
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        </div>

        <Button
          type="submit"
          variant={"outlined"}
          color={"primary"}
          disabled={isSubmitDisable() || !isAnyChanged() || progress}
        >
          Сохранить
        </Button>
      </form>
    </div>
  );
};

export default SettingsPage;
