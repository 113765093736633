import {
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, { FC, useCallback, useEffect, useState } from "react";
import Transition from "../_shared/Transition";
import API from "../../_shared/axios";
import { makeStyles } from "@material-ui/styles";
import { minutesAndIntervals as minutesAndIntervalsData } from "./minutesAndIntervals";
import { WaitingTimeType } from "./types";
import moment from "moment";

const useStyles = makeStyles({
  textField: { marginTop: 20 },
  waitingTimeField: {
    margin: 0,
    '& .MuiInput-formControl': {
      paddingTop: 6
    }
  },
  disabled: { color: "rgba(0, 0, 0, 0.26);" },
  radioGroup: {
    color: "#632423",
    display: "flex",
    flexDirection: "row",
  },
});

const OrderInWorkDialog: FC<{
  data: {
    readyTime?: string,
    orderNumber: number;
    receivingType: string;
    amountToBePaid: number;
  };
  open: boolean;
  onClose: () => void;
  onConfirm: ({
                text,
                waitingTime,
              }: {
    text: string;
    waitingTime: string;
  }) => void;
}> = ({ data, open, onClose, onConfirm }) => {
  const [template, setTemplate] = useState("");
  const [waitingTimeText, setWaitingTimeText] = useState("");
  const [defaultTemplate, setDefaultTemplate] = useState("");
  const [time, setTime] = useState(0);
  const [minutesAndIntervals, setMinutesAndIntervals] = useState<string[]>([]);
  const [waitingTimeType, setWaitingTimeType] = useState<
    WaitingTimeType
    >(data.readyTime && data.readyTime.length ? "time" : "minutes");
  const [waitingTimeIndex, setWaitingTimeIndex] = useState<number>();

  const classes = useStyles();

  const getFormattedTemplate = useCallback(
    (template: string) =>
      template
        .replace("{{order}}", String(data.orderNumber))
        .replace("{{total}}", String(data.amountToBePaid))
        .replace("{{time}}", String(waitingTimeType === "time" ? waitingTimeText : minutesAndIntervals[time])),
    [data.orderNumber, data.amountToBePaid, minutesAndIntervals, time]
  );

  useEffect(() => {
    setMinutesAndIntervals(
      waitingTimeType === "time" ? [] :
        minutesAndIntervalsData[
          data.receivingType === "delivery" ? "delivery" : "pickup"
          ][waitingTimeType]
    );
    setWaitingTimeText(data.readyTime ?  `к ${moment(data.readyTime).format("HH:mm")}` :
      `к ${moment(new Date()).format("HH:mm")}`);
  }, [waitingTimeType, data.receivingType, open]);

  useEffect(() => {
    if (open) {
      API.get("/settings/orders/messages").then(
        ({
           data: messages,
         }: {
          data: { deliveryTemplate: string[]; pickupTemplate: string[] };
        }) => {
          const isTimeType = waitingTimeType === "time";
          const templData = getFormattedTemplate(
            data.receivingType === "pickup"
              ? messages.pickupTemplate[isTimeType ? 1 :0]
              : messages.deliveryTemplate[isTimeType ? 1 : 0]
          );
          setWaitingTimeText(data.readyTime ?  `к ${moment(data.readyTime).format("HH:mm")}` :
            `к ${moment(new Date()).format("HH:mm")}`);
          setDefaultTemplate(templData);
          setTemplate(templData);
          setWaitingTimeIndex(templData.indexOf(waitingTimeText));
        }
      );
    }
  }, [open, data.receivingType, getFormattedTemplate]);

  useEffect(() => {
    const templData = `${defaultTemplate.slice(
      0,
      defaultTemplate.indexOf("течение") + 8
    )}${minutesAndIntervals[time]}`;
    setTemplate(templData);
    setDefaultTemplate(templData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <Dialog
      open={open}
      // @ts-ignore
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      fullWidth
      maxWidth={"xs"}
    >
      <DialogTitle id="alert-dialog-slide-title">
        {`Принять заказ № ${data.orderNumber}`}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <RadioGroup
            aria-label="waitingTimeType"
            name="waitingTimeType"
            className={classes.radioGroup}
            value={waitingTimeType}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.value !== "time") setTime(0);
              setWaitingTimeType(
                e.target.value as WaitingTimeType
              );
            }}
          >
            <FormControlLabel
              value={"minutes"}
              control={<Radio />}
              className={
                waitingTimeType !== "minutes" ? classes.disabled : ""
              }
              label="точно"
            />
            <FormControlLabel
              value={"intervals"}
              control={<Radio />}
              className={waitingTimeType !== "intervals" ? classes.disabled : ""}
              label="интервалы"
            />
            <FormControlLabel
              value={"time"}
              control={<Radio />}
              className={waitingTimeType !== "time" ? classes.disabled : ""}
              label="время"
            />
          </RadioGroup>
        </FormControl>
        <br />
        <br />
        {
          waitingTimeType === "time" ?
            <TextField
              className={classes.waitingTimeField}
              multiline
              margin="dense"
              inputProps={{ maxLength: 100 }}
              label="Время выполнения"
              value={waitingTimeText}
              onChange={(e) => {
                const val = e.target.value;
                setWaitingTimeText(val);
                setTemplate(template => template.slice(0, waitingTimeIndex) + val);
              }}
              type="text"
              fullWidth
            /> :
            <FormControl fullWidth>
              <InputLabel>Время выполнения</InputLabel>
              <Select value={time} onChange={(e: any) => setTime(e.target.value)}>
                {minutesAndIntervals.map((t, idx) => (
                  <MenuItem key={idx} value={idx}>
                    {t}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
        }
        <TextField
          className={classes.textField}
          multiline
          margin="dense"
          inputProps={{ maxLength: 255 }}
          label="SMS-сообщение для клиента"
          value={template}
          onChange={(e) => setTemplate(e.target.value)}
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Отмена
        </Button>
        <Button
          onClick={() =>
            onConfirm({
              text: template,
              waitingTime: waitingTimeType === "time" ? waitingTimeText : minutesAndIntervals[time],
            })
          }
          color="primary"
        >
          Принять
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderInWorkDialog;
