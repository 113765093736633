import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  CodeProductSet,
  ProductRow,
  ProductRowVariant,
  PromotionCategory,
} from "../../_shared/types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { createVariantLabel } from "../OrdersPage/shared";
import DeleteIcon from "@material-ui/icons/Delete";
import API from "../../_shared/axios";

const useStyles = makeStyles({
  marginedBlock: {
    margin: "10px 0 10px 0",
  },
  quant: {
    margin: "10px 10px 10px 10px",
  },
  allVariantsButtonGroup: {
    border: "solid 0px white",
    borderRadius: "5px",
    backgroundColor: "#eee",
    padding: "6px 12px 6px 12px",
    cursor: "pointer",
  },
});

const Item: FC<{
  set: CodeProductSet;
  disabled: boolean;
  setIndex: number;
  onChange: (set: CodeProductSet, index: number) => void;
  promoCategories: Array<PromotionCategory>;
  products: Array<ProductRow>;
  onDelete: (setIndex: number) => void;
}> = ({
  set,
  promoCategories,
  products,
  onChange,
  setIndex,
  onDelete,
  disabled,
}) => {
  const classes = useStyles();

  const [variants, setVariants] = useState<Array<number>>([]);
  const [tempVariants, setTempVariants] = useState<
    CodeProductSet["variantsList"]
  >([]);
  const [allVariantsHadler, setAllVariantsHandler] = useState(false);
  const [productOptions, setProductOptions] = useState<
    Array<{ key: number; id: number | null; name: string }>
  >([{ key: -1, id: null, name: "Вся категория" }]);
  const [commonVariants, setCommonVariants] = useState<ProductRowVariant[]>([]);
  const [showCommonVariants, setShowCommonVariants] = useState(true);
  const [isFirstShowCommon, setIsFirstShowCommon] = useState(true);

  const [filteredProds, setFilteredProds] = useState<Array<ProductRow>>([]);

  const createVariantLabelWithCommon = (variant: {
    variantId: number;
    values: Array<{
      optionId: number;
      optionData: string;
      optionValueId: number;
      optionValueData: string;
    }>;
  }) => {
    if (commonVariants.some((v) => v.variantId === variant.variantId)) {
      return `${variant.values
        .map((v) => v.optionData + ": " + v.optionValueData)
        .join(" ")} (Общий)`;
    } else {
      return `${variant.values
        .map((v) => v.optionData + ": " + v.optionValueData)
        .join(" ")}`;
    }
  };

  const onVariantClick = (variant: {
    variantId: number;
    values: Array<{
      optionId: number;
      optionData: string;
      optionValueId: number;
      optionValueData: string;
    }>;
  }) => {
    if (!variants.includes(variant.variantId)) {
      onChange(
        {
          ...set,
          variantsList: [...set.variantsList, variant],
        },
        setIndex
      );
    } else {
      const vIndex = set.variantsList.findIndex(
        (v) => v.variantId === variant.variantId
      );
      const arr = [...set.variantsList];
      arr.splice(vIndex, 1);
      onChange(
        {
          ...set,
          variantsList: arr,
        },
        setIndex
      );
    }
  };

  const allVariantsToggle = () => {
    if (set.productId) {
      if (!allVariantsHadler) {
        const vars = products.find((pr) => pr.id === set.productId)?.variants;
        if (!vars) return;
        onChange({ ...set, variantsList: vars }, setIndex);
        return setAllVariantsHandler(!allVariantsHadler);
      } else {
        onChange({ ...set, variantsList: [] }, setIndex);
        return setAllVariantsHandler(!allVariantsHadler);
      }
    } else {
      if (!allVariantsHadler) {
        onChange({ ...set, variantsList: commonVariants }, setIndex);
        return setAllVariantsHandler(!allVariantsHadler);
      } else {
        onChange({ ...set, variantsList: [] }, setIndex);
        return setAllVariantsHandler(!allVariantsHadler);
      }
    }
  };

  useEffect(() => {
    if (set.promotionCategoryId != null) {
      API.get(`/promotionCategories/${set.promotionCategoryId}`).then(
        ({ data }: { data: PromotionCategory }) => {
          const catProducts = products.filter((product) =>
            data.products
              .map((reqProduct) => reqProduct.id)
              .includes(product.id)
          );
          // варианты разделенные по товарам
          const allVariantsArrays = catProducts.map(
            (product) => product.variants
          );
          // все варианты вместе
          let allVariantsCombined = allVariantsArrays.reduce(
            (acc, sum) => [...acc, ...sum],
            []
          );
          // только общие варианты

          allVariantsCombined = allVariantsCombined.filter(
            (variant) =>
              allVariantsArrays.filter((variantArr) => {
                const variantArrIds = variantArr.map((el) => el.variantId);
                return variantArrIds.includes(variant.variantId);
              }).length === allVariantsArrays.length
          );
          // только уникальные по айди варианты
          allVariantsCombined = allVariantsCombined.filter(
            (variant, index) =>
              allVariantsCombined.findIndex(
                (el) => el.variantId === variant.variantId
              ) === index
          );
          setCommonVariants(allVariantsCombined);
        }
      );
    }
    if (set.variantsList) setVariants(set.variantsList.map((v) => v.variantId));
  }, [set]);

  useEffect(() => {
    setProductOptions([
      { key: -1, id: null, name: "Вся категория" },
      ...filteredProds.map((p, i) => {
        return { id: p.id, key: i, name: p.name };
      }),
    ]);
  }, [filteredProds]);

  useEffect(() => {
    const cat = promoCategories.find((p) => p.id === set.promotionCategoryId);
    if (cat) {
      const catProdsIds = cat.products.map((pc) => pc.id);
      const prods = products.filter((p) => {
        return catProdsIds.includes(p.id);
      });
      setFilteredProds(prods);
    }
  }, [set]);

  useEffect(() => {
    if (isFirstShowCommon) {
      setIsFirstShowCommon(false);
      return;
    }
    if (showCommonVariants) {
      setTempVariants(set.variantsList);
    }
    let newVariants: CodeProductSet["variantsList"];
    const setVariantsIds = set.variantsList.map((v) => v.variantId);
    const commonVariantsIds = commonVariants.map((v) => v.variantId);
    if (!commonVariants)
      newVariants = tempVariants.filter((variant) =>
        commonVariantsIds.includes(variant.variantId)
      );
    else {
      newVariants = commonVariants.filter((variant) =>
        setVariantsIds.includes(variant.variantId)
      );
    }
    !(
      products.find((p) => p.id === set.productId)?.variants.map((v) => v)
        .length === variants.length
    )
      ? onChange(
          {
            ...set,
            variantsList: newVariants ? newVariants : [],
          },
          setIndex
        )
      : onChange(
          {
            ...set,
            variantsList: [],
          },
          setIndex
        );
  }, [showCommonVariants]);

  const getVariantList = () =>
    set.productId
      ? set.variantsList
        ? products.find((p) => p.id === set.productId)?.variants || []
        : []
      : commonVariants;

  return (
    <Paper style={{ margin: "10px", padding: "20px" }}>
      <Box style={{ position: "relative" }}>
        <DeleteIcon
          style={{
            cursor: "pointer",
            float: "right",
            position: "absolute",
            right: "2px",
            top: "2px",
          }}
          onClick={() => (disabled ? () => undefined : onDelete(setIndex))}
        />
        <Grid
          container
          direction={"row"}
          alignItems="center"
          className={classes.marginedBlock}
        >
          <Autocomplete
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                style={{ width: "350px" }}
                {...params}
                variant={"outlined"}
                size={"small"}
                label={"Категория"}
              />
            )}
            options={promoCategories}
            getOptionLabel={(option) => option.name}
            value={promoCategories.find(
              (p) => p.id === set.promotionCategoryId
            )}
            onChange={(e, v) => {
              onChange(
                { ...set, promotionCategoryId: v ? v.id : null },
                setIndex
              );
            }}
          />
          {/*<Typography*/}
          {/*  style={{ marginLeft: "10px", cursor: "pointer" }}*/}
          {/*  onClick={(e) => {*/}
          {/*    onChange(*/}
          {/*      {*/}
          {/*        ...set,*/}
          {/*        productId: set.productId ? null : productOptions[0]?.id,*/}
          {/*      },*/}
          {/*      setIndex*/}
          {/*    );*/}
          {/*  }}*/}
          {/*  variant={"subtitle2"}*/}
          {/*>*/}
          {/*  Выбрать всю категорию*/}
          {/*</Typography>*/}
          {/*<Checkbox*/}
          {/*  aria-label={"Вся категория"}*/}
          {/*  color={"primary"}*/}
          {/*  checked={!set.productId}*/}
          {/*  onChange={(e) => {*/}
          {/*    onChange(*/}
          {/*      {*/}
          {/*        ...set,*/}
          {/*        productId: set.productId ? null : productOptions[0]?.id,*/}
          {/*      },*/}
          {/*      setIndex*/}
          {/*    );*/}
          {/*  }}*/}
          {/*/>*/}
        </Grid>
        <Grid
          container
          alignItems={"center"}
          direction={"row"}
          className={classes.quant}
        >
          <Typography component={"span"} style={{ marginRight: "10px" }}>
            Количество:
          </Typography>
          <TextField
            disabled={disabled}
            value={set.count}
            size={"small"}
            onChange={(e) =>
              onChange({ ...set, count: Number(e.target.value) }, setIndex)
            }
          />
        </Grid>
        <Grid
          container
          direction={"row"}
          className={classes.marginedBlock}
          spacing={2}
          alignContent={"space-between"}
          alignItems={"center"}
        >
          <Autocomplete
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: "450px" }}
                variant={"outlined"}
                size={"small"}
                label={set.productId ? "Товар" : "Выбрана вся категория"}
              />
            )}
            getOptionLabel={(option) => option.name}
            options={productOptions}
            value={
              productOptions.find((p) => p.id === set.productId)
                ? productOptions.find((p) => p.id === set.productId)
                : { key: -1, id: null, name: "Вся категория" }
            }
            onChange={(e, v) => {
              if (v === null) {
                onChange(
                  {
                    ...set,
                    variantsList: set.variantsList.filter((v) =>
                      commonVariants.some((cv) => cv.variantId === v.variantId)
                    ),
                    productId: null,
                  },
                  setIndex
                );
              } else {
                onChange(
                  {
                    ...set,
                    productId: v ? v.id : null,
                  },
                  setIndex
                );
              }
            }}
          />
        </Grid>
        <Grid
          container
          direction={"row"}
          className={classes.marginedBlock}
          spacing={2}
          alignContent={"space-between"}
          alignItems={"center"}
        >
          <Box className={classes.allVariantsButtonGroup}>
            <Typography
              onClick={disabled ? () => undefined : allVariantsToggle}
              variant={"subtitle2"}
              component={"span"}
            >
              Выбрать все варианты
            </Typography>
          </Box>
        </Grid>
        {/*{TODO: render optimization}*/}
        <Grid container>
          {getVariantList().length ? (
            getVariantList().map((v) => (
              <Grid
                key={v.variantId}
                container
                direction={"row"}
                alignItems={"center"}
                style={{ margin: "10px", width: "40%" }}
              >
                <span
                  onClick={() => (disabled ? undefined : onVariantClick(v))}
                >
                  <Checkbox
                    disabled={disabled}
                    checked={variants.includes(v.variantId)}
                  />
                  <Typography
                    style={{ cursor: "pointer" }}
                    component={"span"}
                    variant={"subtitle2"}
                  >
                    {createVariantLabelWithCommon(v)}
                  </Typography>
                </span>
              </Grid>
            ))
          ) : (
            <Typography>Нет доступных вариантов</Typography>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default Item;
