import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  IconButton,
  TextField,
} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import API from "../../_shared/axios";
import SearchIcon from "@material-ui/icons/Search";
import {
  AlertContextType,
  Employee,
  PromocodeValue,
} from "../../_shared/types";
import moment from "moment/moment";
import { DateTimePicker } from "@material-ui/pickers";
import { getPromocodeQuery } from "../OrdersPage/shared";
import { alertError } from "../../_shared/utils";
import { AlertContext } from "../_shared/ToastList";
import { ProgressBar } from "../_shared/ProgressBar";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  table: {
    marginTop: "30px",
    marginBottom: "50px",
  },
  content: {
    padding: 50,
    maxWidth: 1240,
    margin: "0 auto",
    "& th": {
      backgroundColor: "white",
    },
  },
  datePicker: {
    marginLeft: "5px !important",
  },
  header: {
    marginBottom: 20,
    padding: "12px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
  },
  filter: {
    fontSize: 14,
    fontWeight: 500,
    "& > div": {
      marginLeft: 20,
      padding: 0,
      width: 120,
      "& > label": {
        width: 111,
      },
    },
    display: "flex",
    alignItems: "center",
  },

  activeFilterLabel: {
    marginLeft: 10,
    color: "rgba(0, 0, 0, 0.54)",
    "&:nth-child(2)": {
      marginLeft: 20,
    },
  },
});

const ReportPromocodesPage = () => {
  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [codes, setCodes] = useState<Array<PromocodeValue>>([]);
  const [progress, setProgress] = useState(false);
  const [employees, setEmployees] = useState<Array<Employee>>([]);
  const [employeeId, setEmployeeId] = useState<number>(0);
  const [totalCodes, setTotalCodes] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [lastActiveFilterFlag, setLastActiveFilterFlag] = useState(false);
  const [valueSearch, setValueSearch] = useState("");
  const [customerSearch, setCustomerSearch] = useState("");

  const fromDefault = () =>
    new Date(
      new Date(Date.now()).setTime(
        new Date(Date.now()).getTime() - 24 * 60 * 60 * 1000
      )
    );
  const toDefault = () => new Date(Date.now());
  const [lastActiveFromFilter, setLastActiveFromFilter] = useState<Date>(
    fromDefault()
  );
  const [lastActiveToFilter, setLastActiveToFilter] = useState<Date>(
    toDefault()
  );

  useEffect(() => {
    updateCustomerList();
  }, []);

  useEffect(() => {
    setProgress(true);
    updateList().finally(() => setProgress(false));
  }, [
    page,
    rowsPerPage,
    employeeId,
    lastActiveFilterFlag,
    lastActiveFromFilter,
    lastActiveToFilter,
    customerSearch,
    valueSearch,
  ]);

  const updateCustomerList = () => {
    API.get("/employees")
      .then((resp) => {
        setEmployees(() => resp.data);
      })
      .catch((e) => console.error(e));
  };

  const updateList = () =>
    API.get(
      getPromocodeQuery(
        employeeId,
        page + 1,
        rowsPerPage,
        lastActiveFilterFlag
          ? { lastActiveFromFilter, lastActiveToFilter }
          : null,
        customerSearch,
        valueSearch
      )
    )
      .then(({ data }) => {
        setTotalCodes(data.totalItems);
        setCodes(data.items);
      })
      .catch((error) =>
        alertError(alertContext, "Ошибка получения списка сотрудников")
      );

  const getFullName = (row: Employee) => {
    if (!row.firstName && !row.lastName && !row.patronymic) return "-";
    let result: string[] = [];
    if (row.firstName) result = [...result, row.firstName];
    if (row.lastName) result = [...result, row.lastName];
    if (row.patronymic) result = [...result, row.patronymic];
    return result.join(", ");
  };

  return (
    <Container className={classes.content}>
      <Box component={"span"}>
        <h2>Отчет-промокоды</h2>
      </Box>
      <Paper className={classes.header}>
        <Select
          value={employeeId ?? ""}
          onChange={(e: any) => setEmployeeId(e.target.value)}
        >
          <MenuItem value={0}>Выберите сотрудника</MenuItem>
          {employees.map((el, key) => (
            <MenuItem key={key} value={el.id} disabled={!el.isActive}>
              {getFullName(el)}
            </MenuItem>
          ))}
        </Select>
        <span className={classes.filter}>
          <FormControlLabel
            value={lastActiveFilterFlag}
            onChange={() =>
              setLastActiveFilterFlag(
                (lastActiveFilterFlag) => !lastActiveFilterFlag
              )
            }
            control={<Checkbox color="primary" />}
            label="Фильтр по дате активности"
            labelPlacement="start"
          />
          {lastActiveFilterFlag && (
            <>
              <span className={classes.activeFilterLabel}>С</span>
              <DateTimePicker
                ampm={false}
                className={classes.datePicker}
                variant="inline"
                format="DD.MM HH:mm"
                value={lastActiveFromFilter}
                onChange={(d) => {
                  // @ts-ignore
                  setLastActiveFromFilter(d);
                  setPage(0);
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size={"small"}
                      onClick={() => setLastActiveFromFilter(fromDefault())}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
              <span className={classes.activeFilterLabel}>ПО</span>
              <DateTimePicker
                ampm={false}
                className={classes.datePicker}
                variant="inline"
                format="DD.MM HH:mm"
                value={lastActiveToFilter}
                onChange={(d) => {
                  // @ts-ignore
                  setLastActiveToFilter(d);
                  setPage(0);
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size={"small"}
                      onClick={() => setLastActiveToFilter(toDefault())}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            </>
          )}
        </span>
      </Paper>
      <Paper className={classes.header}>
        <Grid container spacing={3}>
          <Grid item>
            <TextField
              type={"text"}
              placeholder={"Значение"}
              value={valueSearch}
              onChange={(e) => {
                if (!e.target.value.includes(" ")) {
                  setPage(0);
                  setValueSearch(e.target.value);
                }
              }}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              type={"text"}
              placeholder={"Номер Клиента"}
              value={customerSearch}
              onChange={(e) => {
                if (
                  e.target.value.match(/^\d+$/) ||
                  e.target.value.length === 0
                ) {
                  setPage(0);
                  setCustomerSearch(e.target.value);
                }
              }}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.table}>
        {progress ? (
          <ProgressBar />
        ) : (
          <TableContainer>
            <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>Название</TableCell>
                  <TableCell>Значение</TableCell>
                  <TableCell>Дата создания значения</TableCell>
                  <TableCell>Кто создал (ФИО сотрудника)</TableCell>
                  <TableCell>Номер клиента</TableCell>
                  <TableCell>Статус</TableCell>
                  <TableCell>Комментарий значения</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {codes.map((c) => (
                  <TableRow key={c.value}>
                    <TableCell>{c.promoCodeName}</TableCell>
                    <TableCell>{c.value}</TableCell>
                    <TableCell>
                      {moment(c.createdDate).format("DD MMM HH:mm")}
                    </TableCell>
                    <TableCell>{c.employeeFullName}</TableCell>
                    <TableCell style={{ wordBreak: "keep-all" }}>
                      {c.customerPhoneNumber}
                    </TableCell>
                    <TableCell
                      style={{ wordBreak: "keep-all", fontWeight: "bold" }}
                    >
                      {c.isUsed ? "Использован" : c.isDeleted ? "Удалено" : ""}
                    </TableCell>
                    <TableCell>{c.commentary}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              labelRowsPerPage={"Промокодов на странице:"}
              labelDisplayedRows={({ from, to }) =>
                `${from}-${to} из ${totalCodes}`
              }
              rowsPerPageOptions={[10, 15, 25, 50]}
              component="div"
              count={totalCodes}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={(e) => {
                setRowsPerPage(Number(e.target.value));
                setPage(0);
              }}
              page={page}
              onChangePage={(e, newPage) => setPage(newPage ? newPage : 0)}
            />
          </TableContainer>
        )}
      </Paper>
    </Container>
  );
};

export default ReportPromocodesPage;
