import React, { FC, useContext, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AlertContextType, OrderRow } from "../../_shared/types";
import { TableBody, TablePagination, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ProgressBar } from "../_shared/ProgressBar";
import API from "../../_shared/axios";
import {
  alertError,
  getFormattedPhoneNumber,
  getIntFromString,
  renderDate,
} from "../../_shared/utils";
import { AlertContext } from "../_shared/ToastList";
import OrderForm from "../OrdersPage/OrderForm";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import { getOrdersQuery } from "../OrdersPage/shared";
import PhoneIcon from "@material-ui/icons/Phone";

const columns = [
  { id: "orderNumber", label: "Номер заказа" },
  { id: "clientPhoneNumber", label: "Клиент" },
  {
    id: "createdDate",
    label: "Создан",
  },
  {
    id: "sourceId",
    label: "Источник",
  },
  {
    id: "price",
    label: "Стоимость",
  },
  {
    id: "waitingTime",
    label: "Время выполнения",
  },
  {
    id: "status",
    label: "Статус",
  },
];

const useStyles = makeStyles({
  page: {
    height: "calc(100% - 64px)",
  },
  paginationPaper: { marginTop: 20 },
  cell: {
    minWidth: 155,
    "&:first-child": {
      minWidth: 140,
    },
    "&:nth-child(2), &:nth-child(3)": {
      minWidth: 170,
    },
    "&:nth-child(4), &:nth-child(5)": {
      minWidth: 120,
    },
    "&:last-child": {
      minWidth: 150,
    },
  },
  header: {
    marginBottom: 20,
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
  },
  phoneNumber: {
    margin: "0px 20px 20px",
  },
  content: {
    width: "100%",
    maxWidth: 1140,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "20px 30px",
    margin: "0 auto 20px auto",
    "& .MuiTableCell-body": {
      cursor: "pointer",
    },
  },
  tablePaper: {
    flexGrow: 1,
    overflow: "scroll",
  },
  label: {
    cursor: "pointer",
    marginLeft: 15,
    minHeight: 32,
    borderRadius: 16,
    display: "inline-flex",
    padding: "7px 12px",
    fontSize: 14,
    fontWeight: 500,
    alignItems: "center",
  },
  labelDisabled: { backgroundColor: "#ccc" },
  labelCreated: { backgroundColor: "#90caf9" },
  labelConfirmed: { backgroundColor: "#a5d6a7" },
  labelProcess: { backgroundColor: "#ffab91" },
  web: {
    color: "#07B4D5",
  },
  android: {
    color: "#96C03B",
  },
  ios: {
    color: "#FC7A3E",
  },
  phone: {
    color: '#ffe000'
  }
});

const OrdersArchivePage: FC = () => {
  const [rows, setRows] = useState<OrderRow[]>([]);
  const [progress, setProgress] = useState(false);
  const [orderId, setOrderId] = useState<number | undefined>(undefined);
  const [clientSearch, setClientSearch] = useState("");
  const [ordersTypeSearch, setOrdersTypeSearch] = useState([0, 0]);
  const [orderSearch, setOrderSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalOrders, setTotalOrders] = useState(0);

  const classes = useStyles();
  const alertContext = useContext<AlertContextType>(AlertContext);

  const updateList = () =>
    API.get(
      getOrdersQuery(
        clientSearch,
        ordersTypeSearch,
        page + 1,
        rowsPerPage,
        "archived",
        orderSearch
      )
    )
      .then(({ data }) => {
        setRows(data.data.items);
        setTotalOrders(data.data.totalItems);
        setProgress(false);
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка получения архива заказов");
        setProgress(false);
      });

  useEffect(() => {
    setProgress(true);
    updateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, clientSearch, ordersTypeSearch, orderSearch]);

  useEffect(() => {
    setPage(0);
  }, [clientSearch, ordersTypeSearch, orderSearch]);

  const getOrderStatusLabel = (row: OrderRow) => (
    <span
      className={[
        classes.label,
        row.status.toLowerCase() === "создан"
          ? classes.labelCreated
          : row.status.toLowerCase() === "подтвержден" ||
            row.status.toLowerCase() === "выполнен"
          ? classes.labelConfirmed
          : classes.labelProcess,
      ].join(" ")}
    >
      {row.status}
    </span>
  );

  const changeSearchType = (index: number) => {
    let newOrdersTypeSearch = [0, 0, 0];
    newOrdersTypeSearch[index] = ordersTypeSearch[index] === 0 ? 1 : 0;
    setOrdersTypeSearch(newOrdersTypeSearch);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.page}>
      <OrderForm
        updateOrdersList={updateList}
        orderId={orderId}
        onClose={() => setOrderId(undefined)}
      />
      <div className={classes.content}>
        <h2>Архив заказов</h2>
        <Paper elevation={2} className={classes.header}>
          <div>
            <TextField
              className={classes.phoneNumber}
              id={"servicePhone"}
              label={"Клиент"}
              name={"servicePhone"}
              inputProps={{ maxLength: 11 }}
              margin="normal"
              type="text"
              value={clientSearch}
              onChange={(e) => {
                if (e.target.value === " ") return null;
                const v = String(getIntFromString(e.target.value));
                setClientSearch(v === "0" ? "" : v);
              }}
            />
            <TextField
              label="№ заказа"
              inputProps={{ maxLength: 8 }}
              value={orderSearch}
              onChange={(e) => {
                if (e.target.value === " ") return null;
                const v = String(getIntFromString(e.target.value));
                setOrderSearch(v === "0" ? "" : v);
              }}
            />
          </div>
          <span>
            <span
              className={[
                classes.label,
                ordersTypeSearch[0] === 1
                  ? classes.labelProcess
                  : classes.labelDisabled,
              ].join(" ")}
              onClick={() => changeSearchType(0)}
            >
              Отмененные
            </span>
            <span
              className={[
                classes.label,
                ordersTypeSearch[1] === 1
                  ? classes.labelConfirmed
                  : classes.labelDisabled,
              ].join(" ")}
              onClick={() => changeSearchType(1)}
            >
              Выполненные
            </span>
          </span>
        </Paper>
        {progress ? (
          <div className={classes.content}>
            <ProgressBar />
          </div>
        ) : (
          <Paper className={classes.tablePaper}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        key={column.id}
                        className={classes.cell}
                        align={index === columns.length - 1 ? "right" : "left"}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, key) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={key}
                        onClick={() => setOrderId(row.id)}
                      >
                        <TableCell>{row.orderNumber}</TableCell>
                        <TableCell>
                          {getFormattedPhoneNumber(row.customerPhoneNumber)}
                        </TableCell>
                        <TableCell>
                          {renderDate(row.createdAt, false, true)}
                        </TableCell>
                        <TableCell>
                          {row.sourceId === 0 ? (
                            <DesktopWindowsIcon className={classes.web} />
                          ) : row.sourceId === 1 ? (
                            <AndroidIcon className={classes.android} />
                          ) : row.sourceId === 2 ? (
                            <AppleIcon className={classes.ios} />
                          ) : <PhoneIcon className={classes.phone} />}
                        </TableCell>
                        <TableCell>{row.totalCost} &#8381;</TableCell>
                        <TableCell>
                          {row.waitingTime ? row.waitingTime : "-"}
                        </TableCell>
                        <TableCell align={"right"}>
                          {getOrderStatusLabel(row)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
        <Paper className={classes.paginationPaper}>
          <TablePagination
            labelRowsPerPage={"Заказов на странице:"}
            labelDisplayedRows={({ from, to }) =>
              `${from}-${to} из ${totalOrders}`
            }
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={totalOrders}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
};

export default OrdersArchivePage;
