import React, { FC, useMemo } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute";
import { TTRoute } from "./index";

const Routes: FC<{ routes: TTRoute[] }> = ({ routes }) => {
  const match = useRouteMatch();

  const routesMemoed = useMemo(() => {
    return routes.map((rdata, key) => <ProtectedRoute {...rdata} key={key} />);
  }, [routes]);

  return (
    <Switch>
      {routesMemoed}
      <Route render={() => <Redirect to={routes[0].path} />} />
      {/*<Route render={() => <Redirect to={`${match.path}`} />} />*/}
    </Switch>
  );
};

export default React.memo(Routes);
