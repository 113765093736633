export const minutesAndIntervals = {
  delivery: {
    minutes: [
      "40 минут",
      "60 минут",
      "75 минут",
      "80 минут",
      "90 минут",
      "105 минут",
      "120 минут",
      "140 минут",
      "160 минут",
      "180 минут",
    ],
    intervals: [
      "40 - 60 минут",
      "60 - 75 минут",
      "60 - 80 минут",
      "60 - 90 минут",
      "75 - 90 минут",
      "90 - 105 минут",
      "90 - 120 минут",
      "120 - 150 минут",
      "150 - 180 минут",
    ],
  },
  pickup: {
    minutes: [
      "15 минут",
      "20 минут",
      "25 минут",
      "30 минут",
      "35 минут",
      "45 минут",
      "60 минут",
      "70 минут",
      "80 минут",
    ],
    intervals: [
      "20 - 25 минут",
      "25 - 30 минут",
      "30 - 40 минут",
      "45 - 50 минут",
      "60 - 75 минут",
      "80 - 90 минут",
    ],
  },
};
