import React, { FC, useEffect, useState, Fragment, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  getMonthNameByNumber,
  round15MinutesMoment,
} from "../../_shared/utils";
import { Divider } from "@material-ui/core";
import moment, { Moment } from "moment/moment";
import { getShopIntervalsForWeek } from "./shared";
import { Shop } from "../_shared/types";

const useStyles = makeStyles({
  list: {
    paddingTop: 0,
    maxHeight: 450,
  },
  listItem: {
    textAlign: "center",
  },
  divider: {
    margin: "auto",
    width: "80%",
  },
  lastDivider: {
    margin: "auto",
    width: "80%",
    marginBottom: 15,
  },
});

export type DateList = {
  value: "как можно быстрее" | "пиццерия закрыта" | Date;
  isToday: boolean;
};

const TimeSelect: FC<{
  shop: Shop | undefined;
  type: "delivery" | "pickup";
  isOpen: boolean;
  onClose: () => void;
  setTime: (time: Date | "как можно быстрее") => void;
  controlRoom?: boolean;
  curTime?: string;
  withASAP?: boolean;
}> = ({ shop, type, isOpen, onClose, setTime, withASAP }) => {
  const classes = useStyles();

  const [sevenDays, setSevenDays] = useState<{ [key: string]: Array<Date> }>(
    {}
  );
  const [dayOpen, setDayOpen] = useState<number>();

  useEffect(() => {
    if (shop) {
      if (type === "delivery" && shop.isAroundTheClockDelivery) {
        setSevenDays(aroundClock());
      } else if (type === "delivery") {
        setSevenDays(
          //@ts-ignore
          getShopIntervalsForWeek(shop.deliveryWorking.workingDays, type)
        );
      } else if (type === "pickup" && shop.isAroundTheClockPickup) {
        setSevenDays(aroundClock());
      } else if (type === "pickup") {
        setSevenDays(
          //@ts-ignore
          getShopIntervalsForWeek(shop.pickupWorking.workingDays, type)
        );
      }
    } else {
      setSevenDays(aroundClock());
    }
  }, [shop, type, isOpen]);

  const aroundClock = useCallback(() => {
    const step = moment().add(type === "delivery" ? 60 : 15, "minutes");
    round15MinutesMoment(step);
    const max = moment(step.toDate()).add(7, "days");

    const times: Array<Moment> = [];

    while (step.toDate() < max.toDate()) {
      times.push(moment(step.toDate()));
      step.add(15, "minutes");
    }

    const selectTimes: { [key: string]: Array<Date> } = {};

    times.map((t) => {
      const mDate = moment(t);
      const key = `${mDate.date()} ${getMonthNameByNumber(mDate.month())}`;
      if (selectTimes[key]) {
        selectTimes[key].push(mDate.toDate());
      } else {
        selectTimes[key] = [mDate.toDate()];
      }
    });
    return selectTimes;
  }, [type]);

  return (
    <div>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="simple-dialog-title">Время готовности</DialogTitle>
        <List className={classes.list}>
          <Divider className={classes.divider} />
          {withASAP && (
            <ListItem>
              <ListItemText
                style={{ cursor: "pointer" }}
                primary={"как можно быстрее"}
                onClick={() => setTime("как можно быстрее")}
              />
            </ListItem>
          )}
          <Divider className={classes.divider} />
          {Object.keys(sevenDays).map((date, index) => (
            <React.Fragment key={`dateK-${index}`}>
              <ListItem>
                <ListItemText
                  key={index}
                  style={{ cursor: "pointer" }}
                  primary={date}
                  onClick={() =>
                    dayOpen === index
                      ? setDayOpen(undefined)
                      : setDayOpen(index)
                  }
                />
              </ListItem>
              <Divider className={classes.divider} />
              {dayOpen === index &&
                sevenDays[date].map((time: Date, i: number) => (
                  <Fragment key={`${i + 999}/${date}`}>
                    <ListItem
                      className={classes.listItem}
                      button
                      onClick={() => {
                        setTime(time);
                      }}
                    >
                      <ListItemText primary={moment(time).format("HH:mm")} />
                    </ListItem>
                    <Divider className={classes.divider} />
                  </Fragment>
                ))}
            </React.Fragment>
          ))}
        </List>
      </Dialog>
    </div>
  );
};

export default TimeSelect;

// return mode === "oneDay" ? (
//   <div>
//     <Dialog
//       onClose={onClose}
//       aria-labelledby="simple-dialog-title"
//       open={isOpen}
//     >
//       <DialogTitle id="simple-dialog-title">Время готовности</DialogTitle>
//       <List className={classes.list}>
//         <Divider className={classes.divider} />
//         {listData.map((date, index) => (
//           <Fragment key={index}>
//             <ListItem
//               className={classes.listItem}
//               button
//               onClick={() => {
//                 if (date.value === "пиццерия закрыта") onClose();
//                 else setTime(date);
//               }}
//               key={index}
//             >
//               <ListItemText primary={moment(date.value).format("HH:mm")} />
//             </ListItem>
//             <Divider
//               className={
//                 index === listData.length - 1
//                   ? classes.lastDivider
//                   : classes.divider
//               }
//             />
//           </Fragment>
//         ))}
//       </List>
//     </Dialog>
//   </div>
// ) :
