import React, { FC, useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  IconButton,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { Alert, ToggleButton } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import EventIcon from "@material-ui/icons/Event";
import {
  alertError,
  getClearPhoneNumber,
  getErrorMsg,
  getIntFromString,
  isPhoneNotValid,
  renderDate,
} from "../../../_shared/utils";
import { ProgressBar } from "../../_shared/ProgressBar";
import InputMask from "react-input-mask";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/styles";
import API from "../../../_shared/axios";
import { ActivityStatus, PromoCodeValue } from "../../../_shared/types";
import moment from "moment";

const useStyles = makeStyles({
  normal: {
    "& input": {
      transition: "color 150ms ease-in-out",
    },
  },
  transparent: {
    "& input": {
      color: "transparent",
    },
  },
  showPromocodes: {
    marginTop: 15,
  },
  promoCodesTable: {
    marginTop: 15,
    "& .MuiTableCell-root": {
      paddingRight: 0,
      "&:last-child": {
        paddingRight: 16,
      },
    },
  },
  noPromoCodesMsg: {
    marginTop: 10,
  },
});

const baseDate = new Date();

const EditCustomerForm: FC<{
  onSend: (
    id: number,
    data: {
      phoneNumber: string;
      lastName: string;
      firstName: string;
      patronymic: string;
      email?: string;
      birthDay?: string;
      tickets: number;
    }
  ) => Promise<any>;
  customerId?: number;
  onClose: () => void;
}> = ({ onSend, customerId, onClose }) => {
  const [alerted, setAlerted] = useState(false);
  const [blur, setBlur] = useState(false);
  const [progress, setProgress] = useState(false);

  const classes = useStyles();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [patronymic, setPatronymic] = useState("");
  const [birthDay, setBirthDay] = useState<Date>(baseDate);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [tickets, setTickets] = useState(0);

  const [oldFirstName, setOldFirstName] = useState("");
  const [oldLastName, setOldLastName] = useState("");
  const [oldPatronymic, setOldPatronymic] = useState("");
  const [oldBirthDay, setOldBirthDay] = useState<Date>(baseDate);
  const [oldPhoneNumber, setOldPhoneNumber] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [oldTickets, setOldTickets] = useState(0);
  const [smsCode, setSmsCode] = useState<any>(null);
  const [promoCodes, setPromoCodes] = useState<PromoCodeValue[] | null>(null);

  useEffect(() => {
    setOldFirstName("");
    setOldLastName("");
    setOldPatronymic("");
    setOldBirthDay(baseDate);
    setOldPhoneNumber("");
    setOldEmail("");
    setOldTickets(0);
    setPromoCodes(null);
    ////////////////////////////////
    setSmsCode(null);
    if (!!customerId) {
      API.get(`customers/${customerId}`).then(({ data }) => {
        if (oldFirstName === "") {
          setOldFirstName(data.firstName ? data.firstName : "");
          setOldLastName(data.lastName ? data.lastName : "");
          setOldPatronymic(data.patronymic ? data.patronymic : "");
          if (data.birthDay) setOldBirthDay(new Date(data.birthDay));
          else setOldBirthDay(baseDate);
          setOldPhoneNumber(data.phoneNumber ? data.phoneNumber : "");
          setOldEmail(data.email ? data.email : "");
          setOldTickets(data.tickets ? data.tickets : 0);
        }
        setAlerted(false);
        setBlur(false);
        setFirstName(data.firstName ? data.firstName : "");
        setLastName(data.lastName ? data.lastName : "");
        setPatronymic(data.patronymic ? data.patronymic : "");
        if (data.birthDay) setBirthDay(new Date(data.birthDay));
        else setBirthDay(baseDate);
        setPhoneNumber(data.phoneNumber ? data.phoneNumber : "");
        setEmail(data.email ? data.email : "");
        setTickets(data.tickets ? data.tickets : 0);
        ////////////////
        setSmsCode(data.smsCode);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const onSubmit = () => {
    if (customerId) {
      setProgress(true);
      const BDDate = birthDay !== baseDate ? birthDay : undefined;
      let sendDate: undefined | string = undefined;

      if (BDDate) {
        BDDate.setHours(3);
        BDDate.setMinutes(1);
        sendDate = BDDate.toISOString().slice(0, -1);
      }

      onSend(customerId, {
        firstName,
        lastName,
        patronymic,
        birthDay: sendDate,
        phoneNumber: phoneNumber,
        email: email !== "" ? email : undefined,
        tickets,
      })
        .then((data) => onClose())
        .catch((error) => console.log(error))
        .finally(() => setProgress(false));
    }
  };

  function showPromoCodes() {
    if (promoCodes === null) {
      API.get(
        `/customers/${customerId}/promocodeValues`
      ).then(({ data }: { data: PromoCodeValue[] }) => setPromoCodes(data));
    } else {
      setPromoCodes(null);
    }
  }

  const safeToExit = !!customerId
    ? (oldFirstName === firstName || firstName === "") &&
      (oldLastName === lastName || lastName === "") &&
      (oldPatronymic === patronymic || patronymic === "") &&
      (birthDay.toISOString().slice(0, -1) ===
        oldBirthDay.toISOString().slice(0, -1) ||
        birthDay.toISOString().slice(0, -1) ===
          baseDate.toISOString().slice(0, -1)) &&
      oldPhoneNumber === phoneNumber &&
      (oldEmail === email || email === "") &&
      (oldTickets === tickets || tickets === 0)
    : true;

  const disableSubmit =
    !!customerId &&
    oldFirstName === firstName &&
    oldLastName === lastName &&
    oldPatronymic === patronymic &&
    new Date(birthDay).toISOString().slice(0, -1) ===
      oldBirthDay.toISOString().slice(0, -1) &&
    oldPhoneNumber === phoneNumber &&
    oldEmail === email &&
    oldTickets === tickets;

  return (
    <Dialog
      disableBackdropClick
      onBackdropClick={() =>
        progress ? null : safeToExit ? onClose() : setAlerted(true)
      }
      open={!!customerId}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Редактирование клиента</DialogTitle>
      <DialogContent>
        <DatePicker
          variant="inline"
          format="DD.MM.YYYY"
          label="Дата рождения"
          value={birthDay}
          onChange={(date: MaterialUiPickersDate) =>
            date ? setBirthDay(date.toDate()) : null
          }
          className={
            birthDay !== baseDate ? classes.normal : classes.transparent
          }
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setBirthDay(baseDate);
                  }}
                >
                  <ClearIcon />
                </IconButton>
                <IconButton>
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="dense"
          id="lastName"
          label="Фамилия"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          type="text"
          fullWidth
        />
        <TextField
          margin="dense"
          id="firstName"
          label="Имя"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          type="text"
          fullWidth
        />
        <TextField
          margin="dense"
          id="patronymic"
          label="Отчество"
          value={patronymic}
          onChange={(e) => setPatronymic(e.target.value)}
          type="text"
          fullWidth
        />
        {smsCode && (
          <TextField
            margin="dense"
            id="smsCode"
            label="СМС код"
            value={smsCode}
            type="text"
            onChange={() => setSmsCode(smsCode)}
            fullWidth
          />
        )}
        <InputMask
          mask="+7(999)999-99-99"
          value={phoneNumber}
          onBlur={() => setBlur(true)}
          onChange={(e) => {
            setPhoneNumber(getClearPhoneNumber(e.target.value));
          }}
        >
          {() => (
            <TextField
              margin={"dense"}
              id={"servicePhone"}
              label={"Контактный телефон"}
              name={"servicePhone"}
              required
              error={blur && (!phoneNumber || isPhoneNotValid(phoneNumber))}
              helperText={
                !phoneNumber && blur
                  ? "Поле обязательно для заполнения"
                  : isPhoneNotValid(phoneNumber)
                  ? "Номер невалидный"
                  : ""
              }
              type="text"
              fullWidth
            />
          )}
        </InputMask>
        <TextField
          margin="dense"
          id="email"
          label="Электронная почта"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          fullWidth
        />
        <TextField
          margin="dense"
          id="tickets"
          label="Доступно тикетов"
          value={tickets}
          onChange={(e) =>
            e.target.value === " "
              ? null
              : setTickets(getIntFromString(e.target.value))
          }
          type="text"
          fullWidth
        />
        <Button
          onClick={showPromoCodes}
          className={classes.showPromocodes}
          variant="outlined"
          color="primary"
        >
          {promoCodes === null ? "Показать промокоды" : "Скрыть промокоды"}
        </Button>
        {promoCodes?.length ? (
          <TableContainer className={classes.promoCodesTable} component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Промокод</TableCell>
                  <TableCell align="left">Значение</TableCell>
                  <TableCell align="left">Использован</TableCell>
                  <TableCell align="left">Срок окончания</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {promoCodes.map((row: PromoCodeValue) => (
                  <TableRow key={row.value} hover>
                    <TableCell component="th" scope="row">
                      {row.promoCodeName}
                    </TableCell>
                    <TableCell align="center">{row.value}</TableCell>
                    <TableCell align="center">
                      {row.isUsed ? "Да" : "Нет"}
                    </TableCell>
                    <TableCell align="center">
                      {row.expirationDate
                        ? moment(row.expirationDate).format("DD.MM.YY HH:mm")
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : promoCodes ? (
          <div className={classes.noPromoCodesMsg}>
            нет доступных промокодов
          </div>
        ) : (
          ""
        )}
      </DialogContent>
      <DialogActions>
        {alerted ? (
          <Alert
            severity="warning"
            action={
              <>
                <Button color="inherit" size="small" onClick={onClose}>
                  Да
                </Button>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => setAlerted(false)}
                >
                  Нет
                </Button>
              </>
            }
          >
            Вы действительно хотите отменить все изменения и выйти? Введенные
            данные будут утеряны
          </Alert>
        ) : (
          <>
            <Button onClick={onClose} color="primary" disabled={progress}>
              Отмена
            </Button>
            <Button
              onClick={onSubmit}
              color="primary"
              type="submit"
              disabled={
                disableSubmit ||
                phoneNumber.length === 0 ||
                isPhoneNotValid(phoneNumber) ||
                progress
              }
            >
              Подтвердить
            </Button>
          </>
        )}
      </DialogActions>
      {progress && <ProgressBar bottom />}
    </Dialog>
  );
};

export default EditCustomerForm;
