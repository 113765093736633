import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles/makeStyles";
import {
  Customer,
  CodeProductSet,
  GiftsResponseEntry,
  ProductRow,
  ProductSet,
  Promocode,
  PromotionCategory,
  PromoCodeValue,
} from "../../../_shared/types";
import API from "../../../_shared/axios";
import { Autocomplete } from "@material-ui/lab";
import {
  alertError,
  alertSuccess,
  getClientName,
  getErrorMsg,
} from "../../../_shared/utils";
import { throttle } from "lodash";
import styles from "../../DispatcherPage/OrderForm/index.module.scss";
import GiftsInfo from "./GiftsInfo";
import Typography from "@material-ui/core/Typography";
import NewCustomerForm from "../../UsersPage/CustomersPage/NewCustomerForm";
import { AlertContext } from "../../_shared/ToastList";
import moment from "moment";

const useStyles = makeStyles({
  rootGrid: {
    height: "100%",
  },
  noShadow: {
    boxShadow: "none !important",
  },
  main: {
    marginTop: "50px",
    padding: "20px",
    width: "900px",
    backgroundColor: "#fdfdfd",
    boxShadow: "4px 4px 8px 0px rgba(34, 60, 80, 0.2)",
  },
  fieldsContainer: {
    height: "100px",
    padding: "10px",
    margin: "20px 0 20px 0",
  },
  inputCode: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "300px",
  },
  valueCode: { height: "60px", margin: "10px 0 10px 0" },
  checkButton: { height: "56px", margin: "10px 0 10px 0" },
  applyButton: { marginLeft: "15px" },
  showPromocodes: {
    margin: "0 34px 32px !important",
  },
  noPromocodes: {
    margin: "0 0 34px 34px",
  },
  promoCodesTable: {
    width: "unset !important",
    margin: "-10px 34px 34px",
    "& .MuiTableCell-root": {
      paddingRight: 0,
      "&:last-child": {
        paddingRight: 16,
      },
    },
  },
  gridWrapper: {
    paddingBottom: 1,
  },
  noPromoCodesMsg: {
    margin: "-20px 0 32px 34px",
  },
  promoName: {
    margin: "5px",
    padding: "10px 0 10px 0",
  },
});

export type PromocodeExtra = Omit<Promocode, "presents" & "productSets"> & {
  presents: Omit<Promocode["presents"], "productId"> & { productId: number[] };
  productSets: Omit<Promocode["productSets"], "productId"> & {
    productId: number[];
    productSetVariantsList: ProductSet[];
  };
};

const CashierPromocode: FC = ({}) => {
  const classes = useStyles();
  const alertContext = useContext(AlertContext);

  const [customerString, setCustomerString] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [promocodeValue, setPromocodeValue] = useState("");
  const [chosenCustomer, setChosenCustomer] = useState<Customer | null>(null);
  const [newCachedCustomer, setNewCachedCustomer] = useState<Customer>();
  const [customersPage, setCustomersPage] = useState(1);
  const [customers, setCustomers] = useState<Array<Customer>>([]);
  const [promocodeId, setPromocodeId] = useState<number>(0);
  const [promocodeData, setPromocodeData] = useState<PromocodeExtra>();
  const [promocodeValueId, setPromocodeValueId] = useState();
  const [products, setProducts] = useState<{ [key: string]: ProductRow }>({});
  const [error, setError] = useState<string>();
  const [completed, setCompleted] = useState(false);
  const [showNewCustomerForm, setShowNewCustomerForm] = useState(false);
  const [promoCodes, setPromoCodes] = useState<PromoCodeValue[] | null>(null);
  const [displayPromoCodes, setDisplayPromoCodes] = useState(false);

  const fetchCustomers = useCallback(() => {
    const paramString = customerString
      ? `pageNumber=${customersPage}&FilterParams[0].ColumnName=phoneNumber&FilterParams[0].FilterOption=3&FilterParams[0].filterValue=${customerString}`
      : `pageNumber=${customersPage}`;
    API.get(`/customers?${paramString}`)
      .then((resp) => {
        let customersArr: Customer[] = [];
        if (customersPage !== 1) {
          customersArr = [...customers, ...resp.data.items];
        } else {
          customersArr = [...customers, ...resp.data.items];
        }
        if (newCachedCustomer) {
          customersArr = [newCachedCustomer, ...customersArr];
        }
        setCustomers(() =>
          [...customersArr].filter(
            (el, index) =>
              customersArr.findIndex(
                ({ phoneNumber }) => phoneNumber === el.phoneNumber
              ) === index
          )
        );
      })
      .catch((e) => console.error(e));
  }, [customerString, customersPage, customers]);
  // 7xr4ld9

  useEffect(() => {
    setCustomersPage(1);
  }, [customerString]);

  const onCreateNewUser = (user: {
    phoneNumber: string;
    lastName: string;
    firstName: string;
    patronymic: string;
    email?: string;
    birthDay?: string;
  }) =>
    new Promise((resolve, reject) =>
      API.post("/customers", user)
        .then(({ data }) => {
          if (data) {
            API.get(`/customers/${data}`)
              .then((resp) => {
                alertSuccess(alertContext, "Клиент добавлен");
                setChosenCustomer(resp.data);
                setNewCachedCustomer(resp.data);
              })
              .finally(() => {
                fetchCustomers();
                setShowNewCustomerForm(false);
              });
          } else {
            fetchCustomers();
            setShowNewCustomerForm(false);
          }
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка добавления клиента");
          reject(error);
        })
        .finally(() => {
          setCustomerString("");
          setInputValue("");
          setChosenCustomer(null);
        })
    );

  const applyCode = () => {
    if (promocodeId && chosenCustomer && promocodeValueId) {
      API.patch(
        `promoCodes/${promocodeValueId}/offline?customerId=${chosenCustomer.id}`
      )
        .then((resp) => {
          setPromocodeValue("");
          setCompleted(true);
          setCustomerString("");
        })
        .catch((e) => {
          if (e.response) {
            setError("Ошибка применения промокода");
          }
        });
    }
  };

  function showPromoCodes() {
    API.get(`/customers/${chosenCustomer?.id}/promocodeValues`).then(
      ({ data }: { data: PromoCodeValue[] }) => {
        setPromoCodes(data);
        setDisplayPromoCodes(true);
      }
    );
  }

  const fetchPromocode = useCallback(async () => {
    if (chosenCustomer && promocodeValue !== "") {
      try {
        setDisplayPromoCodes(false);
        const terms = await API.get(
          `promocodes/${promocodeValue}/terms?customerId=${chosenCustomer.id}`
        );

        const promocodeReq = await API.get(
          `promoCodes/${terms.data.promoCodeId}`
        );
        const prodIds: Array<number> = [];
        const reqProds = [...promocodeReq.data.productSets];

        const promoCatsFetch1: Array<PromotionCategory> = await Promise.all(
          promocodeReq.data.productSets.map(
            async (i: CodeProductSet, index: number) => {
              if (!i.productId) {
                const promoReq = await API.get(
                  `promotionCategories/${i.promotionCategoryId}`
                );
                reqProds[index].productId = promoReq.data.products.map(
                  (p: ProductRow) => p.id
                );
                prodIds.push(...reqProds[index].productId);
                return promoReq.data;
              } else {
                prodIds.push(i.productId);
                reqProds[index].productId = [i.productId];
              }
            }
          )
        );

        promoCatsFetch1
          .filter((p) => p)
          .forEach((pr: PromotionCategory) => {
            pr.products.forEach((p: ProductRow) => prodIds.push(p.id));
          });
        // TODO optimize fetching
        const giftProds = [...promocodeReq.data.presents];

        const promoCatsFetch2: Array<PromotionCategory> = await Promise.all(
          promocodeReq.data.presents.map(
            async (i: CodeProductSet, index: number) => {
              if (!i.productId) {
                const promoReq = await API.get(
                  `promotionCategories/${i.promotionCategoryId}`
                );
                giftProds[index].productId = promoReq.data.products.map(
                  (p: ProductRow) => p.id
                );
                prodIds.push(...giftProds[index].productId);
                return promoReq.data;
              } else {
                prodIds.push(i.productId);
                giftProds[index].productId = [i.productId];
              }
            }
          )
        );
        promoCatsFetch2
          .filter((p) => p)
          .map((pr: PromotionCategory) => {
            pr.products.map((p: ProductRow) => prodIds.push(p.id));
          });

        promocodeReq.data.productSets.map((ps: CodeProductSet) =>
          // @ts-ignore
          ps.productId
            ? Array.isArray(ps.productId)
              ? prodIds.push(...ps.productId)
              : prodIds.push(ps.productId)
            : void 0
        );
        const prodsData: { [key: string]: ProductRow } = {};

        await Promise.all(
          prodIds.map(async (p) => {
            const resp = await API.get(`products/${p}`);
            if (resp.data) {
              prodsData[String(p)] = resp.data;
            }
          })
        );

        promocodeReq.data.productSets.map((ps: any) => {
          if (ps.productSetVariantsList.length === 0) {
            ps.productSetVariantsList = prodsData[ps.productId[0]];
          }
        });
        promocodeReq.data.presents.map((ps: any) => {
          if (ps.variantsList.length === 0) {
            ps.productSetVariantsList = prodsData[ps.productId[0]];
          }
        });
        setPromocodeId(terms.data.promoCodeId);
        setPromocodeValueId(terms.data.promoCodeValueId);
        setProducts(prodsData);
        setCompleted(false);
        setPromocodeData({
          ...promocodeReq.data,
          productSets: reqProds,
          presents: giftProds,
        });
        setError(undefined);
      } catch (e) {
        if (e.response) {
          if (e.response.status === 400) {
            // setError("Промокод уже был использован");
            setError(e.response.data.errors[0]);
          } else if (e.response.status === 404) {
            setError(getErrorMsg(e.response, "Промокод не найден"));
            // setError(e.response.data.errors[0]);
          }
        }
      }
    }
  }, [chosenCustomer, promocodeValue]);

  useEffect(() => {
    fetchCustomers();
  }, [customerString, customersPage]);

  useEffect(() => {
    fetchCustomers();
  }, []);

  // useEffect(() => {
  //   if (newCachedCustomer) {
  //     setChosenCustomer(newCachedCustomer);
  //     setCustomers([newCachedCustomer]);
  //     // setNewCachedCustomer(undefined);
  //   }
  // }, [newCachedCustomer]);

  return (
    <Box>
      {showNewCustomerForm && (
        <NewCustomerForm
          onlyModal
          onSend={onCreateNewUser}
          onClose={() => {
            setShowNewCustomerForm(false);
            setChosenCustomer(null);
            setInputValue("");
          }}
        />
      )}
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        justify={"center"}
        className={classes.rootGrid}
      >
        <Box className={classes.main}>
          <Typography variant={"h4"}>Проверка промокода</Typography>
          <Paper className={classes.gridWrapper}>
            <Grid
              container
              justify={"space-around"}
              alignItems={"center"}
              direction={"row"}
              className={classes.fieldsContainer}
            >
              <Autocomplete
                className={classes.inputCode}
                size="medium"
                options={[
                  {
                    id: -1,
                    phoneNumber: "0",
                    lastName: "Добавить нового клиента",
                  },
                  ...customers,
                ]}
                value={chosenCustomer}
                onChange={(e, newValue) => {
                  if (newValue) {
                    setDisplayPromoCodes(false);
                    if (newValue.id !== -1) {
                      setCustomerString(newValue.phoneNumber);
                      // @ts-ignore
                      setChosenCustomer(newValue);
                    } else {
                      setShowNewCustomerForm(true);
                      setCustomerString("");
                    }
                  }
                }}
                getOptionLabel={(option) =>
                  option.id === -1
                    ? option.lastName
                    : // @ts-ignore
                      getClientName(option) ?? ""
                }
                inputValue={inputValue}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={"Номер клиента"}
                  />
                )}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                  setCustomerString(
                    newInputValue.replace(/[^a-zA-Z0-9а-яА-Я]/g, "")
                  );
                }}
                getOptionSelected={(option) => {
                  if (option.id === chosenCustomer?.id) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                filterOptions={(options) => {
                  return options.filter((option) => {
                    if (option.id === -1) {
                      return true;
                    } else {
                      if (
                        `${option.lastName}${option.phoneNumber}`.includes(
                          inputValue
                        )
                      ) {
                        return true;
                      }
                    }
                    return false;
                  });
                }}
                ListboxProps={{
                  style: { scrollbarWidth: "none" },
                  onScroll: throttle((event: React.SyntheticEvent) => {
                    const listboxNode = event.currentTarget;
                    if (
                      listboxNode.scrollTop + listboxNode.clientHeight ===
                      listboxNode.scrollHeight
                    ) {
                      setCustomersPage((pages) =>
                        pages < 10 ? pages + 1 : pages
                      );
                    }
                  }),
                }}
              />
              <TextField
                size={"medium"}
                label={"Промокод"}
                variant={"outlined"}
                value={promocodeValue}
                className={[classes.inputCode, classes.valueCode].join(" ")}
                onChange={(e) => setPromocodeValue(e.target.value)}
              />
              <Button
                className={classes.checkButton}
                variant={"contained"}
                color={"primary"}
                disabled={!chosenCustomer || promocodeValue === ""}
                onClick={fetchPromocode}
              >
                Проверить
              </Button>
            </Grid>
            <Grid>
              <Paper className={classes.noShadow}>
                {!chosenCustomer ? null : displayPromoCodes ? (
                  <Button
                    onClick={() => setDisplayPromoCodes(false)}
                    className={classes.showPromocodes}
                    variant="outlined"
                    color="primary"
                  >
                    Скрыть промокоды
                  </Button>
                ) : (
                  <Button
                    onClick={showPromoCodes}
                    className={classes.showPromocodes}
                    variant="outlined"
                    color="primary"
                  >
                    Показать промокоды
                  </Button>
                )}
                {!displayPromoCodes ? null : promoCodes?.length ? (
                  <TableContainer
                    className={classes.promoCodesTable}
                    component={Paper}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Промокод</TableCell>
                          <TableCell align="left">Значение</TableCell>
                          <TableCell align="left">Использован</TableCell>
                          <TableCell align="left">Срок окончания</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {promoCodes.map((row: PromoCodeValue) => (
                          <TableRow key={row.value} hover>
                            <TableCell component="th" scope="row">
                              {row.promoCodeName}
                            </TableCell>
                            <TableCell align="center">{row.value}</TableCell>
                            <TableCell align="center">
                              {row.isUsed ? "Да" : "Нет"}
                            </TableCell>
                            <TableCell align="center">
                              {row.expirationDate
                                ? moment(row.expirationDate).format(
                                    "DD.MM.YY HH:mm"
                                  )
                                : "-"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <div className={classes.noPromocodes}>
                    нет доступных промокодов
                  </div>
                )}
              </Paper>
            </Grid>
          </Paper>
          {promocodeData && !completed && !error && (
            <>
              <Typography className={classes.promoName} variant={"h4"}>
                {promocodeData.name}
              </Typography>
              <GiftsInfo prods={products} data={promocodeData} />
              <Button
                color={"primary"}
                variant={"contained"}
                onClick={applyCode}
                className={classes.applyButton}
              >
                Применить
              </Button>
            </>
          )}
          {completed ? (
            <Typography variant={"h4"}>
              {"Промокод был отмечен использованым"}
            </Typography>
          ) : (
            error && <Typography variant={"h5"}>{error}</Typography>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default CashierPromocode;
