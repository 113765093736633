import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import styles from "../index.module.scss";
import InfoBlock from "../InfoBlock";
import FlexRow from "../FlexRow";
import InputMask from "react-input-mask";
import {
  alertError,
  alertSuccess,
  getClearPhoneNumber,
  getClientName,
} from "../../../../_shared/utils";
import { Paper, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import API from "../../../../_shared/axios";
import SaveIcon from "@material-ui/icons/Save";
import ReplyIcon from "@material-ui/icons/Reply";
import { Autocomplete } from "@material-ui/lab";
import { throttle } from "lodash";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { AlertContextType, Customer } from "../../../../_shared/types";
import { AlertContext } from "../../../_shared/ToastList";
import NewCustomerForm from "../../../UsersPage/CustomersPage/NewCustomerForm";

const CustomerForm: FC<{
  customer: Customer | null;
  customerId: number | null;
  setCustomer: (customer: Customer | null) => void;
  isOrderEdit: boolean;
}> = ({ customer, customerId, setCustomer, isOrderEdit }) => {
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [clientEditing, setClientEditing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [newCachedCustomer, setNewCachedCustomer] = useState<Customer>();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [clientsPage, setClientsPage] = useState<number>(1);
  const [clientSearchString, setClientSearchString] = useState<string>("");
  const [showNewCustomerForm, setShowNewCustomerForm] = useState(false);

  const fetchCustomers = ({
    id = undefined,
    idx = 0,
  }: {
    id?: number;
    idx?: number;
  }) =>
    // FilterParams[0].ColumnName: CustomerPhoneNumber
    // FilterParams[0].FilterOption: 3
    // FilterParams[0].filterValue: 2334
    {
      const paramString = clientSearchString
        ? `pageNumber=${clientsPage}&FilterParams[0].ColumnName=phoneNumber&FilterParams[0].FilterOption=3&FilterParams[0].filterValue=${clientSearchString}`
        : `pageNumber=${clientsPage}`;
      API.get(`/customers?${paramString}`)
        .then(({ data }: { data: { items: Customer[] } }) => {
          if (clientsPage === 1) {
            setCustomers(
              newCachedCustomer
                ? [newCachedCustomer, ...data.items]
                : [...data.items]
            );
          } else {
            setCustomers((state) =>
              newCachedCustomer
                ? [newCachedCustomer, ...data.items, ...state]
                : [...data.items, ...state]
            );
          }

          if (id) {
            API.get(`/customers/${id}`).then((d) => {
              if (isOrderEdit && id) {
                setCustomer(d.data);
                setPhoneNumber(d.data.phoneNumber);
              } else {
                setCustomer(d.data);
                setPhoneNumber(d.data.phoneNumber);
              }
            });
          } else if (idx) {
            setCustomer(data.items[idx]);
            setPhoneNumber(data.items[idx].phoneNumber);
          }
        })
        .catch((error) => {
          console.log(error);
          alertError(alertContext, "Ошибка получения списка клиентов");
        });
    };

  const onCreateNewUser = (data: {
    phoneNumber: string;
    lastName: string;
    firstName: string;
    patronymic: string;
    email?: string;
    birthDay?: string;
  }) =>
    new Promise((resolve, reject) =>
      API.post("/customers", data)
        .then((response) => {
          alertSuccess(alertContext, "Клиент добавлен");

          if (response.data) {
            API.get(`/customers/${response.data}`)
              .then((resp) => {
                setCustomer(resp.data);
                setNewCachedCustomer(resp.data);
              })
              .finally(() => {
                fetchCustomers({});
                setShowNewCustomerForm(false);
              });
          } else {
            fetchCustomers({});
            setShowNewCustomerForm(false);
          }
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка добавления клиента");
          reject(error);
        })
        .finally(() => {
          setClientSearchString("");
          setCustomer(null);
        })
    );

  const onCustomerSelect = (
    e: any,
    customer:
      | Customer
      | { id: number; phoneNumber: string; lastName: string }
      | null
  ) => {
    if (customer?.id === -1) {
      setShowNewCustomerForm(true);
    } else {
      setCustomer(customer as Customer);
    }
  };

  useEffect(() => {
    fetchCustomers({});
  }, [clientSearchString, clientsPage]);

  useEffect(() => {
    if (newCachedCustomer) {
      setCustomer(newCachedCustomer);
      setNewCachedCustomer(undefined);
    }
  }, [customers]);

  return (
    <Paper className={styles.paperElem} elevation={2}>
      {showNewCustomerForm && (
        <NewCustomerForm
          onlyModal
          onSend={onCreateNewUser}
          onClose={() => {
            setShowNewCustomerForm(false);
            setCustomer(null);
            setClientSearchString("");
          }}
        />
      )}
      <InfoBlock title={"Клиент"}>
        {clientEditing && customer ? (
          <FlexRow>
            <InputMask
              mask="+7(999)999-99-99"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(getClearPhoneNumber(e.target.value));
              }}
            >
              {() => (
                <TextField
                  margin={"dense"}
                  id={"servicePhone"}
                  placeholder={"Контактный телефон"}
                  name={"servicePhone"}
                  type="text"
                  variant={"outlined"}
                  size={"small"}
                  fullWidth
                  className={styles.clientEdit}
                />
              )}
            </InputMask>
            <IconButton
              edge="start"
              color="primary"
              size="small"
              aria-label="delivery"
              disabled={
                phoneNumber.replace(/[^0-9]/g, "").length !== 11 ||
                phoneNumber.replace(/[^0-9]/g, "") ===
                  customer.phoneNumber.slice(1)
              }
              className={[styles.iconAfter, styles.saveBtn].join(" ")}
              onClick={() => {
                API.patch(`/customers/${customer?.id}`, {
                  ...customer,
                  phoneNumber,
                })
                  .then((data) => {
                    setClientEditing(false);
                    fetchCustomers({
                      idx: customers.findIndex((el) => el.id === customer.id),
                      id: customer.id,
                    });
                  })
                  .catch((error) =>
                    alertError(alertContext, "Ошибка сохранения клиента")
                  );
              }}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              edge="start"
              color="primary"
              size="small"
              aria-label="delivery"
              className={[styles.iconAfter, styles.saveBtn].join(" ")}
              onClick={() => {
                setPhoneNumber(customer?.phoneNumber);
                setClientEditing(false);
              }}
            >
              <ReplyIcon />
            </IconButton>
          </FlexRow>
        ) : (
          <FlexRow>
            <Autocomplete
              size="small"
              options={[
                {
                  id: -1,
                  phoneNumber: " ",
                  lastName: "Добавить нового клиента",
                },
                ...customers,
              ]}
              value={customer}
              onChange={(e, newValue) => {
                onCustomerSelect(e, newValue);
              }}
              getOptionLabel={(option) =>
                option.id === -1
                  ? option.lastName
                  : getClientName(option as Customer) ?? ""
              }
              renderInput={(params) => (
                <TextField {...params} variant={"outlined"} size={"small"} />
              )}
              onInputChange={(event, newInputValue) => {
                setClientSearchString(
                  newInputValue.replace(/[^a-zA-Z0-9а-яА-Я]/g, "")
                );
              }}
              ListboxProps={{
                style: { scrollbarWidth: "none" },
                onScroll: throttle((event: React.SyntheticEvent) => {
                  const listboxNode = event.currentTarget;
                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight ===
                    listboxNode.scrollHeight
                  ) {
                    setClientsPage((pages) => (pages < 10 ? pages + 1 : pages));
                  }
                }),
              }}
              className={styles.clientSearch}
            />
            <Button
              color="primary"
              aria-label="delivery"
              variant={"outlined"}
              className={[styles.iconAfter, styles.editBtn].join(" ")}
              onClick={() => setClientEditing(true)}
              startIcon={<EditIcon />}
            >
              Изменить
            </Button>
          </FlexRow>
        )}
      </InfoBlock>
    </Paper>
  );
};

export default React.memo(CustomerForm);
