import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  Address,
  AlertContextType,
  FullAddress,
  StreetSearchResponse,
} from "../../../../_shared/types";
import API from "../../../../_shared/axios";
import {
  alertError,
  getErrorMsg,
  getIntFromString,
} from "../../../../_shared/utils";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { KladrObjectType } from "../../../StreetsPage/_shared/types";
import { AlertContext } from "../../../_shared/ToastList";
import { Shop } from "../../../_shared/types";
import styles from "./index.module.scss";
import Dialog from "@material-ui/core/Dialog";

const CreateAddressForm = ({
  onClose,
  customerId,
  onAddressCreate,
  orderId,
  addressData,
  isAddressError,
}: {
  onClose: () => void;
  customerId: number;
  orderId?: number;
  onAddressCreate: (customerId: number, addressId: number) => void;
  addressData?: FullAddress;
  isAddressError?: boolean;
}) => {
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [kladrObjectTypes, setKladrObjectTypes] = useState<KladrObjectType[]>(
    []
  );

  const [edit, setEdit] = useState(false);

  const [searchList, setSearchList] = useState<StreetSearchResponse[]>([]);
  const [house, setHouse] = useState("");
  const [apartment, setApartment] = useState<number | null>(null);
  const [entrance, setEntrance] = useState<number | null>(null);
  const [code, setCode] = useState<number | null>(null);
  const [floor, setFloor] = useState<number | null>(null);
  const [commentary, setCommentary] = useState("");
  const [shopId, setShopId] = useState<number | null>(null);
  const [kladrId, setKladrId] = useState<number | null>(null);
  const [shops, setShops] = useState<Shop[]>([]);
  const [kladrObjectType, setKladrObjectType] = useState<number | null>(null);
  const [streetName, setStreetName] = useState("");
  const [checkSave, setCheckSave] = useState(false);

  useEffect(() => {
    API.get(`/kladrObjects/types`)
      .then(({ data }: { data: KladrObjectType[] }) => {
        setKladrObjectTypes(data.filter((el) => el.level === 10));
      })
      .catch((error) => {
        alertError(
          alertContext,
          "Ошибка получения списка типов кладр-объектов"
        );
      });
    API.get(`/shops`)
      .then(({ data }) => {
        setShops(data);
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка получения списка пиццерий")
        );
      });
  }, []);

  const handleSave = () => {
    if (edit && addressData) {
      const dataToSend = {
        kladrId: kladrId,
        kladrObjectCoordinateId: undefined,
        house: house,
        entrance: entrance,
        floor: floor,
        apartment: apartment,
        code: code,
        addressComment: commentary,
        isAddressError: false,
        shopId: shopId,
      };

      API.patch(`addresses/${addressData.id}`, dataToSend)
        .then((resp) => {
          onAddressCreate(customerId, addressData.id);
        })
        .catch((e) => {
          alertError(alertContext, "Ошибка сохранения адреса");
        });
    } else {
      const dataToSend = {
        orderId: orderId ? orderId : null,
        customerId: customerId,
        kladrId: kladrId ? kladrId : undefined,
        kladrName: kladrId ? undefined : streetName,
        house: house,
        entrance: entrance ? entrance : undefined,
        floor: floor ? floor : undefined,
        apartment,
        shopId: shopId !== 0 ? shopId : undefined,
        aroundTheClockShopId: shopId !== 0 ? shopId : undefined,
        code: code ? code : undefined,
        addressComment: commentary,
        isAddressError: false,
        kladrObjectCoordinateId: undefined,
        kladrObjectTypeId: kladrId ? undefined : kladrObjectType,
      };
      API.post(`/addresses`, dataToSend)
        .then((resp) => onAddressCreate(customerId, resp.data))
        .catch((error) => {
          alertError(alertContext, "Ошибка создания адреса");
        });
    }
  };
  useEffect(() => {
    if (addressData) {
      if (addressData.kladrId) {
        API.get(`kladrObjects/${addressData.kladrId}`)
          .then((resp) => {
            setStreetName(resp.data.name);

            setKladrObjectType(resp.data.kladrObjectTypeId);
            setKladrId(resp.data.id);
            setSearchList([resp.data]);
          })
          .catch((e) => {
            alertError(
              alertContext,
              "Ошибка получения данных об адресе, выберите улицу"
            );
          })
          .finally(() => {
            setEdit(true);
            setHouse(addressData.house);
            setApartment(addressData.apartment);
            setEntrance(addressData.entrance);
            setCode(addressData.code ? addressData.code : 0);
            setFloor(addressData.floor);
            setCommentary(addressData.commentary);
            setShopId(addressData.shopId);
          });
      } else {
        setEdit(true);
        setHouse(addressData.house);
        setApartment(addressData.apartment);
        setEntrance(addressData.entrance);
        setCode(addressData.code ? addressData.code : 0);
        setFloor(addressData.floor);
        setCommentary(addressData.commentary);
        setShopId(addressData.shopId);
      }
    }
  }, [kladrObjectTypes]);

  const requiredFieldsFilled = useMemo(
    () =>
      kladrId !== 0 &&
      kladrId != null &&
      streetName &&
      house !== "" &&
      apartment !== 0 &&
      apartment != null &&
      shopId !== 0 &&
      shopId != null,
    [kladrId, streetName, house, apartment, shopId]
  );

  const fetch = (event: any, newInputValue: string) => {
    setStreetName(newInputValue);
    if (newInputValue.length > 2) {
      // setSearchList([]);
      let streetsList: StreetSearchResponse[] = [];
      const url = encodeURI(
        `kladrObjectsByLevel?level=10&name=${newInputValue}`
      );
      API.get(url)
        .then(({ data }) => {
          setSearchList([...streetsList, ...data]);
        })
        .catch((error) => alertError(alertContext, "Ошибка поиска улицы"));
    } else {
      // setSearchList([]);
    }
  };

  useEffect(() => {
    setKladrObjectType(
      searchList.find((item) => item.id === kladrId)
        ? searchList.find((item) => item.id === kladrId)!.kladrObjectTypeId!
        : null
    );
  }, [kladrId]);

  return (
    <Dialog open={true} fullWidth maxWidth={"sm"} onBackdropClick={onClose}>
      <div className={styles.wrapper}>
        <div>
          <Autocomplete
            id="combo-box-demo"
            fullWidth
            onBlur={() =>
              setSearchList([searchList.find((item) => item.id === kladrId)!])
            }
            onInputChange={fetch}
            options={searchList}
            getOptionSelected={(option) => option.id === kladrId}
            value={
              searchList.find((item) => item.id === kladrId)!
                ? searchList.find((item) => item.id === kladrId)!
                : null
            }
            getOptionLabel={(option: StreetSearchResponse) =>
              option.fullName ? option.fullName : option.name!
            }
            renderInput={(params) => <TextField {...params} label="Улица *" />}
            onChange={(
              event,
              newValue: StreetSearchResponse | null | undefined
            ) => {
              if (newValue) {
                setKladrId(newValue.id!);
              } else {
                setKladrId(null);
              }
            }}
          />
        </div>
        <br />
        <FormControl fullWidth>
          {kladrObjectTypes.length ? (
            <Select value={kladrObjectType} disabled={true} label={"Тип улицы"}>
              {kladrObjectTypes.map((c, idx) => (
                <MenuItem
                  onClick={() => setKladrObjectType(c.id)}
                  key={idx}
                  value={c.id}
                >
                  {c.nameRu}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <></>
          )}
        </FormControl>
        <br />
        <div>
          <TextField
            label="Дом *"
            type="text"
            inputProps={{ maxLength: 10 }}
            value={house}
            onChange={(e) => {
              let newValue = e.target.value;
              if (newValue.slice(0, 1) === "0") newValue = newValue.slice(1);
              setHouse(newValue);
            }}
          />
          <TextField
            label="Квартира *"
            inputProps={{ maxLength: 10 }}
            type="text"
            value={apartment || ""}
            onChange={(e) =>
              e.target.value === " " || e.target.value === ""
                ? setApartment(null)
                : setApartment(getIntFromString(e.target.value))
            }
          />
          <TextField
            label="Подъезд"
            inputProps={{ maxLength: 10 }}
            type="text"
            value={entrance || ""}
            onChange={(e) =>
              e.target.value === " " || e.target.value === ""
                ? setEntrance(null)
                : setEntrance(getIntFromString(e.target.value))
            }
          />
        </div>
        <br />
        <div>
          <TextField
            fullWidth
            label="Код"
            inputProps={{ maxLength: 10 }}
            type="text"
            value={code || ""}
            onChange={(e) =>
              e.target.value === " " || e.target.value === ""
                ? setCode(null)
                : setCode(getIntFromString(e.target.value))
            }
          />
          <TextField
            fullWidth
            label="Этаж"
            inputProps={{ maxLength: 10 }}
            type="text"
            value={floor || ""}
            onChange={(e) =>
              e.target.value === " " || e.target.value === ""
                ? setFloor(null)
                : setFloor(getIntFromString(e.target.value))
            }
          />
        </div>
        <br />
        <TextField
          value={commentary || ""}
          onChange={(e) => {
            if (e.target.value.length < 256) setCommentary(e.target.value);
          }}
          label="Комментарий"
          multiline
        />
        <br />
        <br />
        <FormControl>
          <InputLabel required={true} id={"shop-select-id"}>
            Пиццерия
          </InputLabel>
          <Select
            value={shopId ? shopId : ""}
            onChange={(e: any) => setShopId(e.target.value)}
            labelId={"shop-select-id"}
          >
            <MenuItem value={0} disabled key={"99999"}>
              Пиццерия *
            </MenuItem>
            {shops.map((c, idx) => (
              <MenuItem key={idx} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <DialogActions className={styles.actions}>
          {!checkSave && (
            <Button
              type={"submit"}
              color={"secondary"}
              variant={"outlined"}
              onClick={onClose}
            >
              Отмена
            </Button>
          )}
          {!checkSave && (
            <Button
              disabled={!requiredFieldsFilled}
              onClick={handleSave}
              variant={"contained"}
              color={"primary"}
            >
              {isAddressError ? "Подтвердить" : edit ? "Сохранить" : "Создать"}
            </Button>
          )}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default CreateAddressForm;
