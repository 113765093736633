import React, { FC, useEffect, useState } from "react";
import {
  Box,
  createMuiTheme,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { PromocodeHistoryEntry } from "../../_shared/types";
import { Search } from "@material-ui/icons";
import API from "../../_shared/axios";
import { DateTimePicker } from "@material-ui/pickers";
import moment, { Moment } from "moment/moment";

const PromocodeHistory: FC<{ id: number }> = ({ id }) => {
  const [searchString, setSearchString] = useState("");
  const [entries, setEntries] = useState<Array<PromocodeHistoryEntry>>([]);
  const [filtered, setFiltered] = useState<Array<PromocodeHistoryEntry>>([]);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [dateFrom, setDateFrom] = useState<Moment | null>(null);
  const [dateTill, setDateTill] = useState<Moment | null>(null);
  let datePickerTheme = createMuiTheme({
    typography: {
      fontSize: 12,
    },
    shape: {
      borderRadius: 10,
    },
  });
  useEffect(() => {
    API.get(`promocodes/${id}/history`)
      .then((d) => setEntries(d.data))
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    setFiltered(
      entries
        .filter(
          (v) =>
            String(v.orderId)
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            String(v.customerPhoneNumber)
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            v.promoCodeValue.toLowerCase().includes(searchString.toLowerCase())
        )
        .filter((v) => {
          if (dateFrom && dateTill) {
            return moment(v.orderDate).isBetween(dateFrom, dateTill);
          } else if (dateFrom) {
            return new Date(v.orderDate) > dateFrom.toDate();
          } else if (dateTill) {
            return new Date(v.orderDate) < dateTill.toDate();
          } else {
            return true;
          }
        })
    );
  }, [entries, searchString, dateTill, dateFrom]);

  return (
    <Box>
      <Box>
        <Grid
          direction={"row"}
          justify={"space-between"}
          alignItems={"center"}
          container
        >
          <TextField
            style={{ width: "390px" }}
            margin={"dense"}
            type={"text"}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            placeholder={"Номер телефона, промокод, номер заказа"}
            variant={"outlined"}
            size={"small"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Paper>
            <Grid
              container
              direction={"row"}
              style={{ padding: "5px" }}
              alignItems={"center"}
            >
              <Typography
                component={"span"}
                style={{ height: "5px", marginRight: "5px", marginLeft: "5px" }}
              >
                От
              </Typography>
              <ThemeProvider theme={datePickerTheme}>
                <DateTimePicker
                  ampm={false}
                  style={{ width: "200px" }}
                  variant="inline"
                  format="DD.MM.YYYY HH:mm"
                  margin="dense"
                  id="date-picker-inline"
                  label="Дата"
                  value={dateFrom}
                  onChange={(d) => {
                    if (dateTill !== null) {
                      // @ts-ignore
                      if (d !== null && d.toDate() > dateTill.toDate()) {
                        setDateFrom(dateTill);
                      } else {
                        // @ts-ignore
                        setDateFrom(d);
                      }
                    } else {
                      // @ts-ignore
                      setDateFrom(d);
                    }
                  }}
                />
              </ThemeProvider>
            </Grid>
          </Paper>
          <Paper>
            <Grid
              container
              direction={"row"}
              style={{ padding: "5px" }}
              alignItems={"center"}
            >
              <Typography
                component={"span"}
                style={{ height: "5px", marginRight: "5px", marginLeft: "5px" }}
              >
                До
              </Typography>
              <ThemeProvider theme={datePickerTheme}>
                <DateTimePicker
                  ampm={false}
                  style={{ width: "200px" }}
                  variant="inline"
                  format="DD.MM.YYYY HH:mm"
                  margin="dense"
                  id="date-picker-inline"
                  label="Дата"
                  value={dateTill}
                  onChange={(d) => {
                    if (dateFrom !== null) {
                      // @ts-ignore
                      if (d !== null && d.toDate() < dateFrom.toDate()) {
                        setDateTill(dateFrom);
                      } else {
                        // @ts-ignore
                        setDateTill(d);
                      }
                    } else {
                      // @ts-ignore
                      setDateTill(d);
                    }
                  }}
                />
              </ThemeProvider>
            </Grid>
          </Paper>
        </Grid>
      </Box>

      {entries.length ? (
        <>
          <TableContainer>
            <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>Значение промокода</TableCell>
                  <TableCell>Номер клиента</TableCell>
                  <TableCell>Номер заказа</TableCell>
                  <TableCell>Дата заказа</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtered
                  .slice(page * pageSize, page * pageSize + pageSize)
                  .map((entry) => (
                    <TableRow>
                      <TableCell>{entry.promoCodeValue}</TableCell>
                      <TableCell>{entry.customerPhoneNumber}</TableCell>
                      <TableCell>
                        {entry.orderId
                          ? entry.orderId
                          : "Был использован на кассе"}
                      </TableCell>
                      <TableCell>
                        {moment(entry.orderDate).format("DD-MM-YYYY HH:mm")}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={"Записей на странице"}
            labelDisplayedRows={({ from, to }) =>
              `${from}-${to} из ${filtered.length}`
            }
            rowsPerPageOptions={[10, 15, 25, 50]}
            component="div"
            count={filtered.length}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={(e) => setPageSize(Number(e.target.value))}
            page={page}
            onChangePage={(e, newPage) => setPage(newPage ? newPage : 0)}
          />{" "}
        </>
      ) : (
        <Grid
          container
          alignItems={"center"}
          style={{ width: "100%", margin: "30px" }}
          justify={"center"}
        >
          <Typography variant={"h6"}>Нет данных</Typography>
        </Grid>
      )}
    </Box>
  );
};

export default PromocodeHistory;
