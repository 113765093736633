import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  Fragment,
  useMemo,
} from "react";
import OrderDialog from "./OrderDIalog";
import InfoBlock from "./InfoBlock";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import StoreIcon from "@material-ui/icons/Store";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import BackspaceIcon from "@material-ui/icons/Backspace";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import PhoneIcon from "@material-ui/icons/Phone";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import styles from "./index.module.scss";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import FlexRow from "./FlexRow";
import { useInput, useIntInput } from "../../../_shared/hooks";
import CustomSelect from "./CustomSelect";
import {
  FormControl,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  ButtonGroup,
  Tooltip,
  Divider,
  Box,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import WarningIcon from "@material-ui/icons/Warning";
import { Autocomplete } from "@material-ui/lab";
import ProductForm from "./ProductForm";
import PromotionForm from "./PromotionForm";
import API from "../../../_shared/axios";
import {
  alertError,
  alertSuccess,
  alertWarning,
  getErrorMsg,
  getIngredientsLabel,
  isObjectsEqual,
  renderReadyTime,
} from "../../../_shared/utils";
import {
  AlertContextType,
  Customer,
  Address,
  OrderAddress,
  ProductRow,
  Category,
  PromotionRow,
  DispatcherProduct,
  OrderView,
  OrderRow,
  OrderCommonPromotion,
  OrderCommonItem,
  OrderCommonPromoCode,
  FullAddress,
} from "../../../_shared/types";
import { AlertContext } from "../../_shared/ToastList";
import { Shop } from "../../_shared/types";
import TimeSelect from "../../OrdersPage/TimeSelect";
import { PaymentType } from "../../OrdersPage/types";
import CreateAddressForm from "./CreateAddressForm";
import { Redirect, useParams, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router";
import moment, { Moment } from "moment/moment";
import OrderHistory, { ArchivedOrder } from "./OrderHistory";
import lodash from "lodash";
import OrderInWorkDialog from "../../OrdersPage/OrderInWorkDialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import PromoCodeInfoTable from "../../OrdersPage/promocodeInfoTable";
import RefreshIcon from "@material-ui/icons/Refresh";
import DispatcherPromocodeForm from "./PromocodeDispatcherForm";
import CustomerForm from "./CustomerForm";
import TooltipWrapper from "./TooltipWrapper";
import { Edit, Warning } from "@material-ui/icons";
import { AxiosResponse } from "axios";

const statusList = [
  [
    {
      id: 1,
      description: "Подтвердить",
      color: "#a5d6a7",
    },
  ],
  [
    {
      id: 2,
      description: "В работе",
      color: "#ffab91",
    },
  ],
  [
    {
      id: 4,
      description: "Выполнен",
      color: "#a5d6a7",
    },
  ],
  [
    {
      id: -1,
      description: "",
      color: "",
    },
  ],
  [
    {
      id: 4,
      description: "Выполнен",
      color: "#a5d6a7",
    },
  ],
];

// <span className={styles.statusLabel}>создан</span>;
// {
//   id: 0,
//     name: "Создан",
// },
// {
//   id: 1,
//     name: "Подтвержден",
// },
// {
//   id: 2,
//     name: "Обрабатывается",
// },
// {
//   id: 3,
//     name: "Выполнен",
// },
// {
//   id: 4,
//     name: "Отменен",
// },

const typeIdMap = new Map([
  ["product", "productId"],
  ["commonProduct", "id"],
  ["promotion", "id"],
]);

const getTooltipLayout = (data: any, variantId: number) => {
  const weight = variantId
    ? data.variants.find((el: any) => el.variantId === variantId).weight
    : undefined;
  const { description, proteins, carbohydrates, fats, calories } = data;
  let nutritionStr = "";
  if (proteins) nutritionStr += `Белки - ${proteins} гр. `;
  if (fats) nutritionStr += `Жиры - ${fats} гр. `;
  if (carbohydrates) nutritionStr += `Углеводы - ${carbohydrates} гр. `;
  if (calories) nutritionStr += `Калории - ${calories} Ккал. `;
  return (
    <>
      {description || weight || nutritionStr ? (
        <div>
          {description && (
            <>
              {description} <br />
            </>
          )}
          {weight && (
            <>
              <strong>Вес:</strong> {weight} <br />
            </>
          )}
          {nutritionStr && (
            <>
              <strong>Пищевая ценность продукта на 100 гр:</strong> <br />
              {nutritionStr}
            </>
          )}
        </div>
      ) : (
        <div>данные не заполнены</div>
      )}
    </>
  );
};

export type PromotionState = {
  id: number;
  quantity: number;
  price: number;
  name: string;
  priceOfOne?: number;
  items: Array<{
    id: number;
    variantId: number | null;
    quantity: number;
    promotionCategoryId: number;
  }>;
};

export default function DispatcherOrderForm() {
  const history = useHistory();
  const match = useRouteMatch();

  const [customer, setCustomer] = useState<Customer | null>(null);
  const [orderHistory, setOrderHistory] = useState<any>([]);
  const [address, setAddress] = useState<number | null>(null);
  const [isAddressError, setIsAddressError] = useState(false);
  const [shop, setShop] = useState<Shop | null>(null);
  const orderTimeLabel = useInput("как можно быстрее");
  const orderTime = useInput("");
  const paymentMethod = useInput<number | null>(null);
  const [paymentStatus, setPaymentStatus] = useState<string>();
  const [externalPaymentId, setExternalPaymentId] = useState<string>();
  const oddMoney = useInput(1);
  const comment = useInput("");
  const tickets = useIntInput(0);
  const totalTickets = useInput(0);
  const [category, setCategory] = useState<number | null>(null);
  const [courier, setCourier] = useState<string | null>(null);
  const [source, setSource] = useState(3);
  const [createdDate, setCreatedAt] = useState("");
  const [isOnlinePayment, setOnlinePayment] = useState(false);
  const orderId = useInput("#781c5d17-50bb-7b97-a8ad-aa6e5e43239c");

  const params = useParams<{ id: string }>();
  const id = +params.id;

  const isEdit = !isNaN(id);

  const alertContext = useContext<AlertContextType>(AlertContext);

  const [orderType, setOrderType] = useState("delivery");
  const [total, setTotal] = useState(0);

  const [addresses, setAddressess] = useState<FullAddress[]>([]);
  const [addressEdit, setAddressEdit] = useState(false);
  const [shops, setShops] = useState<Shop[]>([]);
  const [timeSelectIsOpen, setTimeSelectIsOpen] = useState(false);
  const [addingAddress, setAddingAddress] = useState(false);
  const [paymentsTypes, setPaymentsTypes] = useState<
    { id: number; value: string }[]
  >([]);
  const [change] = useState([
    { id: 1, value: "Без сдачи" },
    { id: 2, value: "500" },
    { id: 3, value: "1000" },
    { id: 4, value: "2000" },
    { id: 5, value: "5000" },
  ]);

  const [
    currentProduct,
    setCurrentProduct,
  ] = useState<DispatcherProduct | null>(null);
  const [currentPromotion, setPromotion] = useState<PromotionState | null>();
  const [productId, setProductId] = useState(-1);
  const [promotionId, setPromotionId] = useState(-1);
  const [products, setProducts] = useState<DispatcherProduct[]>([]);
  const [promotions, setPromotions] = useState<Array<PromotionState>>([]);
  // {
  //   id: 1,
  //   name: 'Акция: 4 пиццы по цене 3',
  //   quantity: 1,
  //   price: 1500,
  //   total: 1250,
  //   products: [
  //     {
  //       id: 1,
  //       name: 'Пицца 4 сыра (традиционное 33 см)',
  //       quantity: 3,
  //       price: 375,
  //       total: 375,
  //     },
  //     {
  //       id: 2,
  //       name: 'Пицца с цыпленком (традиционное 33 см)',
  //       quantity: 1,
  //       price: 375,
  //       total: 375,
  //     },
  //   ],
  // },

  const [prevOrders, setPrevOrders] = useState([]);
  const [commonProducts, setCommonProducts] = useState<ProductRow[]>([]);
  const [commonPromotions, setCommonPromotions] = useState<PromotionRow[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [status, setStatus] = useState<{ id: number; description: string }>();
  const [touched, setTouched] = useState(false);
  const [orderInWorkModal, setOrderInWorkModal] = useState(false);
  const [isCancelConfirm, setIsCancelConfirm] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [promoCode, setPromocode] = useState<OrderCommonPromoCode>();
  const [waitingTime, setWaitingTime] = useState("");
  const [deletedAt, setDeletedAt] = useState<null | string>(null);

  const changeStatusHandler = useCallback(async (statusId: number) => {
    let uriString = "";
    switch (statusId) {
      case 1:
        uriString = `orders/${id}/confirm`;
        break;
      case 2:
        return setOrderInWorkModal(true);
      case 4:
        uriString = `orders/${id}/complete`;
        break;
      case 3:
        uriString = `orders/${id}/cancel`;
        break;
    }

    const req = await API.put(`${uriString}`);
    if (req.status === 204) {
      const stat = {
        id: statusId,
        description:
          statusId === 0
            ? "Подтвержден"
            : statusId === 1
            ? "Обрабатывается"
            : statusId === 2
            ? "Выполнен"
            : statusId === 4
            ? "Выполнен"
            : "",
      };
      setStatus({ ...stat });
    } else {
      alertError(alertContext, "Ошибка обновления заказа");
    }
  }, []);

  const refreshPaymentStatus = () => {
    API.get(`orders/${id}/paymentStatus`)
      .then((resp) => {
        if (resp.data === "success") {
          setPaymentStatus("success");
        }
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 404) {
            return alertError(alertContext, "Данные оплаты не обновлены");
          }
        }
        alertError(
          alertContext,
          getErrorMsg(e.response, "Ошибка обновления статуса оплаты")
        );
      });
  };

  const setOrderInWork = (data?: { text: string; waitingTime: string }) => {
    API.put(`orders/${id}/handle`, data)
      .then(() => {
        fetchOrderData(id);
      })
      .catch(() => {
        alertError(alertContext, "Ошибка обновления заказа");
      });
  };

  const bindShop = () => {
    if (orderTime.fields.value && orderType === "delivery") {
      const addressData = addresses.find((a) => {
        return a.id === address;
      });

      if (addressData) {
        const boundShop = shops.find((s) => {
          return s.id === addressData.shopId;
        });
        if (boundShop) {
          // если доставка круглосуточная то не думая ставим магазин
          if (boundShop.isAroundTheClockDelivery) {
            return setShop(boundShop);
          } else {
            // если нет, то проверяем совпадение выбранного времени и времени работы
            const chosenTime =
              orderTime.fields.value === "как можно быстрее"
                ? moment()
                : moment(orderTime.fields.value);
            const dates = {
              // @ts-ignore
              ...boundShop.deliveryWorking.workingDays[
                chosenTime.isoWeekday() - 1
              ].workingTime[0],
            };

            // Приводим к текущей дате, с сервера приходит дата заполнения магазина)))
            const from = moment(dates.timeFrom);
            from.add(
              Math.round(chosenTime.diff(moment(dates.timeFrom), "days", true)),
              "d"
            );
            const till = moment(dates.timeTill);
            till.add(
              Math.round(chosenTime.diff(moment(dates.timeFrom), "days", true)),
              "days"
            );

            if (
              from.toDate() < chosenTime.toDate() &&
              chosenTime.toDate() < till.toDate()
            ) {
              return setShop(boundShop);
            }
          }
        }
        // если ретерна еще не было, берем 24h
        const bound24hShop = shops.find((s) => {
          return s.id === addressData.aroundTheClockShopId;
        });
        if (bound24hShop) {
          return setShop(bound24hShop);
        } else {
          const toSet = shops.find((s) => s.isAroundTheClockDelivery);
          return setShop(toSet ? toSet : null);
        }
      }
    }
  };

  useEffect(() => {
    if (shop === null) bindShop();
  }, [orderTime, address]);

  const validateTime = () => {
    if (shop) {
      if (shop.isAroundTheClockDelivery) {
        return true;
      } else {
        // если нет, то проверяем совпадение выбранного времени и времени работы
        let chosenTime: Moment = moment();
        if (orderTime.fields.value === "как можно быстрее") {
          chosenTime = moment();
        } else if (orderTime.fields.value) {
          chosenTime = moment(orderTime.fields.value);
        }
        const dates = {
          // @ts-ignore
          ...shop.deliveryWorking.workingDays[chosenTime.isoWeekday() - 1]
            .workingTime[0],
        };

        // Приводим к текущей дате, с сервера приходит дата заполнения магазина)))
        const from = moment(dates.timeFrom);
        const endofDay = moment(chosenTime);
        endofDay.startOf("day");
        from.add(
          Math.round(
            endofDay.diff(moment(dates.timeFrom).startOf("day"), "days", true)
          ),
          "d"
        );
        const till = moment(dates.timeTill);
        till.add(
          Math.round(
            endofDay.diff(moment(dates.timeTill).startOf("day"), "days", true)
          ),
          "days"
        );

        if (till.toDate() < from.toDate()) {
          till.add(1, "day");
        }
        if (
          !(
            from.toDate() < chosenTime.toDate() &&
            chosenTime.toDate() < till.toDate()
          )
        ) {
          setShop(null);
          alertWarning(
            alertContext,
            "В выбранное время пиццерия не работает, выберите новое время или другую пиццерию"
          );
          return false;
        } else {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    validateTime();
  }, [shop]);

  useEffect(() => {
    if (currentProduct) setProductId(currentProduct.productId);
  }, [currentProduct]);

  useEffect(() => {
    orderTime.setValue("");
    orderTimeLabel.setValue("");
  }, [orderType]);

  useEffect(() => {
    if (currentPromotion) {
      setPromotionId(currentPromotion.id);
    } else {
      setPromotionId(-1);
    }
  }, [currentPromotion, setPromotionId]);

  const fetchFieldsLists = (orderData?: OrderView) => {
    if (orderData && !orderData?.deliveryAddress) {
      setOrderType("pickup");
    }
    if (orderData?.deliveryAddress?.id)
      setAddress(orderData?.deliveryAddress.id);
    if (orderData && orderData.deliveryAddress?.isAddressError)
      setIsAddressError(true);
    if (typeof orderData?.spentTickets === "number")
      tickets.setValue(orderData.spentTickets);
    if (orderData?.orderComment) comment.setValue(orderData.orderComment);
    if (orderData?.status) setStatus(orderData.status);
    if (orderData?.waitingTime) setWaitingTime(orderData.waitingTime);
    if (orderData?.deletedAt) {
      setDeletedAt(orderData.deletedAt);
    } else {
      setDeletedAt(null);
    }
    if (orderData?.readyTime) {
      const time = new Date(orderData?.readyTime);
      const date = moment(time).format("YYYY-MM-DDTHH:mm:ss.sss");
      orderTime.setValue(date);
      orderTimeLabel.setValue(renderReadyTime(new Date(time.getTime())));
    } else {
      orderTime.setValue("");
      orderTimeLabel.setValue("как можно быстрее");
    }
    if (orderData) {
      setCreatedAt(orderData.createdAt);
      setSource(orderData.source.sourceId);
    }

    if (orderData?.payment) {
      setPaymentStatus(orderData?.payment.state);
      setExternalPaymentId(orderData?.payment.externalPaymentId);
    }

    orderData?.customer &&
      API.get(`customers/${orderData.customer?.id}`)
        .then((response) => {
          if (response) {
            setCustomer(response.data);
          }
        })
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка получения данных о клиенте")
          );
        });

    if (orderData) {
      const proms: Array<PromotionState> = [];

      orderData.promotions.map((promotion) => {
        proms.push({
          id: promotion.promotionId,
          quantity: promotion.quantity,
          price: Number(promotion.totalCost),
          name: promotion.name,
          items: promotion.items.map((item) => {
            return {
              id: item.productId,
              variantId: item.options
                ? Number(Object.keys(item.options)[0])
                : null,
              quantity: item.quantity,
              promotionCategoryId: item.promotionCategoryId,
            };
          }),
        });
      });
      setPromotions(
        proms.map((prom) => ({
          ...prom,
          priceOfOne: prom.price / prom.quantity,
        }))
      );
    }

    API.get(`/shops`)
      .then(({ data }) => {
        const shopsIds = data.map((el: { id: number }) => el.id);
        let shopsCount = 0;
        Promise.all(
          shopsIds.map((id: number) => {
            return API.get(`/shops/${id}`);
          })
        ).then((shopsData: any) => {
          const fetchedShops = shopsData
            .filter((j: AxiosResponse) => j.data)
            .map((i: any) => i.data);
          setShops(fetchedShops);
          if (shopsData.length && orderData?.shop) {
            setShop(
              isEdit && orderData
                ? fetchedShops.find((el: any) => el.id === orderData.shop?.id)!
                : fetchedShops[0]
            );
          }
        });
      })
      .catch((error) =>
        alertError(alertContext, "Ошибка получения списка точек продаж")
      );
    API.get(`/payments/types?device=${source}`)
      .then(({ data }: { data: PaymentType[] }) => {
        let types = data.map((el) => {
          return { id: el.id, value: el.description };
        });
        setPaymentsTypes(types);
        const chosenPaymentMethod = types.find(
          (el) => el.id === orderData?.paymentType.id
        );
        if (chosenPaymentMethod) {
          paymentMethod.setValue(chosenPaymentMethod.id);
        } else {
          paymentMethod.setValue(types[0].id);
        }

        if (
          orderData?.paymentType.type === "Cash" &&
          orderData.oddMoney &&
          isEdit
        )
          oddMoney.setValue(
            change.find((el) => +el.value === orderData.oddMoney)!.id
          );
      })
      .catch((error) =>
        alertError(alertContext, "Ошибка получения способов оплаты")
      );
    API.get(`/products`)
      .then(({ data }) => {
        setCommonProducts(data);
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка получения списка товаров");
      });
    API.get(`/promotions`)
      .then(({ data }) => {
        setCommonPromotions(data);
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка получения списка акций");
      });
    API.get("/categories")
      .then(({ data }: { data: Category[] }) => {
        setCategories(
          data
            .filter((el) => el.showInMenu)
            .map((el) => {
              return { ...el, value: el.name };
            })
        );
        setCategory(data.filter((el) => el.showInMenu)[0].id);
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка получения списка категорий");
      });
  };

  useEffect(() => {
    API.get(`/payments/types?device=${source}`)
      .then(({ data }: { data: PaymentType[] }) => {
        let types = data.map((el) => {
          return { id: el.id, value: el.description };
        });
        setPaymentsTypes(types);
        // @ts-ignore
        if (!types.map((t) => t.id).includes(paymentMethod.fields.value)) {
          paymentMethod.setValue(types[0].id);
        }
      })
      .catch((error) =>
        alertError(alertContext, "Ошибка получения способов оплаты")
      );
  }, [source]);

  const fetchOrderData = useCallback((id?: number) => {
    if (id)
      API.get(`/orders/${id}`)
        .then(({ data: orderData }: { data: OrderView }) => {
          setPromocode(orderData.promoCode);
          setOnlinePayment(orderData.paymentType.isOnline);
          fetchFieldsLists(orderData);
          Promise.all(
            orderData.items
              .map((el) => el.productId)
              .map((id: number) => API.get(`/products/${id}`))
          ).then((data) => {
            setProducts(
              data.map((el: any, index) => {
                const product = el.data;
                const orderProduct = orderData.items[index];
                return {
                  productId: orderProduct.productId,
                  quantity: orderProduct.quantity,
                  price: +orderProduct.totalPrice / orderProduct.quantity,
                  options: orderProduct.options,
                  ingredientsToAdd: orderProduct.ingredientsToAdd
                    ? orderProduct.ingredientsToAdd.map((ing: any) => {
                        return {
                          ingredientId: ing.ingredientId,
                          quantity: ing.quantity,
                          name: ing.name,
                        };
                      })
                    : [],
                  ingredientsToRemove: orderProduct.ingredientsToRemove
                    ? orderProduct.ingredientsToRemove.map((ing: any) => {
                        return {
                          id: ing.id!,
                          name: ing.name,
                        };
                      })
                    : [],
                  variantId: product.variants.length
                    ? product.variants.find(
                        (variant: any) =>
                          Object.values(orderData.items[index].options)[0].join(
                            ", "
                          ) ===
                          variant.values
                            .reduce(
                              (sum: string[], acc: any) => [
                                ...sum,
                                acc.optionValueData,
                              ],
                              []
                            )
                            .join(", ")
                      )?.variantId
                    : null,
                };
              })
            );
          });
        })
        .catch((error) => {
          console.log(error);
          alertError(alertContext, "Ошибка получения заказа");
        });
    else fetchFieldsLists();
  }, []);

  const titleLabel = useCallback(
    (stat: { id: number; description: string }) => (
      <>
        <span
          className={[
            styles.statusLabel,
            deletedAt
              ? styles.labelDeleted
              : status?.id === 0
              ? styles.labelCreated
              : status?.id === 1
              ? styles.labelConfirmed
              : status?.id === 2
              ? styles.labelProcess
              : "",
          ].join(" ")}
        >
          {deletedAt ? "Удален" : status?.description}
        </span>
        {statusList[stat?.id].map((s) =>
          status?.id !== 4 && status?.id !== 3 ? (
            deletedAt ? (
              <Button
                variant={"outlined"}
                color={"primary"}
                size={"small"}
                style={{ float: "right" }}
                onClick={() => {
                  API.put(`orders/${id}/recover`)
                    .then(() => {
                      fetchOrderData(id);
                    })
                    .catch((e) => {
                      console.log(e.response);
                      if (e.response) {
                        alertError(alertContext, e.response.data.errors[0]);
                      }
                    });
                }}
              >
                {"Восстановить"}
              </Button>
            ) : (
              <Button
                variant={"outlined"}
                color={"primary"}
                size={"small"}
                style={{ float: "right" }}
                disabled={isAddressError && orderType === "delivery"}
                onClick={() => changeStatusHandler(s.id)}
              >
                {s.description}
              </Button>
            )
          ) : (
            <></>
          )
        )}
      </>
    ),
    [status, deletedAt, alertContext, fetchOrderData, isAddressError]
  );

  // get clients, shops
  useEffect(() => {
    fetchOrderData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAddressFullName = (address: FullAddress) => {
    let nameString = address.fullName;
    if (address.entrance) {
      nameString = nameString + `,  под. ${address.entrance}`;
    }
    if (address.floor) {
      nameString = nameString + `,  этаж ${address.floor}`;
    }

    return nameString;
  };

  const updateAddresses = ({
    clientId,
    addressId,
    tickets,
  }: {
    clientId: number;
    addressId?: number;
    tickets?: number;
  }) =>
    API.get(`/customers/${clientId}/addresses`)
      .then(({ data }) => {
        if (data.length) {
          setAddressess(
            data.map((el: FullAddress) => {
              return {
                ...el,
                id: el.id,
                fullName: getAddressFullName(el),
                shopId: el.shopId,
                aroundTheClockShopId: el.aroundTheClockShopId,
              };
            })
          );
          setAddress(
            addressId ? addressId : data[addingAddress ? data.length - 1 : 0].id
          );

          if (customer) {
            totalTickets.setValue(tickets ? tickets : customer.tickets);
          }
        } else {
          setAddressess([]);
        }
        setAddingAddress(false);
      })
      .catch((error) => {
        console.log(error);
        alertError(alertContext, "Ошибка получения списка адресов");
      });

  // get addresses by clientId
  useEffect(() => {
    if (customer)
      updateAddresses({
        clientId: customer.id,
        addressId: address ? address : undefined,
      });
    const asynced = async () => {
      if (customer) {
        try {
          const orders = await API.get(
            `/orders/archived?SortingParams[0].SortOrder=2&SortingParams[0].ColumnName=id&FilterParams[0].ColumnName=CustomerPhoneNumber&FilterParams[0].filterValue=${customer.phoneNumber.slice(
              1,
              -1
            )}&FilterParams[0].FilterOption=3`
          );
          if (orders.data.data.items.length) {
            const toIter = orders.data.data.items.filter(
              (i: any) => i.status !== "Отменен"
            );
            const toSet: any = [];
            await Promise.all(
              toIter.slice(0, 4).map(async (order: OrderRow) => {
                try {
                  const details = await API.get(`/orders/${order.id}`);
                  toSet.push({
                    ...order,
                    prods: details.data.items,
                    promos: details.data.promotions,
                  });
                } catch (e) {
                  console.error(e);
                  alertError(
                    alertContext,
                    `Ошибка получения прошлого заказа ${order.id}`
                  );
                }
              })
            );
            setPrevOrders(toSet);
          }
        } catch (e) {
          console.error(e);
          alertError(alertContext, "Ошибка получения прошлых заказов клиента");
          return;
        }
      }
    };
    if (customer) {
      asynced();
    } else {
      setPrevOrders([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  //
  // useEffect(() => {
  //   if (orderType === "pickup") {
  //     if (shop) {
  //       console.log(
  //         getShopIntervalsForWeek(orderType, shops, {
  //           name: shop.name,
  //           id: shop.id!,
  //         })
  //       );
  //       setMinMaxIntervals(
  //         getShopIntervalsForWeek(orderType, shops, {
  //           name: shop.name,
  //           id: shop.id!,
  //         })
  //       );
  //     }
  //   } else if (orderType === "delivery") {
  //     setMinMaxIntervals(getDeliveryIntervals());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [shop, orderType])

  const addProdFromHistory = (prod: OrderCommonItem) => {
    const check = commonProducts.find((p) => p.id === prod.productId);

    if (check) {
      if (check.isActive) {
        const newProd = {
          productId: prod.productId,
          quantity: prod.quantity,
          variantId: prod.options ? Number(Object.keys(prod.options)[0]) : 0,
          price: Number(prod.totalPrice) / prod.quantity,
          options: prod.options,
          ingredientsToRemove:
            prod.ingredientsToRemove != null
              ? prod.ingredientsToRemove.map((i) => {
                  return {
                    name: i.name,
                    id: i.ingredientId,
                  };
                })
              : [],
          ingredientsToAdd:
            prod.ingredientsToAdd != null
              ? prod.ingredientsToAdd.map((i) => {
                  return {
                    name: i.name,
                    quantity: i.quantity,
                    ingredientId: i.ingredientId,
                  };
                })
              : [],
        };
        const idx = products.findIndex((p) => {
          return lodash.isEqual(
            {
              productId: newProd.productId,
              variantId: newProd.variantId,
              price: newProd.price,
              ingredientsToAdd: newProd.ingredientsToAdd,
              ingredientsToRemove: newProd.ingredientsToRemove,
              quantity: 0,
            },
            {
              productId: p.productId,
              variantId: p.variantId,
              price: p.price,
              ingredientsToAdd: p.ingredientsToAdd,
              ingredientsToRemove: p.ingredientsToRemove,
              quantity: 0,
            }
          );
        });
        if (idx !== -1) {
          setProducts((state) =>
            state.map((el, index) =>
              index === idx
                ? { ...el, quantity: el.quantity + newProd.quantity }
                : { ...newProd }
            )
          );
        } else {
          setProducts((state) => [...state, newProd]);
        }
        setTouched(true);
      } else {
        alertError(alertContext, "Товар более не доступен");
      }
    } else {
      alertError(alertContext, "Товар более не доступен");
    }
  };

  const addPromoFromHistory = (promo: OrderCommonPromotion) => {
    const check = commonPromotions.find((p) => p.id === promo.promotionId);
    if (check) {
      if (check.isActive) {
        const newProm = {
          id: promo.promotionId,
          quantity: promo.quantity,
          price: Number(promo.totalCost),
          name: promo.name,
          items: promo.items.map((item) => {
            return {
              id: item.productId,
              variantId: item.options
                ? Number(Object.keys(item.options)[0])
                : null,
              quantity: item.quantity,
              promotionCategoryId: item.promotionCategoryId,
            };
          }),
        };

        const idx = promotions.findIndex((p) => {
          return lodash.isEqual(
            {
              id: newProm.id,
              quantity: 0,
              price: newProm.price,
              name: newProm.name,
              items: newProm.items,
            },
            {
              id: p.id,
              quantity: 0,
              price: p.price,
              name: p.name,
              items: p.items,
            }
          );
        });
        if (idx !== -1) {
          setPromotions((state) =>
            state.map((el, index) =>
              index === idx
                ? { ...el, quantity: el.quantity + newProm.quantity }
                : { ...newProm }
            )
          );
        } else {
          setPromotions((state) => [...state, newProm]);
        }
        setTouched(true);
      } else {
        alertError(alertContext, "Акция более не доступна");
      }
    } else {
      alertError(alertContext, "Акция более не доступна");
    }
  };

  const copyOrder = (order: ArchivedOrder) => {
    API.get(`/orders/${order.id}`)
      .then(({ data: orderData }: { data: OrderView }) => {
        const proms: Array<PromotionState> = [];

        orderData.promotions.map((promotion) => {
          proms.push({
            id: promotion.promotionId,
            quantity: promotion.quantity,
            price: Number(promotion.totalCost),
            name: promotion.name,
            items: promotion.items.map((item) => {
              return {
                id: item.productId,
                variantId: item.options
                  ? Number(Object.keys(item.options)[0])
                  : null,
                quantity: item.quantity,
                promotionCategoryId: item.promotionCategoryId,
              };
            }),
          });
        });
        setPromotions(proms);
        Promise.all(
          orderData.items
            .map((el) => el.productId)
            .map((id: number) => API.get(`/products/${id}`))
        ).then((data) => {
          setProducts(
            data.map((el: any, index) => {
              const product = el.data;
              const orderProduct = orderData.items[index];
              return {
                productId: orderProduct.productId,
                quantity: orderProduct.quantity,
                price: +orderProduct.totalPrice / orderProduct.quantity,
                options: orderProduct.options,
                ingredientsToAdd: orderProduct.ingredientsToAdd
                  ? orderProduct.ingredientsToAdd.map((ing: any) => {
                      return {
                        ingredientId: ing.ingredientId,
                        quantity: ing.quantity,
                        name: ing.name,
                      };
                    })
                  : [],
                ingredientsToRemove: orderProduct.ingredientsToRemove
                  ? orderProduct.ingredientsToRemove.map((ing: any) => {
                      return {
                        id: ing.id!,
                        name: ing.name,
                      };
                    })
                  : [],
                variantId: product.variants.find(
                  (variant: any) =>
                    Object.values(orderData.items[index].options)[0].join(
                      ", "
                    ) ===
                    variant.values
                      .reduce(
                        (sum: string[], acc: any) => [
                          ...sum,
                          acc.optionValueData,
                        ],
                        []
                      )
                      .join(", ")
                )!.variantId,
              };
            })
          );
        });
        setTouched(true);
      })
      .catch((e) => {
        alertError(alertContext, "Ошибка загрузки заказа");
        console.error(e);
      });
  };

  const getSourceIcon = () => {
    return source === 0 ? (
      <DesktopWindowsIcon />
    ) : source === 1 ? (
      <AndroidIcon />
    ) : source === 2 ? (
      <AppleIcon />
    ) : (
      <PhoneIcon />
    );
  };

  useEffect(() => {
    setTotal(
      products.reduce((sum, acc) => sum + acc.quantity * acc.price, 0) +
        promotions.reduce(
          (sum, prom) => sum + (prom.priceOfOne ?? prom.price) * prom.quantity,
          0
        ) -
        Number(tickets.fields.value) -
        (promoCode ? (promoCode.discount ? promoCode.discount : 0) : 0)
    );
  }, [products, promotions, tickets, promoCode]);

  const handleClearFields = () => {
    setCustomer(null);
    setOrderType("delivery");
    setAddress(null);
    setShop(null);
    orderTime.setValue("");
    orderTimeLabel.setValue("как можно быстрее");
    comment.setValue("");
    setSource(3);
    paymentMethod.setValue(paymentsTypes[0].id);
    oddMoney.setValue(change[0].id);
    tickets.setValue(0);
    setProducts([]);
    setPromotions([]);
    setTouched(false);
  };

  const isCreateEnabled = () => {
    const isDelivery = orderType === "delivery";
    return (
      customer &&
      (isDelivery ? address : shop?.id) &&
      (products.length || promotions.length || promoCode?.presents)
    );
  };

  const getPaymentsTypes = () => {
    return isOnlinePayment
      ? paymentsTypes
      : paymentsTypes.filter((el) => el.id === 3 || el.id === 1);
  };

  const handleCreateOrder = () => {
    if (!validateTime()) {
      return false;
    }

    const customerId = customer?.id;
    const isDelivery = orderType === "delivery";
    const items = products.map((el) => ({
      ...el,
      ingredientsToRemove: el.ingredientsToRemove.map((i) => i.id),
      ingredientsToAdd: el.ingredientsToAdd.map((i) => ({
        ingredientId: i.ingredientId,
        quantity: i.quantity,
      })),
    }));
    const promos = promotions.map((prom) => {
      return {
        promotionId: prom.id,
        quantity: prom.quantity,
        promotionItems: prom.items.map((item) => {
          return {
            productId: item.id,
            quantity: item.quantity,
            variantId: item.variantId,
            promotionCategoryId: item.promotionCategoryId,
          };
        }),
      };
    });

    if (!shop) {
      alertError(alertContext, "Выберите пиццерию");
      return;
    }

    const body = {
      customerId: customerId,
      addressId: orderType === "delivery" ? address : undefined,
      shopId: shop.id,
      receivingMethodId: orderType === "pickup" ? 0 : 1,
      oddMoney:
        paymentMethod.fields.value === 1
          ? oddMoney.fields.value === 1
            ? null
            : Number(change[oddMoney.fields.value - 1].value)
          : undefined,
      spentTickets: +tickets.fields.value,
      paymentTypeId: paymentMethod.fields.value,
      commentary: comment.fields.value,
      sourceId: source,
      readyTime: orderTime.fields.value === "" ? null : orderTime.fields.value,
      items,
      promotions: promos,
      promoCode: promoCode
        ? {
            promocodeValueId: promoCode.promocodeValueId,
            presents: promoCode.presents.map((pr) => ({
              productId: pr.productId,
              variantId: pr.variant ? pr.variant.variantId : null,
              quantity: pr.count,
              id: pr.presentId,
            })),
          }
        : undefined,
    };
    API.post(`/orders/${customerId}`, body)
      .then(({ data }) => {
        API.put(`orders/${data.orderId}/confirm`)
          .catch(() => alertError(alertContext, "Не удалось подтвердить заказ"))
          .finally(() => {
            history.push(`/main/dispatcher/order-form/${data.orderId}`);
            fetchOrderData(data.orderId);
          });
      })
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка создания заказа")
        );
      });
    // promotions: [
    //   {
    //     promotionId: 2,
    //     quantity: 1,
    //     promotionItems: [
    //       {
    //         productId: 1,
    //         variantId: 9,
    //         quantity: 1,
    //         promotionCategoryId: 3,
    //       },
    //     ],
    //   },
    // ],
  };

  const cancelOrder = () => {
    setIsCancelConfirm(true);
  };

  const updateOrder = () => {
    if (!shop) {
      alertWarning(alertContext, "Не выбрана пиццерия");
      return false;
    }
    if (!validateTime()) {
      alertWarning(alertContext, "Ошибка времени заказа");
      return false;
    }
    const customerId = customer?.id;
    const isDelivery = orderType === "delivery";

    if (totalTickets.fields.value < tickets.fields.value) {
      alertError(
        alertContext,
        "Количество тикетов превышает доступное значение"
      );
      return;
    }
    //
    // const itemsReq = () =>
    //   API.patch(`/orders/${id}/items`, {
    //     readyTime:
    //       orderTime.fields.value === "" ? null : orderTime.fields.value,
    //     items: products.map((el) => ({
    //       ...el,
    //       ingredientsToRemove: el.ingredientsToRemove.map((i) => i.id),
    //       ingredientsToAdd: el.ingredientsToAdd.map((i) => ({
    //         ingredientId: i.ingredientId,
    //         quantity: i.quantity,
    //       })),
    //     })),
    //     // {
    //     //   "promoCodeValueId":0,
    //     //   "presents":[{
    //     //   "productId":0,
    //     //   "variantId":0,
    //     //   "quantity":0
    //     // }]
    //     // },
    //     promoCode: promoCode
    //       ? {
    //           promocodeValueId: promoCode.promocodeValueId,
    //           presents: promoCode.presents.map((pr) => ({
    //             productId: pr.productId,
    //             variantId: pr.variant ? pr.variant.variantId : null,
    //             quantity: pr.count,
    //             id: pr.presentId,
    //           })),
    //         }
    //       : { promocodeValueId: null },
    //     promotions: promotions.map((prom) => {
    //       return {
    //         promotionId: prom.id,
    //         quantity: prom.quantity,
    //         promotionItems: prom.items.map((item) => {
    //           return {
    //             productId: item.id,
    //             quantity: item.quantity,
    //             variantId: item.variantId,
    //             promotionCategoryId: item.promotionCategoryId,
    //           };
    //         }),
    //       };
    //     }),
    //   });
    //
    // const orderDataReq = () =>
    //   API.patch(`/orders/${id}`, {
    //     shopId: orderType === "pickup" ? shop?.id : undefined,
    //     addressId: isDelivery ? address : undefined,
    //     readyTime:
    //       orderTime.fields.value === "" ? null : orderTime.fields.value,
    //     adminCommentary: comment.fields.value,
    //     receivingMethodId: orderType === "pickup" ? 0 : 1,
    //   });
    //
    // const paymentReq = () =>
    //   API.patch(`/orders/${id}/payment`, {
    //     spentTickets: +tickets.fields.value,
    //     paymentTypeId: paymentMethod.fields.value,
    //     oddMoney:
    //       paymentMethod.fields.value === 1
    //         ? oddMoney.fields.value === 1
    //           ? null
    //           : Number(change[oddMoney.fields.value - 1].value)
    //         : undefined,
    //     addressId: isDelivery ? address : undefined,
    //   });
    //
    // Promise.all([paymentReq(), orderDataReq(), itemsReq()])
    //   .then((result) => {
    //     alertSuccess(alertContext, "Данные успешно сохранены");
    //     console.log(result);
    //   })
    //   .catch((reject) => console.log(reject));
    //
    API.patch(`/orders/${id}`, {
      shopId: orderType === "pickup" ? shop?.id : undefined,
      addressId: isDelivery ? address : undefined,
      readyTime: orderTime.fields.value === "" ? null : orderTime.fields.value,
      orderCommentary: comment.fields.value,
      receivingMethodId: orderType === "pickup" ? 0 : 1,
    })
      .then(() => setTouched(false))
      .catch((error) => {
        alertError(alertContext, "Ошибка создания заказа");
      });

    const sendItems = () => {
      API.patch(`/orders/${id}/items`, {
        readyTime:
          orderTime.fields.value === "" ? null : orderTime.fields.value,
        items: products.map((el) => ({
          ...el,
          ingredientsToRemove: el.ingredientsToRemove.map((i) => i.id),
          ingredientsToAdd: el.ingredientsToAdd.map((i) => ({
            ingredientId: i.ingredientId,
            quantity: i.quantity,
          })),
        })),
        // {
        //   "promoCodeValueId":0,
        //   "presents":[{
        //   "productId":0,
        //   "variantId":0,
        //   "quantity":0
        // }]
        // },
        promoCode: promoCode
          ? {
              promocodeValueId: promoCode.promocodeValueId,
              presents: promoCode.presents.map((pr) => ({
                productId: pr.productId,
                variantId: pr.variant ? pr.variant.variantId : null,
                quantity: pr.count,
                id: pr.presentId,
              })),
            }
          : { promocodeValueId: null },
        promotions: promotions.map((prom) => {
          return {
            promotionId: prom.id,
            quantity: prom.quantity,
            promotionItems: prom.items.map((item) => {
              return {
                productId: item.id,
                quantity: item.quantity,
                variantId: item.variantId,
                promotionCategoryId: item.promotionCategoryId,
              };
            }),
          };
        }),
      })
        .then(() => setTouched(false))
        .catch((error) => {
          alertError(
            alertContext,
            getErrorMsg(error.response, "Ошибка создания заказа")
          );
        });
    };

    if (orderType === "delivery" && commonProducts) {
      if (
        products.find(
          (prod) =>
            commonProducts?.find((cprod) => prod.productId === cprod?.id)
              ?.isPickupOnly
        )
      ) {
        alertError(alertContext, "Некоторые товары не доступны для доставки");
      } else {
        sendItems();
      }
    } else {
      sendItems();
    }

    API.patch(`/orders/${id}/payment`, {
      spentTickets: +tickets.fields.value,
      paymentTypeId: paymentMethod.fields.value,
      oddFrom:
        paymentMethod.fields.value === 1
          ? oddMoney.fields.value === 1
            ? null
            : Number(change[oddMoney.fields.value - 1].value)
          : undefined,
      addressId: isDelivery ? address : undefined,
    })
      .then(() => setTouched(false))
      .catch((error) => {
        alertError(
          alertContext,
          getErrorMsg(error.response, "Ошибка обновления данных оплаты")
        );
      });
  };

  const CommonProducts = useMemo(
    () => (
      <div className={styles.commonProducts}>
        <Paper elevation={2} className={styles.paperElem}>
          <Autocomplete
            size="small"
            options={commonProducts}
            getOptionLabel={(option) => option.name}
            onChange={(e, newValue) => {
              if (newValue) {
                setProductId(newValue.id);
              }
            }}
            disabled={isOnlinePayment}
            renderInput={(params) => (
              <TextField {...params} label="поиск товара" variant="outlined" />
            )}
            className={styles.productsSearch}
            ListboxProps={{
              style: { scrollbarWidth: "none" },
            }}
          />
          <Paper>
            {category && (
              <Tabs
                value={category}
                onChange={(e: React.ChangeEvent<{}>, newValue: number) => {
                  if (!isOnlinePayment) {
                    setCategory(newValue);
                  }
                }}
                indicatorColor="primary"
                textColor="primary"
                aria-label="disabled tabs example"
                className={styles.tabs}
              >
                {categories.map((cat) => (
                  <Tab
                    disabled={isOnlinePayment}
                    key={cat.id}
                    label={cat.value}
                    value={cat.id}
                    style={{ minWidth: 10 }}
                  />
                ))}
                <Tab
                  label={"Акции"}
                  disabled={isOnlinePayment}
                  value={666}
                  style={{ minWidth: 10 }}
                />
              </Tabs>
            )}
            <TableContainer
              component={Paper}
              className={[
                styles.maxHeight,
                isOnlinePayment ? styles.disabled : "",
              ].join(" ")}
            >
              <Table aria-label="caption table" size="small">
                <TableHead>
                  <TableRow className={styles.commonRow}>
                    <TableCell>№</TableCell>
                    <TableCell>Наименование</TableCell>
                    <TableCell>Стоимость</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={styles.scrollable}>
                  {category === 666
                    ? commonPromotions
                        .filter(
                          (p) =>
                            p.isActive &&
                            (orderType === "pickup" ? true : !p.isPickupOnly)
                        )
                        .map((row, index) => (
                          <Fragment key={row.id}>
                            <TableRow
                              hover={!isOnlinePayment}
                              className={[
                                isOnlinePayment ? "" : styles.pointer,
                                styles.commonRow,
                              ].join(" ")}
                              onClick={() => {
                                if (!isOnlinePayment) {
                                  setPromotionId(row.id);
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.price}</TableCell>
                            </TableRow>
                          </Fragment>
                        ))
                    : commonProducts
                        .filter(
                          (p) =>
                            p.categoryId === category &&
                            (orderType === "pickup" ? true : !p.isPickupOnly)
                        )
                        .map((row, index) => (
                          <TooltipWrapper
                            key={index}
                            rowData={row}
                            type={"commonProduct"}
                          >
                            <TableRow
                              key={`${row.id}#tr`}
                              hover={!isOnlinePayment}
                              className={[
                                isOnlinePayment ? "" : styles.pointer,
                                styles.commonRow,
                              ].join(" ")}
                              onClick={() => {
                                if (!isOnlinePayment) {
                                  setProductId(row.id);
                                }
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.price}</TableCell>
                            </TableRow>
                          </TooltipWrapper>
                        ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Paper>
      </div>
    ),
    [
      commonProducts,
      commonPromotions,
      category,
      customer,
      products,
      promoCode,
      promotions,
      orderType,
      address,
      courier,
    ]
  );

  const ProductsTable = React.memo(() => (
    <div className={styles.orderProducts}>
      {products.length ? (
        <Paper className={styles.paperElem} elevation={2}>
          <InfoBlock title={products.length ? "Товары" : "Товары не добавлены"}>
            <TableContainer component={Paper}>
              <Table aria-label="caption table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>№</TableCell>
                    <TableCell>Наименование</TableCell>
                    <TableCell>Добавить</TableCell>
                    <TableCell>Убрать</TableCell>
                    <TableCell>Количество</TableCell>
                    <TableCell>Стоимость</TableCell>
                    <TableCell>К оплате</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((row, index) => (
                    <TooltipWrapper key={index} rowData={row} type={"product"}>
                      <TableRow
                        key={index}
                        hover={!isOnlinePayment}
                        className={isOnlinePayment ? "" : styles.pointer}
                        onClick={() => {
                          if (!isOnlinePayment) {
                            setCurrentProduct(row);
                          }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {commonProducts.find((el) => el.id === row.productId)!
                            .name +
                            (row.options
                              ? ` (${Object.values(row.options)[0].join(", ")})`
                              : "")}
                        </TableCell>
                        <TableCell>
                          {getIngredientsLabel(row.ingredientsToAdd)}
                        </TableCell>
                        <TableCell>
                          {getIngredientsLabel(row.ingredientsToRemove)}
                        </TableCell>
                        <TableCell
                          className={isOnlinePayment ? styles.disabled : ""}
                        >
                          <FlexRow className={styles.quantityRow}>
                            <RemoveCircleOutlineIcon
                              onClick={(
                                e: React.MouseEvent<SVGSVGElement, MouseEvent>
                              ) => {
                                if (!isOnlinePayment) {
                                  e.stopPropagation();
                                  setProducts((products) => {
                                    if (products[index].quantity === 1) {
                                      products.splice(index, 1);
                                      return [...products];
                                    } else {
                                      products[index].quantity =
                                        products[index].quantity - 1;
                                      return [...products];
                                    }
                                  });
                                  setTouched(true);
                                  if (promoCode) {
                                    setPromocode(undefined);
                                    alertWarning(
                                      alertContext,
                                      "Состав заказа изменился, промокод аннулирован"
                                    );
                                  }
                                }
                              }}
                            />
                            {row.quantity}
                            <AddCircleOutlineIcon
                              onClick={(
                                e: React.MouseEvent<SVGSVGElement, MouseEvent>
                              ) => {
                                if (!isOnlinePayment) {
                                  e.stopPropagation();
                                  setProducts((products) => {
                                    products[index].quantity =
                                      products[index].quantity + 1;
                                    return [...products];
                                  });
                                  setTouched(true);
                                }
                              }}
                            />
                          </FlexRow>
                        </TableCell>
                        <TableCell>{row.price}</TableCell>
                        <TableCell>{row.price * row.quantity}</TableCell>
                      </TableRow>
                    </TooltipWrapper>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfoBlock>
        </Paper>
      ) : (
        <></>
      )}
      {promotions.length ? (
        <Paper className={styles.paperElem} elevation={2}>
          <InfoBlock title={promotions.length ? "Акции" : "Акции не добавлены"}>
            <TableContainer component={Paper}>
              <Table aria-label="caption table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>№</TableCell>
                    <TableCell>Наименование</TableCell>
                    <TableCell>Количество</TableCell>
                    <TableCell>Стоимость</TableCell>
                    <TableCell>К оплате</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {promotions.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        hover={!isOnlinePayment}
                        className={isOnlinePayment ? "" : styles.pointer}
                        onClick={() => {
                          if (!isOnlinePayment) {
                            setPromotion(row);
                          }
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell
                          className={isOnlinePayment ? styles.disabled : ""}
                        >
                          <FlexRow className={styles.quantityRow}>
                            <RemoveCircleOutlineIcon
                              onClick={(
                                e: React.MouseEvent<SVGSVGElement, MouseEvent>
                              ) => {
                                if (!isOnlinePayment) {
                                  e.stopPropagation();
                                  setPromotions((proms) => {
                                    if (proms[index].quantity === 1) {
                                      proms.splice(index, 1);
                                      return [...proms];
                                    } else {
                                      proms[index].quantity =
                                        proms[index].quantity - 1;
                                      return [...proms];
                                    }
                                  });
                                  setTouched(true);
                                }
                              }}
                            />
                            {row.quantity}
                            <AddCircleOutlineIcon
                              onClick={(
                                e: React.MouseEvent<SVGSVGElement, MouseEvent>
                              ) => {
                                if (!isOnlinePayment) {
                                  e.stopPropagation();
                                  setPromotions((proms) => {
                                    proms[index].quantity =
                                      proms[index].quantity + 1;
                                    return [...proms];
                                  });
                                }
                              }}
                            />
                          </FlexRow>
                        </TableCell>
                        <TableCell>{row.priceOfOne}</TableCell>
                        <TableCell>
                          {(row.priceOfOne ?? row.price) * row.quantity}
                        </TableCell>
                      </TableRow>
                      {/*{row.items.map((product: any) => (*/}
                      {/*  <TableRow key={product.id}>*/}
                      {/*    <TableCell component="th" scope="row" />*/}
                      {/*    <TableCell className={styles.promotionProduct}>*/}
                      {/*      {product.name}*/}
                      {/*    </TableCell>*/}
                      {/*    <TableCell>*/}
                      {/*      <FlexRow className={styles.quantityRow}>*/}
                      {/*        <RemoveCircleOutlineIcon />*/}
                      {/*        {product.quantity}*/}
                      {/*        <AddCircleOutlineIcon />*/}
                      {/*      </FlexRow>*/}
                      {/*    </TableCell>*/}
                      {/*    <TableCell>{product.price}</TableCell>*/}
                      {/*    <TableCell>{product.total}</TableCell>*/}
                      {/*  </TableRow>*/}
                      {/*))}*/}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </InfoBlock>
        </Paper>
      ) : (
        <></>
      )}
      {promoCode ? (
        <Paper className={styles.paperElem} elevation={2}>
          <InfoBlock title={promoCode ? "Промокод" : "Промокод не добавлен"}>
            <PromoCodeInfoTable promoCode={promoCode} />
          </InfoBlock>
        </Paper>
      ) : (
        <></>
      )}
    </div>
  ));

  const handleProductClose = useCallback(() => {
    setProductId(-1);
    setCurrentProduct(null);
  }, []);

  const handleTouchCart = useCallback(
    (newProduct: DispatcherProduct) => {
      if (currentProduct) {
        const idx = products.findIndex((el) =>
          isObjectsEqual(el, currentProduct)
        );
        setProducts((products) =>
          products.map((el, index) => (index === idx ? newProduct : el))
        );
        if (promoCode) {
          setPromocode(undefined);
          alertWarning(
            alertContext,
            "Состав заказа изменился, промокод аннулирован"
          );
        }
        setCurrentProduct(null);
      } else {
        // @ts-ignore
        newProduct.options = {
          [Number(newProduct.variantId)]: newProduct.options[0],
        };
        const idx = products.findIndex((el) =>
          lodash.isEqual({ ...el, quantity: 1 }, { ...newProduct, quantity: 1 })
        );
        if (idx !== -1) {
          setProducts((products) =>
            products.map((el, index) =>
              index === idx ? { ...el, quantity: el.quantity + 1 } : el
            )
          );
        } else {
          setProducts((products) => [...products, newProduct]);
        }
      }
      setTouched(true);

      setProductId(-1);
    },
    [products, currentProduct]
  );

  const handleTouchCartPromotion = useCallback(
    (promotion: PromotionState) => {
      const promotionData = {
        ...promotion,
        priceOfOne: promotion.price / promotion.quantity,
      };
      if (currentPromotion) {
        const idx = promotions.findIndex((el) => lodash.isEqual(el, promotion));
        setPromotions((proms) =>
          proms.map((el, index) => (index === idx ? promotionData : el))
        );
        setCurrentProduct(null);
      } else {
        const idx = promotions.findIndex((el) => lodash.isEqual(el, promotion));
        if (idx !== -1) {
          setPromotions((proms) =>
            proms.map((el, index) =>
              index === idx
                ? { ...el, quantity: el.quantity + 1 }
                : promotionData
            )
          );
        } else {
          setPromotions((proms) => [...proms, promotionData]);
        }
      }
      setPromotionId(-1);
      setPromotion(null);
      setTouched(true);
    },
    [promotions, currentPromotion]
  );

  const handlePromotionClose = useCallback(() => {
    setPromotionId(-1);
    setPromotion(null);
  }, []);

  const handleAddressCreate = useCallback(
    (customerId: number, newAddressId: number) => {
      updateAddresses({ clientId: customerId, addressId: newAddressId });
      setIsAddressError(false);
      setAddressEdit(false);
      setAddingAddress(false);
    },
    [updateAddresses]
  );

  const handleAddressFormClose = useCallback(() => {
    setAddingAddress(false);
    setAddressEdit(false);
  }, []);

  return commonProducts.length ? (
    <OrderDialog id={id} createdAt={createdDate}>
      <div className={styles.formGrid}>
        {productId !== -1 && (
          <ProductForm
            productOptionsProp={currentProduct?.options}
            onClose={handleProductClose}
            productId={productId}
            product={currentProduct}
            touchCart={handleTouchCart}
          />
        )}
        {promotionId !== -1 && (
          <PromotionForm
            products={commonProducts}
            currentPromotion={currentPromotion ? currentPromotion : null}
            promotionId={promotionId}
            onClose={handlePromotionClose}
            addToPromo={handleTouchCartPromotion}
          />
        )}
        {(addingAddress || addressEdit) && customer && (
          <CreateAddressForm
            isAddressError={isAddressError}
            orderId={id}
            onAddressCreate={handleAddressCreate}
            addressData={
              addressEdit ? addresses.find((a) => a.id === address) : undefined
            }
            onClose={handleAddressFormClose}
            customerId={customer.id}
          />
        )}
        <div className={styles.orderInfo}>
          <CustomerForm
            customer={customer ? customer : null}
            setCustomer={(customer) => setCustomer(customer)}
            customerId={customer ? customer.id : null}
            isOrderEdit={isEdit}
          />
          <Paper elevation={2} className={styles.paperElem}>
            <InfoBlock
              title={"Заказ"}
              titleLabel={status ? titleLabel(status) : () => null}
            >
              <FlexRow>
                <ButtonGroup
                  style={{ margin: "10px 0 10px 0" }}
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  <Button
                    size={"small"}
                    variant={
                      orderType === "delivery" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setTouched(true);
                      setOrderType("delivery");
                    }}
                  >
                    Доставка
                  </Button>
                  <Button
                    size={"small"}
                    onClick={() => {
                      setTouched(true);
                      setOrderType("pickup");
                    }}
                    variant={
                      orderType === "delivery" ? "outlined" : "contained"
                    }
                  >
                    Самовывоз
                  </Button>
                </ButtonGroup>
              </FlexRow>
              <FlexRow className={styles.rowForm}>
                <IconButton
                  edge="start"
                  color="inherit"
                  size="small"
                  aria-label="delivery"
                  className={[styles.iconBefore, styles.active].join(" ")}
                >
                  <AccessTimeIcon />
                </IconButton>
                <FormControl className={styles.comment} error={true}>
                  <TextField
                    {...orderTimeLabel.fields}
                    variant={"outlined"}
                    label={"Время"}
                    size={"small"}
                    className={[styles.timeField, styles.shadedInput].join(" ")}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setTimeSelectIsOpen(true);
                    }}
                  />
                </FormControl>
              </FlexRow>
              <TimeSelect
                type={orderType === "delivery" ? "delivery" : "pickup"}
                shop={shop ? shops.find((el) => el.id === shop.id) : undefined}
                isOpen={timeSelectIsOpen}
                setTime={(data: Date | "как можно быстрее") => {
                  setTimeSelectIsOpen(false);
                  if (data === "как можно быстрее") {
                    orderTime.setValue("");
                    orderTimeLabel.setValue("как можно быстрее");
                  } else {
                    const time = data;
                    const date = moment(time).format("YYYY-MM-DDTHH:mm:ss.sss");
                    orderTime.setValue(date);
                    orderTimeLabel.setValue(
                      renderReadyTime(new Date(time.getTime()))
                    );
                  }
                  setTouched(true);
                }}
                onClose={() => setTimeSelectIsOpen(false)}
                withASAP={orderType === "delivery"}
              />
              {orderType === "delivery" ? (
                <FlexRow className={styles.rowForm}>
                  <IconButton
                    edge="start"
                    color="inherit"
                    size="small"
                    aria-label="delivery"
                    className={[styles.iconBefore, styles.active].join(" ")}
                    onClick={() => setOrderType("delivery")}
                  >
                    <LocalShippingIcon />
                  </IconButton>
                  <Autocomplete
                    style={{
                      minWidth: "370px",
                    }}
                    value={
                      addresses.find((a) => a.id === address)
                        ? addresses.find((a) => a.id === address)
                        : null
                    }
                    onChange={(e, v) => {
                      if (v?.id === -1) {
                        return setAddingAddress(true);
                      }
                      setTouched(true);
                      setAddress(v ? v.id : null);
                    }}
                    options={[
                      { id: -1, fullName: "Добавить новый адрес" },
                      ...addresses,
                    ]}
                    renderInput={(params) => (
                      <TextField
                        label={"Адрес Доставки"}
                        {...params}
                        size={"small"}
                        className={styles.shadedInput}
                        variant={"outlined"}
                      />
                    )}
                    getOptionLabel={(option) => option.fullName}
                  />
                  <IconButton
                    edge="start"
                    color="primary"
                    size="small"
                    aria-label="add"
                    className={styles.iconAfter}
                    onClick={() => setAddressEdit(true)}
                  >
                    {isAddressError ? (
                      <Warning color={"secondary"} />
                    ) : (
                      <Edit />
                    )}
                  </IconButton>
                </FlexRow>
              ) : (
                <></>
              )}
              <FlexRow className={styles.rowForm}>
                <IconButton
                  edge="start"
                  color="inherit"
                  size="small"
                  aria-label="pickup"
                  className={[styles.iconBefore, styles.active].join(" ")}
                  onClick={() => setOrderType("pickup")}
                >
                  <StoreIcon />
                </IconButton>
                {shop ? (
                  <FormControl>
                    <Autocomplete
                      value={shop}
                      style={{ minWidth: "370px" }}
                      onChange={(e, v) => {
                        // @ts-ignore
                        setShop(v ? v : null);
                        setTouched(true);
                      }}
                      options={shops}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={styles.shadedInput}
                          label={"Пиццерия"}
                          size={"small"}
                          variant={"outlined"}
                        />
                      )}
                      getOptionLabel={(option) => option.name}
                    />
                  </FormControl>
                ) : (
                  <FormControl>
                    <Autocomplete
                      value={shop}
                      style={{ minWidth: "370px" }}
                      onChange={(e, v) => {
                        // @ts-ignore
                        setShop(v ? v : null);
                        setTouched(true);
                      }}
                      options={shops}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={styles.shadedInput}
                          label={"Пиццерия"}
                          size={"small"}
                          variant={"outlined"}
                        />
                      )}
                      getOptionLabel={(option) => option.name}
                    />
                  </FormControl>
                )}
              </FlexRow>
              <FlexRow className={styles.rowForm}>
                <IconButton
                  edge="start"
                  color="inherit"
                  size="small"
                  aria-label="delivery"
                  className={[styles.iconBefore, styles.active].join(" ")}
                  onClick={() => undefined}
                >
                  <DirectionsRunIcon />
                </IconButton>
                <FormControl className={styles.comment}>
                  <TextField
                    label={"Курьер"}
                    variant={"outlined"}
                    disabled
                    value={courier ? courier : ""}
                    className={styles.shadedInput}
                    size={"small"}
                    onChange={(e) => {
                      return undefined;
                    }}
                    inputProps={{
                      maxLength: 100,
                      style: {
                        maxHeight: "60px",
                        overflow: "scroll",
                        scrollbarWidth: "none",
                      },
                    }}
                    multiline
                  />
                </FormControl>
              </FlexRow>
              <FlexRow className={styles.rowForm}>
                <IconButton
                  edge="start"
                  color="inherit"
                  size="small"
                  aria-label="delivery"
                  className={[styles.iconBefore, styles.active].join(" ")}
                  onClick={() => undefined}
                >
                  <TextFieldsIcon />
                </IconButton>
                <FormControl className={styles.comment}>
                  <TextField
                    label={"Комментарий"}
                    variant={"outlined"}
                    {...comment.fields}
                    value={comment.fields.value}
                    className={styles.shadedInput}
                    size={"small"}
                    onChange={(e) => {
                      setTouched(true);
                      comment.setValue(e.target.value);
                    }}
                    inputProps={{
                      maxLength: 100,
                      style: {
                        maxHeight: "60px",
                        overflow: "scroll",
                        scrollbarWidth: "none",
                      },
                    }}
                    multiline
                    placeholder={"как можно быстрее"}
                  />
                </FormControl>
              </FlexRow>
              <FlexRow className={styles.rowForm}>
                <Typography
                  color="inherit"
                  align="left"
                  className={styles.rowLabel}
                >
                  Источник:
                </Typography>
                <Button
                  variant={"outlined"}
                  size={"small"}
                  disabled
                  className={[
                    styles.active,
                    source === 0
                      ? styles.desktop
                      : source === 1
                      ? styles.android
                      : source === 2
                      ? styles.ios
                      : styles.phone,
                  ].join(" ")}
                >
                  {getSourceIcon()}
                </Button>
              </FlexRow>
              {status?.id === 2 || status?.id === 3 ? (
                <FlexRow>
                  <Typography
                    color="inherit"
                    align="left"
                    className={styles.rowLabel}
                  >
                    {`Время ожидания: `}
                    <span className={[styles.total].join(" ")}>
                      {waitingTime}
                    </span>
                  </Typography>
                </FlexRow>
              ) : (
                <></>
              )}
              {/*<FlexRow>*/}
              {/*  <Typography*/}
              {/*    color="inherit"*/}
              {/*    align="left"*/}
              {/*    className={styles.rowLabel}*/}
              {/*  >*/}
              {/*    уникальный номер:*/}
              {/*  </Typography>*/}
              {/*  <FormControl className={styles.orderId}>*/}
              {/*    <TextField {...orderId.fields} disabled />*/}
              {/*  </FormControl>*/}
              {/*</FlexRow>*/}
            </InfoBlock>
          </Paper>
          <Paper elevation={2} className={styles.paperElem}>
            <InfoBlock title={"Оплата"}>
              <FlexRow>
                <Typography
                  color="inherit"
                  align="left"
                  className={styles.rowLabel}
                >
                  способ:
                </Typography>
                {paymentMethod.fields.value && (
                  <CustomSelect
                    disabled={Number(paymentMethod.fields.value) === 2}
                    value={paymentMethod.fields.value}
                    onChange={(e) => {
                      if (e.target.value === 2) {
                        return;
                      }
                      paymentMethod.setValue(e.target.value as number);
                      setTouched(true);
                    }}
                    data={getPaymentsTypes()}
                  />
                )}
              </FlexRow>
              {console.log(isOnlinePayment, "online payment")}
              {isOnlinePayment ? (
                <>
                  <FlexRow>
                    <Typography
                      color="inherit"
                      align="left"
                      className={styles.rowLabel}
                    >
                      Статус:
                    </Typography>
                    <Typography
                      color="inherit"
                      align="left"
                      className={styles.rowLabel}
                      style={{ fontWeight: "bold", margin: "5px 0 5px 0" }}
                    >
                      {paymentStatus === "success"
                        ? "Оплачено"
                        : paymentStatus === "failure"
                        ? "Ошибка оплаты"
                        : "Ожидается"}
                    </Typography>
                    {paymentStatus === "processing" ||
                    paymentStatus === undefined ? (
                      <RefreshIcon onClick={refreshPaymentStatus} />
                    ) : (
                      <></>
                    )}
                  </FlexRow>
                  {paymentStatus === "success" ? (
                    <FlexRow>
                      <Typography
                        color="inherit"
                        align="left"
                        className={styles.rowLabel}
                      >
                        Идентификатор:
                      </Typography>
                      <Typography
                        style={{ fontWeight: "bold", margin: "5px 0 5px 0" }}
                        color="inherit"
                        align="left"
                        className={styles.rowLabel}
                      >
                        {externalPaymentId}
                      </Typography>
                    </FlexRow>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
              <FlexRow>
                <Typography
                  color="inherit"
                  align="left"
                  className={[
                    styles.rowLabel,
                    styles.changeFromLabel,
                    paymentMethod.fields.value !== 1 && styles.disabledText,
                  ].join(" ")}
                >
                  сдача с:
                </Typography>
                <CustomSelect
                  value={oddMoney.fields.value}
                  onChange={(e) => {
                    oddMoney.setValue(Number(e.target.value));
                    setTouched(true);
                  }}
                  data={change}
                  disabled={paymentMethod.fields.value !== 1}
                />
              </FlexRow>
              <FlexRow>
                <Typography
                  color="inherit"
                  align="left"
                  className={styles.rowLabel}
                >
                  тикеты:
                </Typography>
                <FormControl className={styles.tickets}>
                  <TextField
                    value={tickets.fields.value}
                    onChange={(e) => {
                      tickets.setValue(Number(e.target.value));
                      setTouched(true);
                    }}
                  />
                </FormControl>
                <Typography
                  color="inherit"
                  align="left"
                  className={styles.rowLabel}
                >
                  из {totalTickets.fields.value}
                </Typography>
              </FlexRow>
              <br />
              <Typography color="inherit" align="left">
                к оплате:{" "}
                <span className={[styles.rowLabel, styles.total].join(" ")}>
                  {total} ₽
                </span>
              </Typography>
              {/*<FlexRow>*/}
              {/*  <Typography*/}
              {/*    color="inherit"*/}
              {/*    align="left"*/}
              {/*    className={styles.rowLabel}*/}
              {/*  >*/}
              {/*    уникальный номер:*/}
              {/*  </Typography>*/}
              {/*  <FormControl className={styles.orderId}>*/}
              {/*    <TextField {...orderId.fields} disabled />*/}
              {/*  </FormControl>*/}
              {/*</FlexRow>*/}
            </InfoBlock>
          </Paper>
        </div>
        <div className={styles.rightSideContainer}>
          {CommonProducts}
          <div className={styles.orderControl}>
            {deletedAt ? (
              <></>
            ) : (
              <Paper elevation={2} className={styles.paperElem}>
                <FlexRow className={styles.statusRow}>
                  <Button
                    color="primary"
                    onClick={isEdit ? updateOrder : handleCreateOrder}
                    variant={
                      // prettier-ignore
                      !isCreateEnabled() || !touched || !(status?.id === 0 || status?.id === 1 || status?.id === 2 || !isEdit) ? 'text' : 'contained'
                    }
                    disabled={
                      // prettier-ignore
                      !isCreateEnabled() || !touched || !(status?.id === 0 || status?.id === 1 || status?.id === 2 || !isEdit) || (isAddressError && orderType === "delivery") || paymentStatus === "success"
                    }
                  >
                    {isEdit ? "Редактировать заказ" : "Создать заказ"}
                  </Button>
                  {isEdit && (
                    <Button
                      onClick={cancelOrder}
                      variant={"outlined"}
                      color="secondary"
                      style={{ marginLeft: "15px" }}
                    >
                      Отменить заказ
                    </Button>
                  )}
                </FlexRow>
                <Divider style={{ marginBottom: "10px" }} />
                <FlexRow>
                  {isEdit && (
                    <Button
                      startIcon={<RefreshIcon />}
                      onClick={() => fetchOrderData(id)}
                      variant={"contained"}
                      color="primary"
                      size={"small"}
                      style={{ marginLeft: "15px" }}
                    >
                      Обновить
                    </Button>
                  )}
                  <Button
                    startIcon={<BackspaceIcon />}
                    variant={"contained"}
                    color="secondary"
                    size={"small"}
                    style={{ marginLeft: "15px" }}
                    onClick={handleClearFields}
                  >
                    Сбросить
                  </Button>
                </FlexRow>
              </Paper>
            )}
            <Paper>
              <DispatcherPromocodeForm
                onApply={(data) => {
                  if (!isOnlinePayment) {
                    setTouched(true);
                    setPromocode(data);
                  }
                }}
                onDelete={() => {
                  if (!isOnlinePayment) {
                    setTouched(true);
                    setPromocode(undefined);
                  }
                }}
                appliedPromoCode={promoCode && promoCode}
                customerId={customer?.id}
                disabled={!(status?.id !== 0 || isEdit) || isOnlinePayment}
                sourceId={source}
                items={products}
                commonProducts={commonProducts}
              />
            </Paper>
          </div>
        </div>
        <ProductsTable />
        {prevOrders.length ? (
          <div className={styles.orderProducts}>
            <InfoBlock title={"Прошлые заказы"}>
              <OrderHistory
                isOnlinePayment={isOnlinePayment}
                orders={prevOrders}
                addProd={addProdFromHistory}
                addPromo={addPromoFromHistory}
                copyOrder={copyOrder}
                commonProducts={commonProducts}
              />
            </InfoBlock>
          </div>
        ) : (
          <></>
        )}
        <OrderInWorkDialog
          open={orderInWorkModal}
          data={{
            readyTime: orderTime.fields.value,
            orderNumber: id,
            amountToBePaid: total,
            receivingType: orderType,
          }}
          onClose={() => setOrderInWorkModal(false)}
          onConfirm={(data) => {
            setOrderInWork(data);
            setOrderInWorkModal(false);
          }}
        />
      </div>
      <Dialog
        disableBackdropClick
        open={isCancelConfirm}
        onBackdropClick={() => {
          setCancelReason("");
          setIsCancelConfirm(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Подтверждение отмены</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="description"
            label="Причина отмены (необязательно)"
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCancelReason("");
              setIsCancelConfirm(false);
            }}
            color="primary"
          >
            Отмена
          </Button>
          <Button
            onClick={() => {
              let url = `/orders/${id}/cancel`;
              if (cancelReason.length > 0) url += `?comment=${cancelReason}`;
              API.put(url)
                .then(() => {
                  alertSuccess(alertContext, "Заказ отменен");
                  setCancelReason("");
                  setIsCancelConfirm(false);
                  setStatus({ id: 3, description: "Отменен" });
                  return <Redirect to={"dispatcher-page"} />;
                })
                .catch((error) =>
                  alertError(alertContext, "Ошибка отмены заказа")
                );
            }}
            color="primary"
            type="submit"
          >
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    </OrderDialog>
  ) : (
    <></>
  );
}
