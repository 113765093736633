import React, { useEffect, useRef, useState } from "react";
import { getIntFromString } from "./utils";
import cookies from "../../cookies";

export function useInterval(callback: any, delay: any) {
  let savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useInput<T>(initialValue: T) {
  const [value, setValue] = useState(initialValue);

  const onChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => setValue(e.target.value as T);

  return {
    fields: {
      value,
      onChange,
    },
    setValue,
  };
}

export function useIntInput(initialValue: number) {
  const [value, setValue] = useState(initialValue);

  const onChange = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) =>
    e.target.value === " "
      ? null
      : setValue(getIntFromString(e.target.value as string));

  return {
    fields: {
      value,
      onChange,
    },
    setValue,
  };
}

export function useUsersRoles() {
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    const user = cookies.get("user");
    if (user) {
      setRoles(user.roles);
    }
  }, []);

  return roles;
}
