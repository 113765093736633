import React, { FC, useState, useEffect, useContext, useMemo } from "react";
import {
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AlertContextType,
  newPromoCodeList,
  PromoCodeValuesItem,
} from "../../../_shared/types";
import { Search } from "@material-ui/icons";
import API from "../../../_shared/axios";
import { getPromoValuesQuery } from "../../OrdersPage/shared";
import { alertError, getErrorMsg } from "../../../_shared/utils";
import { AlertContext } from "../../_shared/ToastList";
import moment from "moment";
import PersonalValueRow from "./PersonalValueRow";
import { Moment } from "moment/moment";
import Button from "@material-ui/core/Button";

const PromocodeValues: FC<{
  id: number;
  type: number;
  cachedValues: string[];
}> = ({ id, type, cachedValues }) => {
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [customerSearchString, setCustomerSearchString] = useState("");
  const [valueSearchString, setValueSearchString] = useState("");
  const [entries, setEntries] = useState<PromoCodeValuesItem[]>([]);
  const [filtered, setFiltered] = useState<PromoCodeValuesItem[]>([]);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [codeList, setCodeList] = useState<PromoCodeValuesItem[]>([]);
  const [stopWarningShow, setStopWarningShow] = useState(false);
  const [groupEdit, setGroupEdit] = useState(false);
  const [groupIdxs, setGroupIdxs] = useState<Array<number>>([]);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [noDateOnly, setNoDateOnly] = useState(false);

  useEffect(() => {
    if (stopWarningShow) {
      setTimeout(() => {
        setStopWarningShow(false);
      }, 180000);
    }
  }, [stopWarningShow]);

  useEffect(() => {
    setCodeList([...filtered]);
  }, [filtered]);

  const singleSave = (index: number, startDate: Moment | null) => {
    if (startDate !== null) {
      API.patch(`/promoCodes/personal`, {
        values: groupIdxs.map((i) => ({
          id: codeList[i].promoCodeValueId,
          startDate: startDate.format("YYYY.MM.DD 00:00:00"),
        })),
      })
        .then(() => {
          // setEdit(false);
          setCodeList((state) => {
            const list = [...state];
            groupIdxs.forEach(
              (i) =>
                (list[i].startDate = startDate.format("YYYY-MM-DDT00:00:00"))
            );
            return list;
          });
          setGroupIdxs([]);
        })
        .catch((e) => {
          console.error(e);
          alertError(
            alertContext,
            getErrorMsg(e.response, "Ошибка обновления")
          );
        });
      return;
    }
  };

  const onValueChange = (index: number, newDate: string) => {
    setCodeList((state) => {
      const list = [...state];
      list[index].startDate = newDate;
      return list;
    });
  };

  const onValueDelete = (index: number) => {
    setCodeList((state) => {
      const list = [...state];
      list.splice(index, 1);
      return list;
    });
    setTotalItems(totalItems - 1);
  };

  const updateList = () =>
    API.get(
      getPromoValuesQuery(
        id,
        customerSearchString.replace("+", ""),
        valueSearchString,
        noDateOnly,
        page + 1,
        pageSize
      )
    );

  useEffect(() => {
    updateList()
      .then(({ data }) => {
        const sorted = data.items.sort((i: any) =>
          cachedValues.includes(i.value) ? -1 : +1
        );
        setEntries(data.items);
        setTotalItems(data.totalItems);
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка получения значений промокода");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, customerSearchString, valueSearchString, noDateOnly]);

  useEffect(() => {
    setPage(0);
  }, [customerSearchString, valueSearchString]);

  const isNumeric = (value: string) => /^\d+$/.test(value) || value === "";

  useEffect(() => {
    setFiltered(
      entries.filter(
        (v) =>
          String(v.value)
            .toLowerCase()
            .includes(customerSearchString.toLowerCase()) ||
          String(v.customerPhoneNumber)
            .toLowerCase()
            .includes(customerSearchString.toLowerCase())
      )
    );
  }, [entries, customerSearchString]);

  useEffect(() => {
    if (groupIdxs.length === 0) {
      setStartDate(null);
    } else if (groupIdxs.length === 1) {
      const date = codeList[groupIdxs[0]].startDate;
      if (date) setStartDate(moment(date));
    }
  }, [groupIdxs]);

  const getSortedCodeList = () => {
    return codeList.sort((a, b) => a.promoCodeValueId < b.promoCodeValueId ? 1 : -1);
  }

  return (
    <Box>
      <Box>
        <Grid
          direction={"row"}
          justify={"flex-start"}
          alignItems={"center"}
          container
        >
          {type === 2 ? (
            <Grid
              style={{ width: "580px", margin: "10px" }}
              alignItems={"center"}
              container
              direction={"row"}
              justify={"space-between"}
            >
              <TextField
                style={{ width: "250px" }}
                margin={"dense"}
                type={"text"}
                value={valueSearchString}
                onChange={(e) => setValueSearchString(e.target.value)}
                placeholder={"Значение промокода"}
                variant={"outlined"}
                size={"small"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <Paper style={{ margin: " 0 10px 0 10px" }}>
                <Grid
                  onClick={() => setNoDateOnly(!noDateOnly)}
                  style={{ width: "280px" }}
                  alignItems={"center"}
                  container
                  direction={"row"}
                  justify={"space-around"}
                >
                  <Typography variant={"subtitle2"}>
                    Показать значения без даты
                  </Typography>
                  <Checkbox
                    checked={noDateOnly}
                    onClick={() => setNoDateOnly(!noDateOnly)}
                  />
                </Grid>
              </Paper>
            </Grid>
          ) : (
            <>
              <TextField
                style={{ width: "400px" }}
                margin={"dense"}
                type={"text"}
                value={customerSearchString}
                onChange={(e) => setCustomerSearchString(e.target.value)}
                placeholder={"Номер телефона"}
                variant={"outlined"}
                size={"small"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                style={{ width: "400px", marginLeft: "30px" }}
                margin={"dense"}
                type={"text"}
                value={valueSearchString}
                onChange={(e) => setValueSearchString(e.target.value)}
                placeholder={"Значение промокода"}
                variant={"outlined"}
                size={"small"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}
        </Grid>
      </Box>
      {entries.length ? (
        <>
          <Box style={{ margin: "15px" }}>
            <Grid container spacing={3}>
              {type === 2 && (
                <Grid alignItems={"center"} style={{ margin: "0 0 0 10px" }}>
                  <Typography variant={"subtitle2"}>
                    Вы можете выбрать несколько значений для группового
                    редактирования
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
          <TableContainer>
            <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>Значение промокода</TableCell>
                  <TableCell>Номер клиента</TableCell>
                  {(type === 2 || type === 1) && (
                    <TableCell>Дата начала</TableCell>
                  )}
                  <TableCell>Дата окончания</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getSortedCodeList().map((value, i) =>
                  type === 2 || type === 1 ? (
                    <React.Fragment key={i}>
                      <PersonalValueRow
                        type={type}
                        key={value.value}
                        index={i}
                        edit={groupIdxs.includes(i)}
                        setEdit={(v) => {
                          v
                            ? setGroupIdxs((state) => [...state, i])
                            : setGroupIdxs((state) => {
                                const arr = [...state];
                                const idx = arr.findIndex((v) => v === i);
                                arr.splice(idx, 1);
                                return [...arr];
                              });
                        }}
                        groupEdit={groupEdit}
                        data={value}
                        save={singleSave}
                        startDate={startDate}
                        dateChange={(date) => {
                          setStartDate(date);
                        }}
                        onDelete={onValueDelete}
                        warningDisabled={stopWarningShow}
                        disableWarning={() => setStopWarningShow(true)}
                        highlight={cachedValues.includes(value.value)}
                      />
                    </React.Fragment>
                  ) : (
                    <TableRow key={value.promoCodeValueId}>
                      <TableCell>{value.value}</TableCell>
                      <TableCell>{value.customerPhoneNumber}</TableCell>
                      {(type === 2 || type === 1) && (
                        <TableCell>
                          {(value.startDate &&
                            moment(value.startDate).format("DD.MM.YYYY")) ||
                            "-"}
                        </TableCell>
                      )}
                      <TableCell>
                        {(value.expirationDate &&
                          moment(value.expirationDate).format("DD.MM.YYYY")) ||
                          "-"}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={"Записей на странице"}
            labelDisplayedRows={({ from, to }) =>
              `${from}-${to} из ${totalItems}`
            }
            rowsPerPageOptions={[10, 15, 25, 50]}
            component="div"
            count={totalItems}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={(e) => setPageSize(Number(e.target.value))}
            page={page}
            onChangePage={(e, newPage) => setPage(newPage ? newPage : 0)}
          />
        </>
      ) : (
        <Grid
          container
          alignItems={"center"}
          style={{ width: "100%", margin: "30px" }}
          justify={"center"}
        >
          <Typography variant={"h6"}>Нет данных</Typography>
        </Grid>
      )}
    </Box>
  );
};

export default PromocodeValues;
