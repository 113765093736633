import React, { FC, useContext, useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  AlertContextType,
  ItemsEdit,
  OrderCommonPromotion,
  OrderMainEdit,
  OrderPromotion,
  OrderView,
  PaymentEdit,
  ProductRow,
  StreetSearchResponse,
  UserCookie,
} from "../../_shared/types";
import {
  alertError,
  alertSuccess,
  copyObject,
  getFormattedPhoneNumber,
  getIntFromString,
  isObjectsEqual,
  renderDate,
  mergePromotionItems,
} from "../../_shared/utils";
import { ProgressBar } from "../_shared/ProgressBar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Checkbox,
  CircularProgress,
  DialogContentText,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TableBody,
  TextareaAutosize,
  TextField,
  Typography,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import API from "../../_shared/axios";
import { AlertContext } from "../_shared/ToastList";
import ProductConfiguration from "./ProductConfiguration";
import InputCounter from "../_shared/InputCounter";
import { KladrObjectType } from "../StreetsPage/_shared/types";
import { Autocomplete } from "@material-ui/lab";
import { Shop } from "../_shared/types";
import TimeSelect from "./TimeSelect";
import { useStyles, addProductToProductSet, getShopIntervals } from "./shared";
import {
  AddressBlock,
  CurrentItem,
  OrderBlock,
  PaymentBlock,
  PaymentType,
} from "./types";
import cookies from "../../../cookies";
import OrderInWorkDialog from "./OrderInWorkDialog";
import Transition from "../_shared/Transition";
import RefreshIcon from "@material-ui/icons/Refresh";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment/moment";
import PromoCodeInfoTable from "./promocodeInfoTable";

const columns = [
  { id: "name", label: "Наименование" },
  { id: "counts", label: "" },
  { id: "options", label: "Опции" },
  { id: "addedIngredients", label: "Добавляемые ингредиенты" },
  { id: "removedIngredients", label: "Удаляемые ингредиенты" },
  {
    id: "price",
    label: "Стоимость",
  },
  {
    id: "delete",
    label: "",
  },
];

const promotionsColumns = [
  { id: "name", label: "Наименование" },
  { id: "counts", label: "" },
  { id: "options", label: "Опции" },
  {
    id: "price",
    label: "Стоимость",
  },
  {
    id: "delete",
    label: "",
  },
];

const orderInit: OrderView = {
  createdAt: "",
  accruedTickets: 5,
  isAddressError: false,
  adminCommentary: "",
  amountToBePaid: 100,
  canCancel: false,
  customer: {
    birthDay: "",
    email: "",
    firstName: "",
    id: 75,
    lastName: "",
    patronymic: "",
    phoneNumber: "+79212280344",
  },
  promoCode: {
    id: 1,
    name: "string",
    value: "string",
    presents: [],
  },
  promotions: [
    {
      promotionId: 1,
      name: "",
      productId: 1,
      quantity: 1,
      totalCost: 1,
      items: [
        {
          id: 1,
          promotionCategoryId: 1,
          name: "",
          options: { 3: ["тонкое", "38"] },
          productId: 1,
          quantity: 1,
          variantId: 1,
          totalPrice: 1,
        },
      ],
    },
  ],
  items: [
    {
      id: 1,
      ingredientsToAdd: [{ name: "", quantity: 1, ingredientId: 1 }],
      ingredientsToRemove: [{ id: 1, name: "", quantity: 1, ingredientId: 2 }],
      name: "",
      options: { 3: ["тонкое", "38"] },
      productId: 1,
      variantId: 1,
      quantity: 1,
      totalPrice: 1,
    },
  ],
  deliveryAddress: {
    addressComment: "",
    apartment: "",
    city: "",
    code: "",
    deliveryAddress: "",
    kladrId: "",
    entrance: "",
    floor: "",
    house: "",
    id: 1,
    isAddressError: false,
    street: "",
    streetType: "",
  },
  readyTime: "2020-10-22T13:39:35.718",
  id: 40,
  paymentStatus: false,
  oddMoney: 0,
  orderComment: "1",
  orderNumber: "ef97ec11-d762-42e4-a551-22af642fd87d",
  payment: {
    state: 1,
    GatewayName: "",
    externalPaymentId: "",
    status: "",
  },
  paymentType: {
    type: "Cash",
    description: "Наличные",
    isOnline: false,
    id: 1,
  },
  shop: undefined,
  source: { sourceId: 0, description: "Сайт" },
  spentTickets: 0,
  status: { id: 0, description: "Создан" },
  totalPrice: 100,
};

export const getCreatedDate = (dateStr: string) => {
  const strToDate = new Date(dateStr);
  let date = new Date(
    strToDate.setTime(strToDate.getTime() + 3 * 60 * 60 * 1000)
  ).toISOString();
  const datePart = date.slice(0, 10);
  const timePart = date.slice(11, 16);
  return `${datePart} ${timePart}`;
};

const OrderForm: FC<{
  updateOrdersList: () => void;
  orderId: number | undefined;
  onClose: () => void;
}> = ({ onClose, orderId, updateOrdersList }) => {
  const [kladrObjectTypes, setKladrObjectTypes] = useState<KladrObjectType[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [oldOrder, setOldOrder] = useState<OrderView>(orderInit);
  const [order, setOrder] = useState<OrderView>(orderInit);
  const [progress, setProgress] = useState(false);
  const [asapDelivery, setAsapDelivery] = useState(false);
  const [oldAsapDelivery, setOldAsapDelivery] = useState(false);
  const [oldIsNewAddress, setOldIsNewAddress] = useState(false);
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [isNeedToChooseShop, setIsNeedToChooseShop] = useState(false);
  const [currentProductVariants, setCurrentProductVariants] = useState<
    number[]
  >([]);
  const [receivingType, setReceivingType] = useState<"delivery" | "pickup">(
    "delivery"
  );
  const [oldReceivingType, setOldReceivingType] = useState<
    "delivery" | "pickup"
  >("delivery");
  const [products, setProducts] = useState<ProductRow[]>([]);
  const [promotionProducts, setPromotionProducts] = useState<ProductRow[]>([]);
  const [promotions, setPromotions] = useState<OrderPromotion[]>([]);
  const [paymentsTypes, setPaymentsTypes] = useState<PaymentType[]>([]);
  const [oddFromVariants] = useState([
    "Без сдачи",
    "500",
    "1000",
    "2000",
    "5000",
  ]);
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const [currentPromotionId, setCurrentPromotionId] = useState(0);
  const [currentAddressShopId, setCurrentAddressShopId] = useState(0);
  const [oldCurrentAddressShopId, setOldCurrentAddressShopId] = useState(0);
  const [addressesList, setAddressesList] = useState<
    {
      apartment: string;
      house: string;
      street: string;
    }[]
  >([]);
  const [selectedPromotionIndex, setSelectedPromotionIndex] = useState<
    number | null
  >(null);
  const [currentItem, setCurrentItem] = useState<CurrentItem>();
  const [currentType, setCurrentType] = useState("items");
  const [currentItemOpen, setCurrentItemOpen] = useState(false);
  const [adminCommentaryFocus, setAdminCommentaryFocus] = useState(false);
  const [isOrderEditAvailable, setIsOrderEditAvailable] = useState(false);
  const [isCancelConfirm, setIsCancelConfirm] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const adminCommentaryInput = useRef<HTMLTextAreaElement>(null);
  const [orderInWorkModal, setOrderInWorkModal] = useState(false);
  const [searchList, setSearchList] = useState<StreetSearchResponse[]>([]);
  const alertContext = useContext<AlertContextType>(AlertContext);

  const [minMaxIntervals, setMinMaxIntervals] = useState(
    [
      {
        min: new Date(2020, 0, 1, 0, 0, 0),
        max: new Date(2020, 0, 2, 0, 0, 0),
      },
    ] || null
  );

  const [orderBlock, setOrderBlock] = useState<OrderBlock>();
  const [oldOrderBlock, setOldOrderBlock] = useState<OrderBlock>();

  const [addressBlock, setAddressBlock] = useState<AddressBlock | null>();
  const [oldAddressBlock, setOldAddressBlock] = useState<AddressBlock | null>();

  const [paymentBlock, setPaymentBlock] = useState<PaymentBlock>();
  const [oldPaymentBlock, setOldPaymentBlock] = useState<PaymentBlock>();
  const [shops, setShops] = useState<Shop[]>([]);
  const [wrongReadyTime, setWrongReadyTime] = useState(false);
  const [confirmWindowOpen, setConfirmWindowOpen] = useState(false);
  const [timeSelectIsOpen, setTimeSelectIsOpen] = useState(false);
  const [promotionCategories, setPromotionCategories] = useState<any[]>([]);

  const user: UserCookie | undefined = cookies.get("user");

  const isOrderBlockChanged = () => {
    return !orderBlock || !oldOrderBlock
      ? false
      : !isObjectsEqual(orderBlock, oldOrderBlock) ||
          oldAsapDelivery !== asapDelivery ||
          (oldOrder.shop === null && order.shop !== null) ||
          (oldOrder &&
            oldOrder.shop &&
            order &&
            order.shop &&
            oldOrder.shop.id !== order.shop.id) ||
          receivingType !== oldReceivingType;
  };
  const isPaymentBlockChanged = () =>
    !paymentBlock || !oldPaymentBlock
      ? false
      : !isObjectsEqual(paymentBlock, oldPaymentBlock);
  const isAddressBlockChanged = () =>
    !addressBlock || !oldAddressBlock
      ? false
      : !isObjectsEqual(addressBlock, oldAddressBlock) ||
        oldCurrentAddressShopId !== currentAddressShopId ||
        order.deliveryAddress?.street !== oldOrder.deliveryAddress?.street ||
        order.deliveryAddress?.isAddressError !==
          oldOrder.deliveryAddress?.isAddressError ||
        isNewAddress !== oldIsNewAddress;

  const onlyAsapPickupAvailable = () => {
    if (receivingType === "delivery" || minMaxIntervals.length < 1)
      return false;
    const now = new Date();
    const lastTillTime = minMaxIntervals[minMaxIntervals.length - 1].max;
    return (
      lastTillTime.getTime() > now.getTime() &&
      lastTillTime.getTime() - now.getTime() <= 20 * 60 * 1000
    );
  };
  useEffect(() => {
    if (
      adminCommentaryFocus &&
      adminCommentaryInput &&
      adminCommentaryInput.current
    )
      adminCommentaryInput.current.focus();
  }, [adminCommentaryFocus]);

  useEffect(() => {
    if (orderId) {
      setIsLoading(true);
      API.get(`/shops`)
        .then(({ data: shopsResponse }) => {
          const shopsIds = shopsResponse.map((el: { id: number }) => el.id);
          let shopsData: Shop[] = [];
          let shopsCount = 0;
          API.get(`/orders/${orderId}`)
            .then(({ data: orderData }: { data: OrderView }) => {
              let data = orderData;
              if (data.readyTime === null) {
                data.readyTime = new Date().toISOString().slice(0, -1);
                setAsapDelivery(true);
                setOldAsapDelivery(true);
              } else {
                setAsapDelivery(false);
                setOldAsapDelivery(false);
              }
              if (
                orderData.shop &&
                !shopsResponse
                  .map((el: any) => el.id)
                  .includes(orderData.shop.id)
              )
                orderData.shop = undefined;
              setOldOrder(data);
              setOrder(data);
              setReceivingType(data.deliveryAddress ? "delivery" : "pickup");
              setOldReceivingType(data.deliveryAddress ? "delivery" : "pickup");
              const orderBlockData = {
                oddFrom: data.oddMoney,
                readyTime: data.readyTime,
                adminCommentary: data.adminCommentary,
                spentTickets: data.spentTickets,
                shopId: data.shop ? data.shop.id : undefined,
                addressId: data.deliveryAddress?.id,
              };
              setOrderBlock(orderBlockData);
              setOldOrderBlock(orderBlockData);
              const paymentBlockData = {
                addressId: data.deliveryAddress?.id,
                oddFrom: data.oddMoney ? data.oddMoney : 0,
                spentTickets: data.spentTickets,
                paymentTypeId: data.paymentType.id,
              };
              setPaymentBlock(paymentBlockData);
              setOldPaymentBlock(paymentBlockData);
              const addressBlockData = data.deliveryAddress
                ? {
                    customerId: data.customer.id,
                    orderId: data.id,
                    addressComment: data.deliveryAddress.addressComment,
                    apartment: data.deliveryAddress.apartment
                      ? +data.deliveryAddress.apartment
                      : undefined,
                    code: data.deliveryAddress.code
                      ? +data.deliveryAddress.code
                      : undefined,
                    entrance: data.deliveryAddress.entrance
                      ? +data.deliveryAddress.entrance
                      : undefined,
                    floor: data.deliveryAddress.floor
                      ? +data.deliveryAddress.floor
                      : undefined,
                    house: data.deliveryAddress.house
                      ? data.deliveryAddress.house
                      : "",
                    isAddressError: false,
                    kladrId: +data.deliveryAddress.kladrId,
                    kladrObjectCoordinateId: undefined,
                  }
                : undefined;
              setAddressBlock(addressBlockData);
              setOldAddressBlock(addressBlockData);
              setCurrentPromotionId(0);
              API.get(`/customers/${orderData.customer.id}/addresses`)
                .then(({ data }) => {
                  setAddressesList(data);
                  if (
                    orderData.deliveryAddress &&
                    orderData.deliveryAddress.id
                  ) {
                    const currentAddress = data.find(
                      // @ts-ignore
                      (el: any) => el.id === orderData.deliveryAddress.id
                    )!;
                    setCurrentAddressShopId(
                      currentAddress?.shopId ? currentAddress.shopId : 0
                    );
                    setOldCurrentAddressShopId(
                      currentAddress?.shopId ? currentAddress.shopId : 0
                    );
                  }
                })
                .catch((error) => {
                  alertError(alertContext, "Ошибка получения адресов");
                });
              // todo delete this. в promotions/items нужно имя категории акции
              API.get(`/promotionCategories`).then(({ data }) => {
                setPromotionCategories(data);
              });
            })
            .catch((error) =>
              alertError(alertContext, "Ошибка получения заказа")
            );

          shopsIds.forEach((id: number) => {
            API.get(`/shops/${id}`)
              .then(({ data }) => {
                shopsCount++;
                shopsData = [...shopsData, data];
                if (shopsCount === shopsIds.length) setShops(shopsData);
              })
              .catch((error) => {
                alertError(alertContext, "Ошибка получения точки продаж");
              });
          });
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка получения списка точек продаж");
        });
      API.get(`/kladrObjects/types`)
        .then(({ data }: { data: KladrObjectType[] }) => {
          setKladrObjectTypes(data.filter((el) => el.level === 10));
        })
        .catch((error) => {
          alertError(
            alertContext,
            "Ошибка получения списка типов кладр-объектов"
          );
        });
      API.get(`/orderProducts`)
        .then(({ data }: { data: ProductRow[] }) => {
          const productsData = data.map((el) => {
            return { ...el, isOptionsError: el.variants.length > 0 };
          });
          setPromotionProducts(productsData);
          setProducts(productsData);
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка получения списка товаров");
        });
      API.get(`/orderPromotions`)
        .then(({ data }: { data: OrderPromotion[] }) => {
          setPromotions(data);
        })
        .catch((error) => {
          alertError(alertContext, "Ошибка получения списка акций");
        });
      API.get(`/payments/types`).then(({ data }) => {
        setPaymentsTypes(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  useEffect(() => {
    if (
      paymentBlock &&
      (addressBlock || addressBlock === undefined) &&
      orderBlock
    )
      setIsLoading(false);
  }, [paymentBlock, addressBlock, orderBlock]);

  useEffect(() => {
    if (currentProductIndex !== 0 && currentPromotionId !== 0) {
      const selectedProduct = promotionProducts.find(
        (el, index) => index === currentProductIndex - 1
      )!;
      const selectedPromotion = promotions.find(
        (el) => el.id === currentPromotionId
      )!;
      const isOptionsError = selectedProduct.variants.length > 0;
      let newOrder = {
        ...order,
        promotions: order.promotions.map((prom: any) => {
          const curItems = [
            ...prom.items,
            {
              promotionCategoryId: selectedProduct.promotionCategoryId,
              totalPrice: selectedProduct.price,
              name: selectedProduct.name,
              productId: selectedProduct.id,
              options: undefined,
              ingredientsToAdd: [],
              ingredientsToRemove: [],
              quantity: 1,
              isOptionsError,
            },
          ];
          let totalCost = (
            prom.quantity *
            curItems
              .map((el: any) => +el.quantity * +el.totalPrice)
              .reduce((acc: number, sum: number) => +sum + +acc, 0)
          ).toFixed(2);
          if (totalCost.toString().slice(-2) === "00")
            totalCost = totalCost.toString().slice(0, -3);
          return prom.promotionId !== currentPromotionId
            ? prom
            : {
                ...prom,
                discount: selectedPromotion.discount,
                price: selectedPromotion.price,
                totalCost: totalCost,
                items: curItems,
              };
        }),
      };
      newOrder.promotions = newOrder.promotions.map((el) => {
        const itemsPricesSum = el.items
          .map((item: any) => item.totalPrice)
          .reduce((acc: number, sum: number) => +acc + +sum, 0);
        let totalPrice = (itemsPricesSum - el.discount).toFixed(2);
        if (totalPrice.toString().slice(-2) === "00")
          totalPrice = totalPrice.toString().slice(0, -3);
        return {
          ...el,
          totalCost: el.price
            ? el.price
            : itemsPricesSum - el.discount >= 0
            ? +totalPrice * el.quantity
            : 0,
        };
      });
      setOrder(newOrder);
      setCurrentProductIndex(0);
      setCurrentProductIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, products, currentProductIndex, promotions]);

  const classes = useStyles();

  const updateOrderData = (withAddress: boolean) =>
    API.get(`/orders/${orderId}`)
      .then(({ data }: { data: OrderView }) => {
        setOldIsNewAddress(false);
        setOldOrder(data);
        setOrder(data);
        setReceivingType(data.deliveryAddress ? "delivery" : "pickup");
        setOldReceivingType(data.deliveryAddress ? "delivery" : "pickup");
        const orderBlockData = {
          readyTime: data.readyTime,
          adminCommentary: data.adminCommentary,
          spentTickets: data.spentTickets,
          shopId: data.shop ? data.shop.id : undefined,
          addressId: data.deliveryAddress?.id,
        };
        setOrderBlock(orderBlockData);
        setOldOrderBlock(orderBlockData);
        if (!data.deliveryAddress) {
          setCurrentAddressShopId(0);
          setOldCurrentAddressShopId(0);
        }
        if (withAddress) {
          const addressBlockData = data.deliveryAddress
            ? {
                customerId: data.customer.id,
                orderId: data.id,
                addressComment: data.deliveryAddress.addressComment,
                apartment: data.deliveryAddress.apartment
                  ? +data.deliveryAddress.apartment
                  : undefined,
                code: data.deliveryAddress.code
                  ? +data.deliveryAddress.code
                  : undefined,
                entrance: data.deliveryAddress.entrance
                  ? +data.deliveryAddress.entrance
                  : undefined,
                floor: data.deliveryAddress.floor
                  ? +data.deliveryAddress.floor
                  : undefined,
                house: data.deliveryAddress.house
                  ? data.deliveryAddress.house
                  : "",
                isAddressError: false,
                kladrId: +data.deliveryAddress.kladrId,
                kladrObjectCoordinateId: undefined,
              }
            : null;
          setIsNewAddress(false);
          setAddressBlock(addressBlockData);
          setOldAddressBlock(addressBlockData);
          API.get(`/customers/${data.customer.id}/addresses`)
            .then(({ data }) => {
              setAddressesList(data);
            })
            .catch((error) => {
              alertError(alertContext, "Ошибка получения адресов");
            });
        }
      })
      .catch((error) => alertError(alertContext, "Ошибка получения заказа"));

  const onOrderEdit = (
    orderData: OrderMainEdit,
    isNeedToUpdateAddressInfo?: boolean
  ) =>
    API.patch(`/orders/${order.id}`, {
      ...orderData,
      addressId: isNeedToUpdateAddressInfo
        ? orderData.addressId
        : oldOrder.deliveryAddress?.id,
    })
      .then(({ data }) => {
        updateOrderData(
          isNeedToUpdateAddressInfo !== undefined
            ? isNeedToUpdateAddressInfo
            : false
        );
        setOldReceivingType(receivingType);
        setOldAsapDelivery(asapDelivery);
        alertSuccess(alertContext, "Успешное редактирование заказа");
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка редактирования заказа");
      })
      .finally(() => setProgress(false));

  const onPaymentEdit = (paymentData: PaymentEdit) =>
    API.patch(`/orders/${order.id}/payment`, paymentData)
      .then(({ data }) => {
        updateOrderData(false);
        setOldPaymentBlock(paymentBlock);
        alertSuccess(alertContext, "Успешное редактирование блока оплаты");
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка редактирования блока оплаты");
      })
      .finally(() => setProgress(false));

  const onItemsEdit = (itemsData: ItemsEdit) =>
    API.patch(`/orders/${order.id}/items`, itemsData)
      .then(({ data }) => {
        updateOrderData(false);
        updateOrdersList();
        alertSuccess(alertContext, "Успешное редактирование блока продуктов");
      })
      .catch((error) => {
        alertError(alertContext, "Ошибка редактирования блока продуктов");
      })
      .finally(() => setProgress(false));

  const getItems = (
    items: {
      id: number;
      ingredientsToAdd?: {
        name: string;
        quantity: number;
        ingredientId: number;
      }[];
      ingredientsToRemove?: {
        name: string;
        quantity: number;
        ingredientId: number;
      }[];
      name: string;
      options: { [id: string]: string[] };
      productId: number;
      variantId: number;
      quantity: number;
      totalPrice: number | string;
    }[]
  ) => {
    const result = items.map((el) => {
      return {
        productId: el.productId,
        quantity: el.quantity,
        variantId: el.options ? +Object.keys(el.options)[0] : undefined,
        ingredientsToRemove: el.ingredientsToRemove
          ? el.ingredientsToRemove.map((el) => el.ingredientId)
          : undefined,
        ingredientsToAdd: el.ingredientsToAdd
          ? el.ingredientsToAdd.map((ing) => {
              return {
                ingredientId: ing.ingredientId,
                quantity: ing.quantity,
              };
            })
          : undefined,
      };
    });
    return result;
  };

  const isOrderEditDisabled = () =>
    progress ||
    order.shop === undefined ||
    (wrongReadyTime && !asapDelivery) ||
    (orderBlock &&
      !asapDelivery &&
      new Date(orderBlock.readyTime) < new Date());

  const getOrderStatusLabel = (row: OrderView) => (
    <span
      className={[
        classes.label,
        row.status.id === 0
          ? classes.labelCreated
          : row.status.id === 1
          ? classes.labelConfirmed
          : row.status.id === 2
          ? classes.labelProcess
          : "",
      ].join(" ")}
    >
      {row.status.description}
    </span>
  );

  const selectProduct = (type: string, key: number, index?: number) => {
    if (index !== undefined) {
      setSelectedPromotionIndex(index);
    }
    const curItem =
      index !== undefined
        ? order.promotions[index].items[key]
        : order.items[key];
    const variants = products.find((el) => el.id === curItem.productId)!
      .variants;
    setCurrentItem({ ...curItem, variants, index: key });
    setCurrentType(type);
    setCurrentItemOpen(true);
  };

  const getStatusLabel = () =>
    order.status.id === 0
      ? "Подтвердить заказ"
      : order.status.id === 1
      ? "Заказ в работе"
      : order.status.id === 2
      ? "Заказ выполнен"
      : "";

  const getOrderAction = () =>
    order.status.id === 0
      ? "confirm"
      : order.status.id === 1
      ? "handle"
      : order.status.id === 2
      ? "complete"
      : "";

  const getIngredients = (itemIndex: number, promotionIndex?: number) => {
    const items =
      promotionIndex !== undefined
        ? order.promotions[promotionIndex].items[itemIndex]
        : order.items[itemIndex];
    const ingredientsToAdd =
      items && items.ingredientsToAdd && items.ingredientsToAdd.length > 0
        ? items.ingredientsToAdd
            .map((el) =>
              el.name.length > 0 ? `${el.name} (${el.quantity})` : "-"
            )
            .join(", ")
        : "-";
    const ingredientsToRemove =
      items && items.ingredientsToRemove && items.ingredientsToRemove.length > 0
        ? items.ingredientsToRemove.map((el: any) => el.name).join(", ")
        : "-";
    return { ingredientsToAdd, ingredientsToRemove };
  };

  const isAddressBlockEmpty = () =>
    addressBlock?.kladrId === 0 ||
    addressBlock?.house === "" ||
    addressBlock?.house === "0" ||
    addressBlock?.apartment === 0 ||
    (currentAddressShopId === 0 &&
      order.deliveryAddress &&
      order.deliveryAddress.id !== undefined);

  const getShopLabel = (shop: any) => {
    if (!shop.isLocalPickup) return `${shop.name} (самовывоз недоступен)`;
    if (shop.isAroundTheClockPickup) return `${shop.name} (круглосуточно)`;
    const workingDays =
      shop[receivingType === "delivery" ? "deliveryWorking" : "pickupWorking"]
        .workingDays;
    const currentDayWorkingTime = workingDays.find(
      (el: any) => el.dayOfWeek === new Date().getDay()
    )!.workingTime;
    const timeFrom = new Date(currentDayWorkingTime[0].timeFrom);
    const timeTill = new Date(currentDayWorkingTime[0].timeTill);
    const timeFromStr = renderDate(timeFrom.toISOString(), true, true).slice(
      0,
      -3
    );
    const timeTillStr = renderDate(timeTill.toISOString(), true, true).slice(
      0,
      -3
    );
    return `${shop.name} (${timeFromStr} - ${timeTillStr})`;
  };

  const isAnyItemOptionError = () => {
    const itemsError = !!order.items.find((el) => el.isOptionsError === true);
    const isPromItemsError = !!order.promotions.find((prom) =>
      prom.items.find((item) => item.isOptionsError === true)
    );
    return itemsError || isPromItemsError;
  };

  const isPromotionsWrong = () => {
    let result = false;
    if (
      !order.promotions ||
      order.promotions.length < 1 ||
      promotions.length < 1
    )
      return false;
    for (let i = 0; i < order.promotions.length; i++) {
      let selectedPromotion = promotions.find(
        (el) => el.id === order.promotions[i].promotionId
      );
      if (!selectedPromotion) return false;
      let expectedSetsList = promotions.find(
        (el) => el.id === order.promotions[i].promotionId
      )!.productSetsList;
      let currentSetsList = copyObject(
        expectedSetsList.map((el) => {
          return { ...el, count: 0 };
        })
      );
      order.promotions[i].items.forEach((item) => {
        currentSetsList = addProductToProductSet(
          item,
          item.quantity,
          expectedSetsList,
          currentSetsList,
          expectedSetsList.map((el) => !el)
        );
      });
      const expectedSetsListCounts = expectedSetsList.map((el) => el.count);
      const currentSetsListCounts = currentSetsList.map((el: any) => el.count);
      if (
        JSON.stringify(expectedSetsListCounts) !==
        JSON.stringify(currentSetsListCounts)
      )
        result = true;
      else if (order.promotions) {
        let items: any[] = [];
        for (let k = 0; k < promotions.length; k++) {
          if (order.promotions[k])
            items = [...items, ...order.promotions[k].items];
        }
        const countOfProducts = items
          .map((el) => el.quantity)
          .reduce((acc, sum) => sum + acc, 0);

        if (
          countOfProducts !==
          currentSetsListCounts.reduce((acc: any, sum: any) => sum + acc, 0)
        )
          result = true;
      }
    }
    return result;
  };

  const isItemsOrPromotionsEmpty = () => {
    let result = false;
    if (order.promotions.length === 0) {
      if (order.items.length === 0) result = true;
    } else {
      result =
        order.promotions.filter((el) => el.items.length > 0).length !==
        order.promotions.length;
    }
    return result;
  };

  useEffect(() => {
    if (!order.shop) {
      setMinMaxIntervals([]);
    }
    if (
      order &&
      order.shop &&
      order.shop.id &&
      order.shop.id !== 0 &&
      shops.length > 0
    ) {
      setMinMaxIntervals(getShopIntervals(receivingType, shops, order.shop));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.shop, receivingType, shops]);

  useEffect(() => {
    setIsOrderEditAvailable(
      !(order.status.id !== 0 || (order && order.paymentStatus)) &&
        !user?.roles.includes("Operator")
    );
    if (orderBlock) {
      let intervalsContainsTime = false;
      let time = new Date(orderBlock.readyTime);
      for (let i = 0; i < minMaxIntervals.length; i++) {
        if (
          time.getTime() + 1000 >= minMaxIntervals[i].min.getTime() &&
          time.getTime() <= minMaxIntervals[i].max.getTime()
        )
          intervalsContainsTime = true;
      }
      const currentShop = shops.find((el) => el.id === order.shop?.id);
      const isAroundTheClock =
        (receivingType === "delivery" &&
          currentShop?.isAroundTheClockDelivery) ||
        (receivingType === "pickup" && currentShop?.isAroundTheClockPickup);

      setWrongReadyTime(!intervalsContainsTime && !isAroundTheClock);
    }
  }, [
    shops,
    minMaxIntervals,
    orderBlock,
    order.shop,
    order,
    receivingType,
    user,
  ]);

  const getPromotionPrice = (promotion: OrderCommonPromotion) => {
    let formattedPromotion = promotion;
    if (promotions.length > 0 && products.length > 0 && orderBlock) {
      const selectedPromotion = promotions.find(
        (el) => el.id === promotion.promotionId
      )!;
      if (selectedPromotion && selectedPromotion?.discount) {
        const promotionSets = selectedPromotion.productSetsList;
        let items = promotion.items;
        items = items.map((el) => {
          const productVariants = el.options ? Object.keys(el.options) : [];
          const selectedProduct = products.find(
            (product) => product.id === el.productId
          )!;
          let selectedSetIndex = -1;
          promotionSets.filter((el, index) => {
            if (
              el.productIds.includes(selectedProduct.id) &&
              (productVariants.every((pVar) => el.variantIds.includes(+pVar)) ||
                productVariants.length === 0)
            )
              selectedSetIndex = index;
            return el;
          });
          return {
            ...el,
            totalPrice:
              selectedSetIndex > -1 && promotionSets[selectedSetIndex].isFree
                ? 0
                : el.totalPrice,
          };
        });
        const itemsPricesSum = items
          .map((item: any) => {
            return { totalPrice: item.totalPrice, quantity: item.quantity };
          })
          .reduce(
            (acc: number, sum: { totalPrice: number; quantity: number }) =>
              +acc + +sum.totalPrice,
            0
          );
        let totalCost: number | string = (
          promotion.quantity *
          (itemsPricesSum - selectedPromotion.discount!)
        ).toFixed(2);
        if (totalCost.toString().slice(-2) === "00")
          totalCost = totalCost.toString().slice(0, -3);
        totalCost = +totalCost >= 0 ? totalCost : 0;
        formattedPromotion.totalCost = totalCost;
      } else {
        formattedPromotion.totalCost = +selectedPromotion.price!;
      }
    }
    return formattedPromotion.totalCost === undefined
      ? "-"
      : +formattedPromotion.totalCost === 0
      ? "Бесплатно"
      : `${formattedPromotion.totalCost} ₽`;
  };

  const isAddressAlreadyExist = (addressBlockData?: any, orderData?: any) => {
    const addressVariable = addressBlockData ? addressBlockData : addressBlock;
    const orderVariable = orderData ? orderData : order;
    let result = false;
    const street = orderVariable.deliveryAddress.street.toLowerCase();
    addressesList.forEach((address) => {
      if (addressVariable && orderVariable.deliveryAddress) {
        if (
          address.street.toLowerCase().trim() === street &&
          address.house === addressVariable.house &&
          +address.apartment === +addressVariable.apartment
        ) {
          result = true;
        }
      }
    });
    return result;
  };

  const getPromotionItemName = (product: any) => {
    if (promotionCategories.length < 1) return product.name;
    else {
      const selectedPromotion = promotionCategories.find(
        (el: any) => el.id === product.promotionCategoryId
      );
      if (!selectedPromotion) return product.name;
      const selectedPromotionCategoryName = selectedPromotion!.name;
      let result = `${product.name} (${selectedPromotionCategoryName})`;
      if (result.split(selectedPromotionCategoryName).length > 2) {
        const indexOf = result.lastIndexOf(selectedPromotionCategoryName);
        result = result.slice(0, indexOf - 1);
      }
      return result;
    }
  };

  const isAddressEditDisabled = () =>
    progress ||
    isAddressBlockEmpty() ||
    !isAddressBlockChanged() ||
    (currentAddressShopId === 0 && !isNewAddress) ||
    (order.deliveryAddress && order.deliveryAddress.isAddressError) ||
    isOrderEditDisabled();

  const confirmOrder = (data?: { text: string; waitingTime: string }) => {
    setProgress(true);
    API.put(
      `/orders/${order.id}/${getOrderAction()}`,
      getOrderAction() === "handle" && data
    )
      .then(() => {
        alertSuccess(
          alertContext,
          getOrderAction() === "confirm"
            ? "Заказ подтвержден"
            : getOrderAction() === "handle"
            ? "Заказ в работе"
            : "Заказ выполнен"
        );
        updateOrdersList();
        API.get(`/orders/${orderId}`)
          .then(({ data }: { data: OrderView }) => {
            setOldOrder(data);
            setOrder(data);
            // setAlerted(false);
          })
          .catch((error) =>
            alertError(alertContext, "Ошибка получения заказа")
          );
      })
      .catch((error) =>
        alertError(
          alertContext,
          getOrderAction() === "confirm"
            ? "Ошибка подтверждения заказа"
            : getOrderAction() === "handle"
            ? "Ошибка принятия заказа в работу"
            : "Ошибка выполнения заказа"
        )
      )
      .finally(() => setProgress(false));
  };

  const onConfirmBtnClick = (e: any) => {
    e.preventDefault();
    order.status.id === 1 ? setOrderInWorkModal(true) : confirmOrder();
  };

  const isAsapPicked = () =>
    onlyAsapPickupAvailable() ? onlyAsapPickupAvailable() : asapDelivery;

  return (
    <div className={classes.wrapper}>
      <Dialog
        disableBackdropClick
        onBackdropClick={() =>
          progress
            ? null
            : isOrderBlockChanged() ||
              isAddressBlockChanged() ||
              isPaymentBlockChanged()
            ? setConfirmWindowOpen(true)
            : onClose()
        }
        open={orderId !== undefined}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={"lg"}
      >
        <DialogContent className={classes.dialogContent}>
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog
            disableBackdropClick
            open={isCancelConfirm}
            onBackdropClick={() => {
              setCancelReason("");
              setIsCancelConfirm(false);
            }}
            aria-labelledby="form-dialog-title"
            maxWidth={"sm"}
            fullWidth
          >
            <DialogTitle id="form-dialog-title">
              Подтверждение отмены
            </DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                id="description"
                label="Причина отмены (необязательно)"
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
                type="text"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setCancelReason("");
                  setIsCancelConfirm(false);
                }}
                color="primary"
              >
                Отмена
              </Button>
              <Button
                onClick={() => {
                  setProgress(true);
                  let url = `/orders/${order.id}/cancel`;
                  if (cancelReason.length > 0)
                    url += `?comment=${cancelReason}`;
                  API.put(url)
                    .then(() => {
                      alertSuccess(alertContext, "Заказ отменен");
                      updateOrdersList();
                      setCancelReason("");
                      setIsCancelConfirm(false);
                      API.get(`/orders/${orderId}`)
                        .then(({ data }: { data: OrderView }) => {
                          setOldOrder(data);
                          setOrder(data);
                        })
                        .catch((error) =>
                          alertError(alertContext, "Ошибка получения заказа")
                        );
                    })
                    .catch((error) =>
                      alertError(alertContext, "Ошибка отмены заказа")
                    )
                    .finally(() => setProgress(false));
                }}
                color="primary"
                type="submit"
              >
                Подтвердить
              </Button>
            </DialogActions>
          </Dialog>
          <OrderInWorkDialog
            open={orderInWorkModal}
            data={{
              readyTime: order.readyTime,
              orderNumber: order.id,
              amountToBePaid: order.amountToBePaid,
              receivingType,
            }}
            onClose={() => setOrderInWorkModal(false)}
            onConfirm={(data) => {
              confirmOrder(data);
              setOrderInWorkModal(false);
            }}
          />
          <Dialog
            open={confirmWindowOpen}
            // @ts-ignore
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setConfirmWindowOpen(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                style={{ marginBottom: 0 }}
              >
                Вы действительно хотите отменить все изменения и выйти?
                Введенные данные будут утеряны
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose();
                  setConfirmWindowOpen(false);
                }}
                color="primary"
              >
                Да
              </Button>
              <Button
                onClick={() => {
                  setConfirmWindowOpen(false);
                }}
                color="primary"
              >
                Нет
              </Button>
            </DialogActions>
          </Dialog>
          {order.shop && shops.length ? (
            <TimeSelect
              type={receivingType}
              shop={shops.find((el) => el.id === order.shop?.id)}
              isOpen={timeSelectIsOpen}
              setTime={(data: Date | "как можно быстрее") => {
                setTimeSelectIsOpen(false);
                if (data === "как можно быстрее") {
                  const time = moment()
                    .add(receivingType === "delivery" ? 90 : 20, "minutes")
                    .toDate();
                  const date = time.toISOString().slice(0, -1);
                  setWrongReadyTime(false);
                  setOrderBlock({
                    ...orderBlock!,
                    readyTime: date,
                  });
                } else {
                  const time = new Date(data.getTime() + 3 * 60 * 60 * 1000);
                  const date = time.toISOString().slice(0, -1);
                  setWrongReadyTime(false);
                  setOrderBlock({
                    ...orderBlock!,
                    readyTime: date,
                  });
                }
              }}
              onClose={() => setTimeSelectIsOpen(false)}
            />
          ) : (
            <></>
          )}
          <form>
            <div className={classes.MainInfoBlock}>
              <Paper elevation={2} className={classes.paper}>
                <h2 className={classes.dialogTitle}>Редактирование заказа</h2>
                <div className={classes.orderHeader}>
                  <span>
                    Заказ № {order.id}
                    {order.createdAt &&
                      ` от ${getCreatedDate(order.createdAt)}`}
                  </span>
                  <span>{getOrderStatusLabel(order)}</span>
                </div>
                <div className={classes.client}>
                  {getFormattedPhoneNumber(order.customer.phoneNumber)}
                </div>
                <br />
                <div>
                  <Select
                    disabled={!isOrderEditAvailable}
                    className={classes.shopSelect}
                    value={order.shop ? order.shop.id : 0}
                    onChange={(e: any) => {
                      setOrder({
                        ...order,
                        shop: {
                          id: e.target.value,
                          name: shops.find((el) => el.id === e.target.value)!
                            .name,
                        },
                      });
                    }}
                  >
                    <MenuItem value={0} disabled>
                      Пиццерия
                    </MenuItem>
                    {shops.map((c, idx) => (
                      <MenuItem
                        key={idx}
                        value={c.id}
                        disabled={
                          getShopIntervals(receivingType, shops, {
                            id: +c.id!,
                            name: "",
                          }).length < 1
                        }
                      >
                        {getShopLabel(c)}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {order.deliveryAddress?.deliveryAddress &&
                  receivingType === "delivery" && (
                    <div className={classes.deliveryAddress}>
                      <span>Доставить по адресу:</span>&nbsp;
                      <span className={classes.bold}>
                        {order.deliveryAddress.deliveryAddress}
                      </span>
                    </div>
                  )}
                {order.orderComment && (
                  <div>
                    <span>Комментарий к заказу: </span>
                    <span className={classes.bold}>{order.orderComment}</span>
                  </div>
                )}
                <div>
                  <span>Оплата: </span>
                  <span className={classes.paymentType}>
                    {order.paymentType.description}
                  </span>
                </div>
                {order.paymentType.isOnline && (
                  <div>
                    <span>Статус платежа: </span>
                    <span className={classes.bold}>
                      {order.paymentStatus ? "Оплачено" : "Ожидается оплата"}
                    </span>
                    {order.paymentType.isOnline && !order.paymentStatus && (
                      <>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControlLabel
                          control={
                            <IconButton
                              size={"small"}
                              onClick={() => {
                                API.get(
                                  `/orders/${order.id}/paymentStatus`
                                ).then((data) => updateOrderData(false));
                              }}
                            >
                              <RefreshIcon />
                            </IconButton>
                          }
                          label=""
                        />
                      </>
                    )}
                  </div>
                )}
                {order.payment &&
                  order.payment.externalPaymentId &&
                  order.paymentStatus && (
                    <div>
                      <span>Идентификатор платежа: </span>
                      <span className={classes.bold}>
                        #{order.payment.externalPaymentId}
                      </span>
                    </div>
                  )}
                <div>
                  <span>К оплате: </span>
                  <span>{order.amountToBePaid} &#8381;</span>
                </div>
                {+order.oddMoney > 0 && order.paymentType.id === 1 && (
                  <div>
                    <span>Сдача: </span>
                    <span>{order.oddMoney} &#8381;</span>
                  </div>
                )}
                <div className={classes.adminCommentary}>
                  <span>Комментарий администратора: </span> &nbsp;
                  {adminCommentaryFocus ? (
                    <TextareaAutosize
                      aria-label="empty textarea"
                      placeholder="Empty"
                      ref={adminCommentaryInput}
                      maxLength={100}
                      onBlur={() => setAdminCommentaryFocus(false)}
                      value={orderBlock!.adminCommentary}
                      onChange={(e) => {
                        setOrderBlock({
                          ...orderBlock!,
                          adminCommentary: e.target.value,
                        });
                      }}
                    />
                  ) : (
                    <span
                      style={{
                        cursor: isOrderEditAvailable ? "pointer" : "default",
                      }}
                      className={classes.bold}
                      onClick={() => {
                        if (isOrderEditAvailable) setAdminCommentaryFocus(true);
                      }}
                    >
                      {orderBlock && orderBlock.adminCommentary
                        ? orderBlock.adminCommentary
                        : "-"}
                    </span>
                  )}
                </div>
                <div className={classes.readyTimeBlock}>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    className={classes.radioGroup}
                    value={receivingType}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setReceivingType(
                        e.target.value === "delivery" ? "delivery" : "pickup"
                      );
                      const addressBlockData = order.deliveryAddress
                        ? {
                            customerId: +order.customer.id,
                            orderId: +order.id,
                            isAddressError:
                              order.deliveryAddress?.isAddressError,
                            kladrId: +order.deliveryAddress?.kladrId,
                            apartment: order.deliveryAddress.apartment
                              ? +order.deliveryAddress.apartment
                              : undefined,
                            code: order.deliveryAddress.code
                              ? +order.deliveryAddress.code
                              : undefined,
                            entrance: order.deliveryAddress.entrance
                              ? +order.deliveryAddress.entrance
                              : undefined,
                            floor: order.deliveryAddress.floor
                              ? +order.deliveryAddress.floor
                              : undefined,
                            house: order.deliveryAddress.house
                              ? order.deliveryAddress.house
                              : "",
                            addressComment:
                              order.deliveryAddress?.addressComment,
                          }
                        : {
                            customerId: +order.customer.id,
                            orderId: +order.id,
                            house: "",
                            code: undefined,
                            kladrId: 0,
                            entrance: undefined,
                            apartment: undefined,
                            addressComment: "",
                            isAddressError: false,
                            floor: undefined,
                          };
                      setAddressBlock(addressBlockData);
                      setOldAddressBlock(addressBlockData);
                      const orderData = {
                        ...order,
                        deliveryAddress:
                          e.target.value === "delivery"
                            ? oldOrder.deliveryAddress
                              ? oldOrder.deliveryAddress
                              : {
                                  addressComment: "",
                                  apartment: "",
                                  city: "",
                                  code: "",
                                  kladrId: "",
                                  deliveryAddress: "",
                                  entrance: "",
                                  floor: "",
                                  house: "",
                                  id: undefined,
                                  isAddressError: false,
                                  street: "",
                                  streetType: "",
                                }
                            : undefined,
                      };
                      setOrder({
                        ...orderData,
                        shop:
                          e.target.value === "delivery"
                            ? oldOrder.shop
                            : undefined,
                      });
                      setOldOrder(orderData);
                    }}
                  >
                    <FormControlLabel
                      value={"delivery"}
                      control={<Radio />}
                      className={
                        receivingType === "pickup" ? classes.disabledColor : ""
                      }
                      disabled={
                        !isOrderEditAvailable && receivingType === "pickup"
                      }
                      label="Доставить к: "
                    />
                    <FormControlLabel
                      value={"pickup"}
                      control={<Radio />}
                      className={
                        receivingType === "delivery"
                          ? classes.disabledColor
                          : ""
                      }
                      disabled={
                        !isOrderEditAvailable && receivingType === "delivery"
                      }
                      label="Самовывоз в: "
                    />
                  </RadioGroup>
                  {orderBlock && (
                    <div
                      className={[classes.pointer, classes.readyTime].join(
                        ", "
                      )}
                    >
                      {!isAsapPicked() && !onlyAsapPickupAvailable() && (
                        <span
                          className={classes.bold}
                          style={{
                            cursor:
                              asapDelivery || !isOrderEditAvailable
                                ? "default"
                                : "pointer",
                            color: asapDelivery
                              ? "rgba(0, 0, 0, 0.38)"
                              : "rgba(0, 0, 0, 0.87)",
                          }}
                          onClick={() =>
                            asapDelivery || !isOrderEditAvailable
                              ? null
                              : setTimeSelectIsOpen(true)
                          }
                        >
                          <span>
                            {renderDate(
                              orderBlock.readyTime,
                              false,
                              true
                            ).slice(0, 10)}
                          </span>
                          &nbsp;
                          <span>
                            {renderDate(
                              orderBlock.readyTime,
                              false,
                              true
                            ).slice(-6)}
                          </span>
                        </span>
                      )}
                      {!isAsapPicked() &&
                        wrongReadyTime &&
                        isOrderEditAvailable &&
                        !asapDelivery &&
                        (!onlyAsapPickupAvailable() ||
                          receivingType === "delivery") && (
                          <span className={classes.requiredField}>
                            &nbsp; пиццерия не работает
                          </span>
                        )}
                      {(!isAsapPicked() && !isOrderEditAvailable) ||
                      receivingType !== "delivery" ? null : (
                        <span>
                          <FormControl
                            style={{ marginLeft: 15, color: "#215868" }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isAsapPicked()}
                                  onChange={(e) => {
                                    if (!onlyAsapPickupAvailable()) {
                                      if (
                                        orderBlock &&
                                        minMaxIntervals.length > 0
                                      ) {
                                        let result = false;
                                        let time = new Date(
                                          orderBlock.readyTime
                                        ).getTime();
                                        for (
                                          let i = 0;
                                          i < minMaxIntervals.length;
                                          i++
                                        ) {
                                          if (
                                            time >=
                                              minMaxIntervals[
                                                i
                                              ].min.getTime() &&
                                            time <=
                                              minMaxIntervals[i].max.getTime()
                                          )
                                            result = true;
                                        }
                                        if (result) setWrongReadyTime(false);
                                      }
                                      setAsapDelivery(!asapDelivery);
                                    }
                                  }}
                                  name="checkedB"
                                  color="primary"
                                  disabled={
                                    minMaxIntervals.length < 1 ||
                                    !isOrderEditAvailable
                                  }
                                />
                              }
                              label="Как можно быстрее"
                            />
                          </FormControl>
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <br />
                <DialogActions className={classes.dialogActions}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setProgress(true);
                      if (orderBlock && order.shop) {
                        onOrderEdit({
                          shopId: order.shop.id,
                          addressId: order.deliveryAddress
                            ? order.deliveryAddress.id
                            : undefined,
                          adminCommentary: orderBlock.adminCommentary,
                          // @ts-ignore
                          readyTime: asapDelivery ? null : orderBlock.readyTime,
                          spentTickets: orderBlock.spentTickets,
                          receivingMethodId: order.deliveryAddress ? 1 : 0,
                        });
                      }
                    }}
                    color="primary"
                    type="submit"
                    disabled={
                      isOrderEditDisabled() ||
                      !isOrderEditAvailable ||
                      minMaxIntervals.length < 1 ||
                      (receivingType === "delivery" &&
                        (!order.deliveryAddress ||
                          (order.deliveryAddress &&
                            order.deliveryAddress.id === undefined))) ||
                      !isOrderBlockChanged()
                    }
                  >
                    Применить
                  </Button>
                  <span>
                    {order.status.id !== 3 && order.status.id !== 4 && (
                      <Button
                        className={classes.colorRed}
                        disabled={progress}
                        onClick={() => setIsCancelConfirm(true)}
                      >
                        Отменить заказ
                      </Button>
                    )}
                    <Button
                      onClick={onConfirmBtnClick}
                      color="primary"
                      type="submit"
                      disabled={progress}
                    >
                      {getStatusLabel()}
                    </Button>
                  </span>
                </DialogActions>
              </Paper>
            </div>
            <div className={classes.accordionWrapper}>
              {order.deliveryAddress &&
              addressBlock &&
              receivingType === "delivery" ? (
                <span className={classes.accordion}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="additional-actions1-content"
                      id="additional-actions1-header"
                    >
                      Адрес
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <FormControl fullWidth>
                        <InputLabel>Тип улицы</InputLabel>
                        <Select
                          disabled
                          value={order.deliveryAddress!.streetType}
                        >
                          {kladrObjectTypes.map((c, idx) => (
                            <MenuItem key={idx} value={c.nameRu}>
                              {c.nameRu}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <br />
                      <br />
                      <div className={classes.fullWidth}>
                        <Autocomplete
                          id="combo-box-demo"
                          disabled={!isOrderEditAvailable}
                          onChange={(
                            event,
                            newValue:
                              | StreetSearchResponse
                              | null
                              | undefined
                              | string
                          ) => {
                            if (newValue && typeof newValue !== "string") {
                              if (newValue.fullName) {
                                const {
                                  id,
                                  kladrId,
                                  klardObjectTypeId,
                                  entrance,
                                  apartment,
                                  code,
                                  commentary,
                                  floor,
                                  house,
                                } = newValue;
                                // const street = newValue.fullName;
                                const street = newValue.street;
                                const addressData = {
                                  id,
                                  kladrObjectCoordinateId: undefined,
                                  isAddressError: false,
                                  customerId: order.customer.id,
                                  orderId: +order.id!,
                                  entrance: +entrance!,
                                  apartment: +apartment!,
                                  code: code ? +code : 0,
                                  floor: +floor!,
                                  house: house!,
                                  addressComment: commentary!,
                                  kladrId: kladrId ? +kladrId : 0,
                                  street: street ? street : "",
                                };
                                setAddressBlock({ ...addressData });
                                // setOldAddressBlock({ ...addressData });
                                setOrder({
                                  ...order,
                                  deliveryAddress: {
                                    ...order.deliveryAddress!,
                                    ...addressData,
                                    isAddressError: false,
                                    entrance: entrance!,
                                    code: code ? code : "0",
                                    addressComment: commentary!,
                                    floor: floor!,
                                    house: house!,
                                    kladrId: String(kladrId),
                                    apartment: apartment!,
                                    street: street ? street : "",
                                    streetType: kladrObjectTypes.find(
                                      (el) => el.id === +klardObjectTypeId!
                                    )!.nameRu,
                                  },
                                });
                                setCurrentAddressShopId(
                                  newValue.shopId ? newValue.shopId : 0
                                );
                                if (
                                  isAddressAlreadyExist(addressData, {
                                    ...order,
                                    deliveryAddress: {
                                      ...order.deliveryAddress!,
                                      ...addressData,
                                      isAddressError: false,
                                      entrance: entrance!,
                                      code: code ? code : "0",
                                      addressComment: commentary!,
                                      floor: floor!,
                                      house: house!,
                                      kladrId: String(kladrId),
                                      apartment: apartment!,
                                      street: street ? street : "",
                                      streetType: kladrObjectTypes.find(
                                        (el) => el.id === +klardObjectTypeId!
                                      )!.nameRu,
                                    },
                                  }) &&
                                  isNewAddress
                                )
                                  setIsNewAddress(false);
                                // setOldOrder({ ...order });
                              } else {
                                setCurrentAddressShopId(0);
                                setIsNewAddress(true);
                                setAddressBlock({
                                  ...addressBlock!,
                                  addressComment: "",
                                  house: "",
                                  apartment: undefined,
                                  entrance: undefined,
                                  floor: undefined,
                                  code: undefined,
                                  kladrId: newValue.id
                                    ? +newValue.id
                                    : undefined,
                                });
                                setOrder({
                                  ...order,
                                  deliveryAddress: {
                                    ...order.deliveryAddress!,
                                    id: undefined,
                                    street: newValue.name!,
                                    streetType: kladrObjectTypes.find(
                                      (el) =>
                                        el.id === newValue.kladrObjectTypeId
                                    )!.nameRu,
                                  },
                                });
                              }
                            }
                          }}
                          value={
                            addressBlock
                              ? {
                                  kladrId: 1,
                                  id: addressBlock.kladrId,
                                  name: order.deliveryAddress!.street,
                                  kladrObjectTypeId: 16,
                                }
                              : {
                                  kladrId: 1,
                                  id: 1,
                                  name: "Улица",
                                  kladrObjectTypeId: 16,
                                }
                          }
                          clearOnBlur
                          onBlur={() => setSearchList([])}
                          freeSolo
                          options={searchList}
                          getOptionLabel={(option: StreetSearchResponse) =>
                            option.fullName ? option.fullName : option.name!
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => {
                                setOrder({
                                  ...order,
                                  deliveryAddress: {
                                    ...order.deliveryAddress!,
                                    street: e.target.value,
                                  },
                                });
                                if (e.target.value.length > 2) {
                                  setSearchList([]);
                                  let streetsList: StreetSearchResponse[] = [];
                                  const url = encodeURI(
                                    `kladrObjectsByLevel?level=10&name=${e.target.value}`
                                  );
                                  API.get(url)
                                    .then(({ data }) => {
                                      streetsList = [...streetsList, ...data];
                                      API.get(
                                        `/customers/${order.customer.id}/addresses`
                                      )
                                        .then(({ data }) => {
                                          setSearchList([
                                            ...streetsList,
                                            ...data,
                                          ]);
                                        })
                                        .catch((error) =>
                                          alertError(
                                            alertContext,
                                            "Ошибка получения адресов клиента"
                                          )
                                        );
                                    })
                                    .catch((error) =>
                                      alertError(
                                        alertContext,
                                        "Ошибка поиска улицы"
                                      )
                                    );
                                } else {
                                  setSearchList([]);
                                }
                              }}
                              label="Улица *"
                            />
                          )}
                        />
                      </div>
                      <br />
                      <div className={classes.fullWidth}>
                        <TextField
                          label="Дом *"
                          disabled={!isOrderEditAvailable}
                          type="text"
                          inputProps={{ maxLength: 10 }}
                          value={addressBlock.house}
                          onChange={(e) => {
                            let newValue = e.target.value;
                            if (newValue.slice(0, 1) === "0")
                              newValue = newValue.slice(1);
                            setAddressBlock({
                              ...addressBlock,
                              house: newValue,
                            });
                          }}
                        />
                        <TextField
                          label="Квартира *"
                          disabled={!isOrderEditAvailable}
                          inputProps={{ maxLength: 10 }}
                          type="text"
                          value={
                            addressBlock.apartment ? addressBlock.apartment : ""
                          }
                          onChange={(e) =>
                            setAddressBlock({
                              ...addressBlock,
                              apartment:
                                e.target.value === " "
                                  ? undefined
                                  : getIntFromString(e.target.value),
                            })
                          }
                        />
                        <TextField
                          label="Подъезд"
                          disabled={!isOrderEditAvailable}
                          inputProps={{ maxLength: 10 }}
                          type="text"
                          value={
                            addressBlock.entrance ? addressBlock.entrance : ""
                          }
                          onChange={(e) =>
                            setAddressBlock({
                              ...addressBlock,
                              entrance:
                                e.target.value === " "
                                  ? undefined
                                  : getIntFromString(e.target.value),
                            })
                          }
                        />
                      </div>
                      <br />
                      <div className={classes.fullWidth}>
                        <TextField
                          label="Код"
                          disabled={!isOrderEditAvailable}
                          inputProps={{ maxLength: 10 }}
                          type="text"
                          value={addressBlock.code ? addressBlock.code : ""}
                          onChange={(e) =>
                            setAddressBlock({
                              ...addressBlock,
                              code:
                                e.target.value === " "
                                  ? undefined
                                  : getIntFromString(e.target.value),
                            })
                          }
                        />
                        <TextField
                          label="Этаж"
                          disabled={!isOrderEditAvailable}
                          inputProps={{ maxLength: 10 }}
                          type="text"
                          value={addressBlock.floor ? addressBlock.floor : ""}
                          onChange={(e) =>
                            setAddressBlock({
                              ...addressBlock,
                              floor:
                                e.target.value === " "
                                  ? undefined
                                  : getIntFromString(e.target.value),
                            })
                          }
                        />
                      </div>
                      <br />
                      <TextField
                        fullWidth
                        disabled={!isOrderEditAvailable}
                        value={addressBlock.addressComment || ""}
                        onChange={(e) =>
                          setAddressBlock({
                            ...addressBlock,
                            addressComment: e.target.value,
                          })
                        }
                        label="Комментарий"
                        multiline
                      />
                      {(!isNewAddress || isNeedToChooseShop) && (
                        <>
                          <br />
                          <br />
                          <br />
                          <Select
                            disabled={!isOrderEditAvailable}
                            fullWidth
                            value={currentAddressShopId}
                            onChange={(e: any) =>
                              setCurrentAddressShopId(e.target.value)
                            }
                          >
                            <MenuItem value={0} disabled>
                              Пиццерия
                            </MenuItem>
                            {shops.map((c, idx) => (
                              <MenuItem
                                key={idx}
                                value={c.id}
                                disabled={
                                  getShopIntervals(receivingType, shops, {
                                    id: +c.id!,
                                    name: "",
                                  }).length < 1
                                }
                              >
                                {c.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      )}
                      {isNeedToChooseShop && currentAddressShopId === 0 && (
                        <span className={classes.requiredField}>
                          выберите пиццерию
                        </span>
                      )}
                      <br />
                      <FormControl className={classes.mt20}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isNewAddress}
                              onChange={(e) => {
                                setIsNewAddress(e.target.checked);
                              }}
                              disabled={
                                !isOrderEditAvailable ||
                                isAddressAlreadyExist() ||
                                order.deliveryAddress.id === undefined
                              }
                              color="primary"
                            />
                          }
                          label="Новый адрес"
                        />
                      </FormControl>
                      {oldOrder.deliveryAddress?.isAddressError && (
                        <FormControl className={classes.mt20}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={!isOrderEditAvailable}
                                checked={order.deliveryAddress?.isAddressError}
                                onChange={(e) => {
                                  setOrder({
                                    ...order,
                                    deliveryAddress: {
                                      ...order.deliveryAddress!,
                                      isAddressError: !order.deliveryAddress
                                        ?.isAddressError,
                                    },
                                  });
                                }}
                                color="primary"
                              />
                            }
                            label="Ошибка адреса"
                          />
                        </FormControl>
                      )}
                      <DialogActions className={classes.dialogActions}>
                        <span />
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            if (order.deliveryAddress && addressBlock) {
                              setProgress(true);
                              if (
                                order.deliveryAddress &&
                                addressBlock &&
                                order.shop &&
                                orderBlock
                              ) {
                                setProgress(true);
                                const addressData = {
                                  customerId: addressBlock.customerId,
                                  orderId: addressBlock.orderId,
                                  kladrId: addressBlock.kladrId
                                    ? addressBlock.kladrId
                                    : undefined,
                                  house: addressBlock.house,
                                  entrance: addressBlock.entrance
                                    ? addressBlock.entrance
                                    : undefined,
                                  floor: addressBlock.floor
                                    ? addressBlock.floor
                                    : undefined,
                                  apartment: addressBlock.apartment,
                                  shopId:
                                    currentAddressShopId !== 0
                                      ? currentAddressShopId
                                      : undefined,
                                  code: addressBlock.code
                                    ? addressBlock.code
                                    : undefined,
                                  addressComment: addressBlock.addressComment,
                                  isAddressError: addressBlock.isAddressError,
                                };
                                if (
                                  !isOrderEditDisabled() ||
                                  minMaxIntervals.length < 1
                                ) {
                                  if (orderBlock && order.shop) {
                                    if (isNewAddress) {
                                      API.post(`/addresses`, {
                                        ...addressData,
                                        house: addressBlock.house
                                          ? +addressBlock.house
                                          : undefined,
                                      })
                                        .then(({ data }) => {
                                          const addressId = data;
                                          API.get(
                                            `/customers/${order.customer.id}/addresses`
                                          )
                                            .then(({ data }) => {
                                              const currentAddress = data.find(
                                                (el: any) => el.id === addressId
                                              )!;
                                              if (currentAddress.shopId) {
                                                setIsNeedToChooseShop(false);
                                                alertSuccess(
                                                  alertContext,
                                                  "Успешное редактирование адреса заказа"
                                                );
                                                onOrderEdit(
                                                  {
                                                    shopId:
                                                      currentAddressShopId !== 0
                                                        ? currentAddressShopId
                                                        : undefined,
                                                    addressId: addressId!,
                                                    adminCommentary:
                                                      orderBlock.adminCommentary,
                                                    // @ts-ignore
                                                    readyTime: asapDelivery
                                                      ? null
                                                      : orderBlock.readyTime,
                                                    spentTickets:
                                                      orderBlock.spentTickets,
                                                    receivingMethodId: order.deliveryAddress
                                                      ? 1
                                                      : 0,
                                                  },
                                                  true
                                                );
                                                setCurrentAddressShopId(
                                                  currentAddress.shopId
                                                );
                                                setOldCurrentAddressShopId(
                                                  currentAddress.shopId
                                                );
                                              } else {
                                                setIsNeedToChooseShop(true);
                                                setIsNewAddress(false);
                                                setCurrentAddressShopId(0);
                                                setOrder({
                                                  ...order,
                                                  deliveryAddress: {
                                                    ...order.deliveryAddress!,
                                                    id: addressId,
                                                  },
                                                });
                                              }
                                            })
                                            .catch((error) => {
                                              alertError(
                                                alertContext,
                                                "Ошибка получения списка адресов"
                                              );
                                            });
                                        })
                                        .catch((error) => {
                                          alertError(
                                            alertContext,
                                            "Ошибка редактирования адреса заказа"
                                          );
                                        })
                                        .finally(() => setProgress(false));
                                    } else {
                                      API.patch(
                                        `/addresses/${
                                          order.deliveryAddress!.id
                                        }`,
                                        addressData
                                      )
                                        .then(({ data }) => {
                                          setIsNeedToChooseShop(false);
                                          onOrderEdit(
                                            {
                                              shopId: currentAddressShopId,
                                              addressId: order.deliveryAddress!
                                                .id,
                                              adminCommentary:
                                                orderBlock.adminCommentary,
                                              // @ts-ignore
                                              readyTime: asapDelivery
                                                ? null
                                                : orderBlock.readyTime,
                                              spentTickets:
                                                orderBlock.spentTickets,
                                              receivingMethodId: order.deliveryAddress
                                                ? 1
                                                : 0,
                                            },
                                            true
                                          );

                                          setOldCurrentAddressShopId(
                                            currentAddressShopId
                                          );
                                        })
                                        .catch((error) => {
                                          alertError(
                                            alertContext,
                                            "Ошибка редактирования адреса заказа"
                                          );
                                        })
                                        .finally(() => setProgress(false));
                                    }
                                  }
                                }
                              }
                            }
                          }}
                          color="primary"
                          type="submit"
                          disabled={
                            !isOrderEditAvailable ||
                            isAddressEditDisabled() ||
                            (isAddressAlreadyExist() && isNewAddress)
                          }
                        >
                          Применить
                        </Button>
                      </DialogActions>
                    </AccordionDetails>
                  </Accordion>
                </span>
              ) : null}
              <span className={classes.accordion}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                  >
                    {paymentBlock && (
                      <div className={classes.accordionSummary}>
                        <span>Оплата</span>&nbsp;
                        <span>
                          {(
                            order.totalPrice - paymentBlock.spentTickets
                          ).toFixed(2)}
                          ₽ {order.paymentType.description}
                        </span>
                        <span>
                          Потраченные тикеты:{" "}
                          {paymentBlock ? paymentBlock.spentTickets : "?"}
                        </span>
                      </div>
                    )}
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <div className={classes.fullWidth}>
                      <TextField
                        className={classes.darkerDisabled}
                        disabled
                        id="price"
                        label="Сумма заказа"
                        value={`${order.totalPrice} ₽`}
                      />
                      {paymentBlock && (
                        <TextField
                          className={
                            order.status.id !== 0 || !isOrderEditAvailable
                              ? classes.darkerDisabled
                              : ""
                          }
                          disabled={
                            order.status.id !== 0 || !isOrderEditAvailable
                          }
                          label="Потраченные тикеты *"
                          inputProps={{ maxLength: 5 }}
                          type="text"
                          value={paymentBlock.spentTickets}
                          onChange={(e) => {
                            if (e.target.value === " ") return null;
                            if (
                              getIntFromString(e.target.value) <
                              order.totalPrice
                            )
                              setPaymentBlock({
                                ...paymentBlock!,
                                spentTickets: getIntFromString(e.target.value),
                              });
                          }}
                        />
                      )}
                    </div>
                    <br />
                    {paymentBlock && (
                      <div className={classes.fullWidth}>
                        <TextField
                          className={classes.darkerDisabled}
                          disabled
                          id="sumToPaid"
                          label="Сумма к оплате"
                          value={`${(
                            order.totalPrice - paymentBlock?.spentTickets
                          ).toFixed(2)} ₽`}
                        />
                        <TextField
                          className={classes.darkerDisabled}
                          disabled
                          id="tickets"
                          label="Бонусы"
                          value={order.accruedTickets}
                        />
                      </div>
                    )}
                    {paymentBlock && <br />}
                    <div className={classes.fullWidth}>
                      {paymentBlock && (
                        <>
                          <FormControl>
                            <InputLabel>Способ оплаты *</InputLabel>
                            <Select
                              className={
                                order.status.id !== 0 || !isOrderEditAvailable
                                  ? classes.darkerDisabled
                                  : ""
                              }
                              disabled={
                                order.status.id !== 0 || !isOrderEditAvailable
                              }
                              value={paymentBlock.paymentTypeId}
                              onChange={(e: any) =>
                                setPaymentBlock({
                                  ...paymentBlock!,
                                  paymentTypeId: e.target.value,
                                })
                              }
                            >
                              {paymentsTypes.map((c, idx) => (
                                <MenuItem key={idx} value={c.id}>
                                  {c.description}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </>
                      )}
                      {paymentBlock?.paymentTypeId === 1 && (
                        <>
                          <br />
                          <br />
                        </>
                      )}
                      {paymentBlock?.paymentTypeId === 1 && (
                        <FormControl>
                          <InputLabel>Сдача с (₽) *</InputLabel>
                          <Select
                            value={
                              paymentBlock.oddFrom === 0
                                ? "Без сдачи"
                                : String(paymentBlock.oddFrom)
                            }
                            className={
                              order.status.id !== 0
                                ? classes.darkerDisabled
                                : ""
                            }
                            disabled={order.status.id !== 0}
                            onChange={(e: any) => {
                              setPaymentBlock({
                                ...paymentBlock!,
                                oddFrom:
                                  e.target.value === "Без сдачи"
                                    ? 0
                                    : e.target.value,
                              });
                            }}
                          >
                            {oddFromVariants.map((c, idx) => (
                              <MenuItem key={idx} value={c}>
                                {c}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </div>
                    {paymentBlock?.paymentTypeId === 1 && <br />}
                    {order.payment &&
                      paymentBlock &&
                      order.paymentStatus &&
                      order.payment.externalPaymentId && (
                        <TextField
                          className={classes.darkerDisabled}
                          fullWidth
                          disabled
                          id="paymentId"
                          label="Идентификатор платежа"
                          value={order.payment.externalPaymentId}
                        />
                      )}
                    <br />
                    <br />
                    <DialogActions className={classes.dialogActions}>
                      <span> </span>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          if (paymentBlock) {
                            setProgress(true);
                            onPaymentEdit({
                              addressId: order.deliveryAddress
                                ? order.deliveryAddress.id
                                : undefined,
                              oddFrom:
                                paymentBlock.oddFrom === 0
                                  ? 0
                                  : paymentBlock.oddFrom
                                  ? +paymentBlock.oddFrom
                                  : undefined,
                              paymentTypeId: paymentBlock.paymentTypeId,
                              spentTickets: paymentBlock.spentTickets,
                              receivingMethodId: order.deliveryAddress ? 1 : 0,
                            });
                          }
                        }}
                        disabled={
                          progress ||
                          !isPaymentBlockChanged() ||
                          !isOrderEditAvailable
                        }
                        color="primary"
                        type="submit"
                      >
                        Применить
                      </Button>
                    </DialogActions>
                  </AccordionDetails>
                </Accordion>
              </span>
            </div>
            <TableContainer
              component={Paper}
              className={classes.ProductsInfoBlock}
            >
              <ProductConfiguration
                currentItemOpen={currentItemOpen}
                item={currentItem}
                currentProductVariants={currentProductVariants}
                currentSelectedType={currentType}
                onClose={() => setCurrentItemOpen(false)}
                setCurrentItem={(item) => {
                  if (item) {
                    if (selectedPromotionIndex !== null) {
                      const newItems = order.promotions[
                        selectedPromotionIndex
                      ].items.map((el, index) => {
                        return item.index !== index ? el : item;
                      });
                      let newPromotions = copyObject(order.promotions);
                      const selectedPromotion = promotions.find(
                        (el) =>
                          el.id ===
                          newPromotions[selectedPromotionIndex].promotionId
                      )!;
                      newPromotions[selectedPromotionIndex].items = newItems;
                      newPromotions[
                        selectedPromotionIndex
                      ].totalCost = selectedPromotion.price
                        ? selectedPromotion.price
                        : newPromotions[selectedPromotionIndex].quantity *
                            +newItems
                              .map((el) => el.totalPrice)
                              .reduce((acc, sum) => +acc + +sum, 0) -
                          selectedPromotion.discount!;
                      setOrder({ ...order, promotions: newPromotions });
                    } else {
                      setOrder({
                        ...order,
                        items: order.items.map((el, index) => {
                          return index === item.index ? item : el;
                        }),
                      });
                    }
                    setSelectedPromotionIndex(null);
                  }
                }}
              />
              <Typography
                variant="h6"
                component="div"
                className={classes.tableTitle}
              >
                <p>добавить &nbsp; &nbsp;</p>
                <Select
                  value={0}
                  disabled={!isOrderEditAvailable}
                  labelId="simple-select-city"
                  onChange={(e: any) => {
                    let newOrderItems = order.items;
                    let selectedProduct = products.find(
                      (product) => product.id === e.target.value
                    )!;
                    const isOptionsError = selectedProduct.variants.length > 0;
                    // @ts-ignore
                    newOrderItems = order.items.find(
                      (el) => el.productId === selectedProduct.id
                    )
                      ? order.items.map((el) =>
                          el.productId === selectedProduct.id
                            ? { ...el, quantity: el.quantity + 1 }
                            : el
                        )
                      : [
                          ...newOrderItems,
                          {
                            totalPrice: selectedProduct.price,
                            name: selectedProduct.name,
                            productId: selectedProduct.id,
                            options: undefined,
                            ingredientsToAdd: [],
                            ingredientsToRemove: [],
                            quantity: 1,
                            isOptionsError,
                          },
                        ];
                    setOrder({ ...order, items: newOrderItems });
                  }}
                >
                  <MenuItem value={0} disabled>
                    продукт
                  </MenuItem>
                  {products
                    .filter((product) => !product.isDeleted)
                    .map((c, idx) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                </Select>
              </Typography>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.items.map((row, key) => {
                    return (
                      <TableRow
                        style={{
                          cursor: isOrderEditAvailable ? "pointer" : "default",
                        }}
                        hover={isOrderEditAvailable}
                        role="checkbox"
                        className={"row"}
                        tabIndex={-1}
                        key={key}
                        onClick={() => {
                          if (isOrderEditAvailable) {
                            setCurrentProductVariants([]);
                            selectProduct("items", key);
                          }
                        }}
                      >
                        <TableCell>{row.name}</TableCell>
                        <TableCell className={classes.counterCell}>
                          <InputCounter
                            disabled={!isOrderEditAvailable}
                            valueProp={order.items[key].quantity}
                            setCounter={(value) => {
                              let curItems = order.items.map((el, index) => {
                                let totalPrice = (
                                  (+el.totalPrice / el.quantity) *
                                  value
                                ).toFixed(2);
                                if (totalPrice.toString().slice(-2) === "00")
                                  totalPrice = totalPrice
                                    .toString()
                                    .slice(0, -3);
                                return index !== key
                                  ? el
                                  : {
                                      ...el,
                                      quantity: value,
                                      totalPrice: totalPrice,
                                    };
                              });
                              setOrder({ ...order, items: curItems });
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {key >= order.items.length ||
                          !order.items[key].options
                            ? "-"
                            : Object.values(order.items[key].options)[0].join(
                                ", "
                              )}
                        </TableCell>
                        <TableCell className={classes.ingredients}>
                          {getIngredients(key).ingredientsToAdd}
                        </TableCell>
                        <TableCell className={classes.ingredients}>
                          {getIngredients(key).ingredientsToRemove}
                        </TableCell>
                        <TableCell>
                          {!row.totalPrice
                            ? "-"
                            : +row.totalPrice === 0
                            ? "Бесплатно"
                            : `${row.totalPrice} ₽`}
                        </TableCell>
                        <TableCell className={classes.deleteCell}>
                          {key < order.items.length && (
                            <DeleteIcon
                              fontSize={"small"}
                              color={"primary"}
                              style={{
                                cursor: isOrderEditAvailable
                                  ? "pointer"
                                  : "default",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (isOrderEditAvailable) {
                                  const newItems = order.items.filter(
                                    (el) => el.productId !== row.productId
                                  );
                                  setOrder({ ...order, items: newItems });
                                }
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <br />
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {promotionsColumns.map((column) => (
                      <TableCell key={column.id}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.promotions.map((promotion, index) => {
                    return (
                      <React.Fragment key={`promotion${index}`}>
                        <TableRow
                          role="checkbox"
                          className={"row"}
                          tabIndex={-1}
                        >
                          <TableCell className={classes.promotionName}>
                            {`${promotion.name} (акция)`}
                          </TableCell>
                          <TableCell className={classes.counterCell}>
                            <InputCounter
                              disabled={!isOrderEditAvailable}
                              valueProp={order.promotions[index].quantity}
                              setCounter={(value) => {
                                setOrder({
                                  ...order,
                                  promotions: order.promotions.map(
                                    (prom, key) => {
                                      let totalCost = (
                                        (+prom.totalCost / prom.quantity) *
                                        value
                                      ).toFixed(2);
                                      if (
                                        totalCost.toString().slice(-2) === "00"
                                      )
                                        totalCost = totalCost
                                          .toString()
                                          .slice(0, -3);
                                      return index === key
                                        ? {
                                            ...prom,
                                            totalCost: totalCost,
                                            quantity: value,
                                          }
                                        : prom;
                                    }
                                  ),
                                });
                              }}
                            />
                          </TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>{getPromotionPrice(promotion)}</TableCell>
                          <TableCell className={classes.deleteCell}>
                            <DeleteIcon
                              fontSize={"small"}
                              color={"primary"}
                              style={{
                                cursor: isOrderEditAvailable
                                  ? "pointer"
                                  : "default",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (isOrderEditAvailable) {
                                  const newPromotions = order.promotions.filter(
                                    (el) =>
                                      el.promotionId !== promotion.promotionId
                                  );
                                  setOrder({
                                    ...order,
                                    promotions: newPromotions,
                                  });
                                  setCurrentPromotionId(0);
                                }
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        {promotion.items.map((row, key) => {
                          return (
                            <TableRow
                              hover={isOrderEditAvailable}
                              style={{
                                cursor: isOrderEditAvailable
                                  ? "pointer"
                                  : "default",
                              }}
                              role="checkbox"
                              className={"row"}
                              tabIndex={-1}
                              key={key}
                              onClick={() => {
                                if (isOrderEditAvailable) {
                                  const selectedPromotion = promotions.find(
                                    (el) => el.id === promotion.promotionId
                                  )!;
                                  let selectedSetsVariantsIds: number[] = [];
                                  selectedPromotion.productSetsList
                                    .filter(
                                      (el) =>
                                        el.productIds.includes(row.productId) &&
                                        el.promotionCategoryId ===
                                          row.promotionCategoryId
                                    )!
                                    .map((el) => {
                                      selectedSetsVariantsIds = [
                                        ...selectedSetsVariantsIds,
                                        ...el.variantIds,
                                      ];
                                      return el.variantIds;
                                    });
                                  setCurrentProductVariants(
                                    selectedSetsVariantsIds
                                  );
                                  selectProduct("promotions", key, index);
                                }
                              }}
                            >
                              <TableCell className={classes.promotionItemName}>
                                {getPromotionItemName(row)}
                              </TableCell>
                              <TableCell className={classes.counterCell}>
                                <InputCounter
                                  disabled={!isOrderEditAvailable}
                                  valueProp={row.quantity}
                                  setCounter={(value) => {
                                    if (isOrderEditAvailable) {
                                      let curItems = promotion.items.map(
                                        (el, index) => {
                                          let totalPrice = (
                                            (+el.totalPrice / el.quantity) *
                                            value
                                          ).toFixed(2);
                                          if (
                                            totalPrice.toString().slice(-2) ===
                                            "00"
                                          )
                                            totalPrice = totalPrice
                                              .toString()
                                              .slice(0, -3);
                                          return index !== key
                                            ? el
                                            : {
                                                ...el,
                                                quantity: value,
                                                totalPrice: totalPrice,
                                              };
                                        }
                                      );
                                      const itemsPricesSum = curItems
                                        .map((item: any) => item.totalPrice)
                                        .reduce(
                                          (acc: number, sum: number) =>
                                            +acc + +sum,
                                          0
                                        );
                                      setOrder({
                                        ...order,
                                        promotions: order.promotions.map(
                                          (el) => {
                                            const selectedPromotion = promotions.find(
                                              (prom) =>
                                                prom.id === el.promotionId
                                            )!;
                                            let totalPrice = (
                                              (itemsPricesSum -
                                                selectedPromotion.discount!) *
                                              el.quantity
                                            ).toFixed(2);
                                            if (
                                              totalPrice
                                                .toString()
                                                .slice(-2) === "00"
                                            )
                                              totalPrice = totalPrice
                                                .toString()
                                                .slice(0, -3);
                                            return el.promotionId ===
                                              promotion.promotionId
                                              ? {
                                                  ...el,
                                                  items: curItems,
                                                  totalCost: selectedPromotion.price
                                                    ? selectedPromotion.price
                                                    : itemsPricesSum -
                                                        selectedPromotion.discount! >=
                                                      0
                                                    ? totalPrice
                                                    : 0,
                                                }
                                              : el;
                                          }
                                        ),
                                      });
                                    }
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                {row.options
                                  ? Object.values(row.options)[0].join(", ")
                                  : "-"}
                              </TableCell>
                              <TableCell>
                                {row.totalPrice === undefined
                                  ? "-"
                                  : +row.totalPrice === 0
                                  ? "Бесплатно"
                                  : `${row.totalPrice} ₽`}
                              </TableCell>
                              <TableCell className={classes.deleteCell}>
                                <DeleteIcon
                                  fontSize={"small"}
                                  color={"primary"}
                                  style={{
                                    cursor: isOrderEditAvailable
                                      ? "pointer"
                                      : "default",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (isOrderEditAvailable) {
                                      const newItems = promotion.items.filter(
                                        (el, index) => index !== key
                                      );
                                      setOrder({
                                        ...order,
                                        promotions: order.promotions.map((el) =>
                                          el.promotionId !==
                                          promotion.promotionId
                                            ? el
                                            : { ...el, items: newItems }
                                        ),
                                      });
                                    }
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
              <Typography
                variant="h6"
                component="div"
                className={classes.tableTitle}
              >
                <span>добавить &nbsp; &nbsp;</span>
                <Select
                  disabled={!isOrderEditAvailable}
                  value={currentProductIndex}
                  labelId="simple-select-city"
                  onChange={(e: any) => {
                    let newOrderPromotions = order.promotions;
                    const selectedPromotion = promotions.find(
                      (promo) => promo.id === e.target.value
                    )!;
                    // @ts-ignore
                    newOrderPromotions = order.promotions.find(
                      (el) => el.promotionId === selectedPromotion.id
                    )
                      ? order.promotions.map((el) =>
                          el.promotionId === selectedPromotion.id
                            ? { ...el, quantity: el.quantity + 1 }
                            : el
                        )
                      : [
                          ...newOrderPromotions,
                          {
                            items: [],
                            promotionId: selectedPromotion.id,
                            name: selectedPromotion.name,
                            quantity: 1,
                            totalCost: selectedPromotion.price,
                          },
                        ];
                    setOrder({ ...order, promotions: newOrderPromotions });
                  }}
                >
                  <MenuItem value={0} disabled>
                    акцию
                  </MenuItem>
                  {promotions
                    .filter((promotion) => promotion.isActive)
                    .map((c) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                </Select>
              </Typography>
              <Typography
                variant="h6"
                component="div"
                className={classes.tableTitle}
              >
                <span>добавить &nbsp; &nbsp;</span>
                <Select
                  defaultValue={0}
                  value={currentProductIndex}
                  labelId="simple-select-city"
                  disabled={currentPromotionId === 0 || !isOrderEditAvailable}
                  onChange={(e: any) => setCurrentProductIndex(e.target.value)}
                >
                  <MenuItem value={0} disabled>
                    продукт
                  </MenuItem>
                  {promotionProducts.map((c, idx) => (
                    <MenuItem key={idx} value={idx + 1}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
                <span>&nbsp; &nbsp; в &nbsp; &nbsp;</span>
                <Select
                  value={currentPromotionId}
                  labelId="simple-select-city"
                  disabled={!isOrderEditAvailable}
                  onChange={(e: any) => {
                    const selectedPromotion = promotions.find(
                      (prom) => prom.id === e.target.value
                    )!;
                    let newPromotionProducts: any[] = [];
                    selectedPromotion.productSetsList.forEach((set) => {
                      set.productIds.forEach((id) => {
                        const selectedProduct = {
                          ...products.find((product) => product.id === id)!,
                          promotionCategoryId: set.promotionCategoryId,
                        };
                        if (
                          !newPromotionProducts.find(
                            (el) => el.id === selectedProduct.id
                          )
                        )
                          newPromotionProducts = [
                            ...newPromotionProducts,
                            selectedProduct,
                          ];
                      });
                    });
                    newPromotionProducts = newPromotionProducts.map(
                      (product) => {
                        const selectedPromotionCategoryName = promotionCategories.find(
                          (el: any) => el.id === product.promotionCategoryId
                        )!.name;
                        return newPromotionProducts.filter(
                          (prod) => prod.id === product.id
                        ).length > 1
                          ? {
                              ...product,
                              name: `${product.name} (${selectedPromotionCategoryName})`,
                            }
                          : product;
                      }
                    );
                    newPromotionProducts = newPromotionProducts.sort((a, b) =>
                      a.name > b.name ? 1 : a.name < b.name ? -1 : 0
                    );
                    setPromotionProducts(newPromotionProducts);
                    setCurrentProductIndex(0);
                    setCurrentPromotionId(e.target.value);
                  }}
                >
                  <MenuItem value={0} disabled>
                    акцию
                  </MenuItem>
                  {promotions
                    .filter((prom) =>
                      order.promotions
                        .map((el) => el.promotionId)
                        .includes(prom.id)
                    )
                    .map((c) => (
                      <MenuItem key={c.id} value={c.id}>
                        {c.name}
                      </MenuItem>
                    ))}
                </Select>
              </Typography>
              {order?.promoCode ? (
                <>
                  <hr />
                  <Typography variant={"h6"}>Промокод</Typography>
                  <PromoCodeInfoTable promoCode={order.promoCode} />
                </>
              ) : (
                <></>
              )}
              <DialogActions className={classes.dialogActions}>
                <span />
                <Button
                  className={classes.productsSubmitBtn}
                  onClick={(e) => {
                    e.preventDefault();
                    setProgress(true);
                    onItemsEdit({
                      // @ts-ignore
                      readyTime: asapDelivery ? null : orderBlock.readyTime,
                      // @ts-ignore
                      promotions: order.promotions.map((el) => {
                        return {
                          promotionId: el.promotionId,
                          quantity: el.quantity,
                          promotionItems: mergePromotionItems(
                            // @ts-ignore
                            el.items.map((item) => {
                              return {
                                productId: item.productId,
                                variantId: item.options
                                  ? Object.keys(item.options)[0]
                                  : undefined,
                                quantity: item.quantity,
                                promotionCategoryId: item.promotionCategoryId,
                                ingredientsToRemove: item.ingredientsToRemove
                                  ? item.ingredientsToRemove.map(
                                      (el) => el.ingredientId
                                    ).length > 0
                                    ? item.ingredientsToRemove.map(
                                        (el) => el.ingredientId
                                      )
                                    : undefined
                                  : undefined,
                                ingredientsToAdd: item.ingredientsToAdd
                                  ? item.ingredientsToAdd.map((ing) => {
                                      return {
                                        ingredientId: ing.ingredientId,
                                        quantity: ing.quantity,
                                      };
                                    }).length > 0
                                    ? item.ingredientsToAdd.map((ing) => {
                                        return {
                                          ingredientId: ing.ingredientId,
                                          quantity: ing.quantity,
                                        };
                                      })
                                    : undefined
                                  : undefined,
                              };
                            })
                          ),
                        };
                      }),
                      items: getItems(order.items),
                    });
                  }}
                  color="primary"
                  type="submit"
                  disabled={
                    progress ||
                    !isOrderEditAvailable ||
                    isPromotionsWrong() ||
                    isAnyItemOptionError() ||
                    isItemsOrPromotionsEmpty() ||
                    (isObjectsEqual(order.items, oldOrder.items) &&
                      isObjectsEqual(order.promotions, oldOrder.promotions))
                  }
                >
                  Применить
                </Button>
              </DialogActions>
            </TableContainer>
          </form>
        </DialogContent>
        {progress && <ProgressBar bottom />}
      </Dialog>
    </div>
  );
};

export default OrderForm;
