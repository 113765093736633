import React, { FC, useState } from "react";
import { Tooltip } from "@material-ui/core";
import API from "../../../_shared/axios";
import { makeStyles } from "@material-ui/styles";

const typeIdMap = new Map([
  ["product", "productId"],
  ["commonProduct", "id"],
  ["promotion", "id"],
]);

const useStyles = makeStyles({
  displayContents: { display: "contents" },
  whiteSpace: { whiteSpace: "pre-line" },
});

const getTooltipLayout = (data: any, variantId: number) => {
  const weight = variantId
    ? data.variants.find((el: any) => el.variantId === variantId).weight
    : undefined;
  const { description, proteins, carbohydrates, fats, calories } = data;
  let nutritionStr = "";

  if (proteins) nutritionStr += `Белки - ${proteins} гр. `;
  if (fats) nutritionStr += `Жиры - ${fats} гр. `;
  if (carbohydrates) nutritionStr += `Углеводы - ${carbohydrates} гр. `;
  if (calories) nutritionStr += `Калории - ${calories} Ккал. `;

  return (
    <>
      {description || weight || nutritionStr ? (
        <div>
          {description && (
            <>
              {description} <br />
            </>
          )}
          {weight && (
            <>
              <strong>Вес:</strong> {weight} <br />
            </>
          )}
          {nutritionStr && (
            <>
              <strong>Пищевая ценность продукта на 100 гр:</strong> <br />
              {nutritionStr}
            </>
          )}
        </div>
      ) : (
        <div>данные не заполнены</div>
      )}
    </>
  );
};

const TooltipWrapper: FC<{
  rowData: any;
  type: "commonProduct" | "product" | "promotion";
  children: JSX.Element;
}> = ({ rowData, type, children }) => {
  const classes = useStyles();

  const [isTooltipLoading, setTooltipLoading] = useState(true);
  const [tooltip, setTooltip] = useState(
    <div>
      Курица копченая, бекон, охотничьи колбаски, лук красный, соус барбекю,
      соус ранч
      <br />
      <strong>Вес:</strong> 700гр.
      <br />
      <strong>Пищевая ценность продукта на 100 гр:</strong>
      <br />
      Белки - 100 гр. Жиры - 100.11 гр. Углеводы 500.15 гр. Калории - 100 Ккал
    </div>
  );

  const updateTooltip = () => {
    const id = rowData[typeIdMap.get(type)!];
    const variantId = rowData.variantId;
    setTooltipLoading((state) => true);
    if (type === "commonProduct") {
      setTooltip((tooltip) => getTooltipLayout(rowData, variantId));
      setTooltipLoading((state) => false);
    }
    if (type === "product")
      API.get(`/products/${id}`)
        .then(({ data }) => {
          setTooltip((tooltip) => getTooltipLayout(data, variantId));
        })
        .finally(() => setTooltipLoading(false));
  };

  return (
    <div
      className={classes.displayContents}
      onMouseEnter={(e) => {
        e.preventDefault();
        updateTooltip();
      }}
    >
      <Tooltip
        className={classes.whiteSpace}
        placement={"left"}
        title={isTooltipLoading ? "Загрузка..." : tooltip}
      >
        {children}
      </Tooltip>
    </div>
  );
};

export default React.memo(TooltipWrapper);
